import React from "react";
import Shimmer from "./Shimmer";
import SkeletonElement from "./SkeletonElement";

const SkeletonModuleBody = () => {
  return (
    <div className="skeleton-wrapper conf-main">
      <div className="about">
        <SkeletonElement type="subtitle" />
        <SkeletonElement type="text" />
        <SkeletonElement type="text" />
        <SkeletonElement type="text" />
        <SkeletonElement type="text" />
        <SkeletonElement type="content" />
      </div>
      <div className="module-content">
        <div className="pdf-content">
          <SkeletonElement type="pdf" />
        </div>

        <div className="video-content">
          <SkeletonElement type="video" />
        </div>
      </div>
      <div className="resources">
        <SkeletonElement type="subtitle" />
        <div className="references">
          <SkeletonElement type="subtitle" />
          {[1, 2, 3].map((reference, index) => (
            <SkeletonElement key={reference} type="text" />
          ))}
          <hr />
        </div>
        <div className="references">
          <SkeletonElement type="subtitle" />
          {[1, 2, 3].map((reference, index) => (
            <SkeletonElement key={reference} type="text" />
          ))}
          <hr />
        </div>
        <div className="references">
          <SkeletonElement type="subtitle" />
          {[1, 2, 3].map((reference, index) => (
            <SkeletonElement key={reference} type="text" />
          ))}
          <hr />
        </div>
      </div>
      <Shimmer />
    </div>
  );
};

export default SkeletonModuleBody;
