import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../../components/Button";
import { MESSAGEBASE } from "../../../../navigation/CONSTANT";
import { ProfileInfoProps } from "../../../../types";

const ViewProfileHeader = ({
  profileDetails,
}: {
  profileDetails: ProfileInfoProps;
}) => {
  const history = useHistory();
  const contactUser = () => {
    if (!profileDetails.id) return;
    history.push(MESSAGEBASE + "/" + profileDetails.id);
  };
  return (
    <section className="view-profile-page-header">
      <div className="header-content">
        <h1>View Profile</h1>
        <Button
          type="button"
          size="size-lg"
          colorTheme="has-primary-theme"
          onClick={contactUser}
        >
          Contact
        </Button>
      </div>
    </section>
  );
};

export default ViewProfileHeader;
