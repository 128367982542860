import React, { FunctionComponent } from "react";
const PrivacyPolicyHeader = () => {
  return (
    <section className="priv-pol-header">
      <h1>Takeda Privacy Policy and Consent</h1>
    </section>
  );
};

export default PrivacyPolicyHeader;
