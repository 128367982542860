import React from "react";
import "./ReportingHeading.scss";
const ReportingHeading = () => {
  return (
    <section className="reporting-heading">
      <div className="title-container">
        <h1>Reporting Overview</h1>
      </div>
    </section>
  );
};

export default ReportingHeading;
