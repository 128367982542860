import React, { FunctionComponent } from "react";
import MyMessageHeader from "./MyMessageHeader";
import MyMessageBody from "./MyMessageBody";
import "./MyMessageContent.scss";

const MyMessageContent: FunctionComponent = () => {
  return (
    <main className="my-message-page">
      <MyMessageHeader />
      <MyMessageBody />
    </main>
  );
};

export default MyMessageContent;
