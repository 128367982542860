import React, { FunctionComponent } from "react";
const ContactUsHeader: FunctionComponent = () => {
  return (
    <section className="contact-us-page-header">
      <h1>Contact Us</h1>
    </section>
  );
};

export default ContactUsHeader;
