import React, { FunctionComponent } from "react";
import "./CancelPopUpSVG.scss";
import { ReactComponent as CancelSVG } from "../../assets/images/cancel.svg";

interface CancelPopUpSVGProps {
  cancelPopUp: () => void;
}

const CancelPopUpSVG: FunctionComponent<CancelPopUpSVGProps> = ({
  cancelPopUp,
}: CancelPopUpSVGProps) => {
  return (
    <div className="cancel-popup">
      <CancelSVG onClick={cancelPopUp} />
    </div>
  );
};

export default CancelPopUpSVG;
