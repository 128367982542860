import React, { FunctionComponent, ReactChild } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import AboutUsContent from "./AboutUsContent";

const AboutUs = () => {
  return (
    <>
      <Header />
      <AboutUsContent />
      <Footer />
    </>
  );
};

export default AboutUs;
