import React from "react";
import "./ResetPasswordSuccessContent.scss";
import { ReactComponent as ResetPasswordSVG } from "../../../assets/images/resetpassword.svg";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";

const ResetPasswordSuccessContent = () => {
  return (
    <main className="reset-pwd-succ-page">
      <section className="reset-pwd-succ-content">
        <ResetPasswordSVG />
        <h3>Reset Password</h3>
        <p>
          Your new password has been set. You can log in with the new pasword.
        </p>
        <Link to="/login">
          <Button type="button" size="size-lg" colorTheme="has-primary-theme">
            Login
          </Button>
        </Link>
      </section>
    </main>
  );
};

export default ResetPasswordSuccessContent;
