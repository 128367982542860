import React from "react";
import "./ChangePwdHeader.scss";

const ChangePwdHeader = () => {
  return (
    <section className="change-pwd-header">
      <h3>Change Password</h3>
      <p className="pwd-criteria">
        All passwords must meet the following criteria:{" "}
      </p>
      <p>Have at least 9 characters</p>
      <p> Contain mixed case letters</p>
      <p>Contain at least 1 number OR a special character</p>
      <p>Allowed characters are: a-z, A-Z, 0-9 and !@#$*^().,[]~-</p>
    </section>
  );
};

export default ChangePwdHeader;
