import "./FeedbackForm.scss";
import { Formik, Form } from "formik";
import FormikControl from "../../../../components/Form/FormikControl";
import Button from "../../../../components/Button";
import { Link, useHistory } from "react-router-dom";
import TextError from "../../../../components/Form/TextError";
import React, { useRef, useState } from "react";
import {
  MESSAGESUCCESS,
  REGISTER,
  RESETPASSWORDREQUEST,
} from "../../../../navigation/CONSTANT";
import { feedbackValidationScheme } from "./feedbackValidationScheme";
import { feedbackInitialValues } from "./feedbackInitialValues";
import { loginErrorInformation } from "./errorTypes";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { featureTypes } from "./featureTypes";
import feedbackService from "../../../../services/feedback";
import { useAuth } from "../../../../navigation/Auth/ProvideAuth";

const FeedbackForm = () => {
  const { auth } = useAuth();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const history = useHistory();
  const initialValues = feedbackInitialValues;
  const validationSchema = feedbackValidationScheme;
  const featureOptions = featureTypes;

  const onSubmit = async (values: any, actions: any) => {
    setLoading(true);
    const feedbackPayload =
      values.feature && values.feature !== "Choose feature"
        ? {
            feature: values.feature,
            feedbackText: values.details,
          }
        : { feedbackText: values.details };
    try {
      const feedbackResp = await feedbackService.userFeedback(
        auth?.access_token,
        feedbackPayload
      );
      setLoading(false);
      history.push(MESSAGESUCCESS);
    } catch (error: any) {
      setLoading(false);
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, isValid, setFieldValue }) => (
        <section className="feedback-page-form">
          <div className="feedback-form-container">
            <Form className="feedback-form">
              <FormikControl
                control="select"
                label="Feature (optional)"
                name="feature"
                options={featureOptions}
              />

              <FormikControl
                control="textarea"
                label="Details"
                name="details"
              />

              <Button
                type="submit"
                size="size-xxlg"
                disabled={!isValid}
                colorTheme="has-primary-theme"
              >
                Send
              </Button>
              <LoadingSpinner isLoading={isLoading} />
              {error && <TextError>{error}</TextError>}
            </Form>
          </div>
        </section>
      )}
    </Formik>
  );
};

export default FeedbackForm;
