import React from "react";
import AccountAction from "./AccountAction";
import "./AccountsBody.scss";
import SavedArticles from "./SavedArticles";
import SavedModules from "./SavedModules";

const AccountsBody = () => {
  return (
    <section className="accounts-body">
      <SavedArticles />
      <SavedModules />
      <AccountAction />
    </section>
  );
};

export default AccountsBody;
