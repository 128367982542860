import "./MessageForm.scss";
import { Formik, Form } from "formik";
import FormikControl from "../../../../components/Form/FormikControl";
import Button from "../../../../components/Button";
import { Link, useHistory, useParams } from "react-router-dom";
import TextError from "../../../../components/Form/TextError";
import React, { useRef, useState } from "react";
import { messageValidationSchema } from "./messageValidationScheme";
import { messageErrorInformation } from "./errorTypes";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import messageService from "../../../../services/messages";
import { useAuth } from "../../../../navigation/Auth/ProvideAuth";
import { MESSAGESUCCESS } from "../../../../navigation/CONSTANT";
import { ProfileInfoProps } from "../../../../types";
import { useProvideAuth } from "../../../../navigation/Auth/useProvideAuth";
import { prepareErrorMsg } from "../../../../components/ArticleAddComment/helper";

const MessageForm = ({
  profileDetails,
  receiverId,
}: {
  profileDetails: ProfileInfoProps;
  receiverId: number | null;
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const { auth } = useAuth();
  const { signout } = useProvideAuth();
  const history = useHistory();

  const [error, setError] = useState<string | undefined>();
  // const { email } = profileDetails;
  // const initialValues = { memberName: email, messageText: "" };
  const { firstName, lastName } = profileDetails;
  const initialValues = {
    memberName: firstName ? `${firstName} ${lastName}` : "",
    messageText: "",
  };
  const validationSchema = messageValidationSchema;

  const onSubmit = async (values: any, actions: any) => {
    setLoading(true);
    setError("");
    const messageDetails = {
      receiverId,
      messageText: values.messageText,
    };

    try {
      const response = await messageService.sendMessage(
        messageDetails,
        auth?.access_token
      );
      setLoading(false);
      history.push(MESSAGESUCCESS);
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.data?.errors?.status === "inappropriateContent") {
        setError(prepareErrorMsg(error?.response?.data?.errors?.data));
      } else {
        setError(
          messageErrorInformation[error?.response?.data?.errors] ||
            "Error in messaging"
        );
      }
      if (error?.response?.status === 401) {
        //place your reentry code
        await signout();
      }
    }
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, isValid, setFieldValue }) => (
        <section className="message-page-form">
          <div className="message-form-container">
            <Form className="message-form">
              <FormikControl
                control="input"
                // type="email"
                type="text"
                label="Member name"
                name="memberName"
                disabled
              />

              <FormikControl
                control="textarea"
                label="Message"
                name="messageText"
              />

              <Button
                type="submit"
                size="size-xxlg"
                disabled={!isValid}
                colorTheme="has-primary-theme"
              >
                Send
              </Button>
              <LoadingSpinner isLoading={isLoading} />
              {error && <TextError>{error}</TextError>}
            </Form>
          </div>
        </section>
      )}
    </Formik>
  );
};

export default MessageForm;
