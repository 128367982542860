import React, { FunctionComponent } from "react";
import "./CMEBoard.scss";
import { CMECardType } from "../../types";
import CMECard from "../CMECard";
interface CMEBoardProps {
  cmemodules: Array<CMECardType>;
}

const CMEBoard: FunctionComponent<CMEBoardProps> = ({
  cmemodules,
}: CMEBoardProps) => {
  return (
    <div className="cme-board">
      {cmemodules.map((cmemodule) => (
        <CMECard key={cmemodule.id} {...cmemodule} />
      ))}
    </div>
  );
};

export default CMEBoard;
