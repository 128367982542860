export const sampleProfile = [
  {
    email: "",
    firstName: "",
    id: 1,
    image: null,
    lastName: "",
    mobileNumber: "",
    organisation: "",
    professionalNumber: "",
    region: "",
    seniority: "",
    speciality: "",
    takedaId: "",
    title: "",
  },
  {
    email: "",
    firstName: "",
    id: 2,
    image: null,
    lastName: "",
    mobileNumber: "",
    organisation: "",
    professionalNumber: "",
    region: "",
    seniority: "",
    speciality: "",
    takedaId: "",
    title: "",
  },
  {
    email: "",
    firstName: "",
    id: 3,
    image: null,
    lastName: "",
    mobileNumber: "",
    organisation: "",
    professionalNumber: "",
    region: "",
    seniority: "",
    speciality: "",
    takedaId: "",
    title: "",
  },
  {
    email: "",
    firstName: "",
    id: 4,
    image: null,
    lastName: "",
    mobileNumber: "",
    organisation: "",
    professionalNumber: "",
    region: "",
    seniority: "",
    speciality: "",
    takedaId: "",
    title: "",
  },
];
