import React, { createContext, useContext, useReducer } from "react";
import { conferencesReducer, initialConferences } from "./ConferenceReducer";

interface AppContextInterface {
  conferences: any;
  setConferences: React.Dispatch<any>;
}
const ConferenceContext = createContext<AppContextInterface>(
  {} as AppContextInterface
);

export const ConferenceProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [conferences, setConferences] = useReducer(
    conferencesReducer,
    initialConferences
  );

  return (
    <ConferenceContext.Provider value={{ conferences, setConferences }}>
      {children}
    </ConferenceContext.Provider>
  );
};

export const useConferenceContext = () => useContext(ConferenceContext);
