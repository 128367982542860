import React, { useEffect } from "react";
import Board from "../../components/Board";
import { useNewsContext } from "../../context/News";
import { useAuth } from "../../navigation/Auth/ProvideAuth";
import { JOURNALWATCH } from "../../navigation/CONSTANT";
import newsService from "../../services/news";

const JournalWatch = () => {
  const { auth } = useAuth();
  const { news, setNews } = useNewsContext();
  const journalWatch = news.JOURNAL_WATCH;
  useEffect(() => {
    const getAllNewsInformation = async () => {
      const journalWatchResp = await newsService.getNewsType(
        "journal-watch",
        auth?.access_token
      );
      setNews({ type: "GET_JOURNAL_WATCH", payload: journalWatchResp.data });
    };

    getAllNewsInformation();
  }, [setNews]);
  return (
    <div className="information-menu">
      <Board
        articles={journalWatch}
        title="Journal Watch"
        navigateTo={JOURNALWATCH}
        viewAll={false}
      />
    </div>
  );
};

export default JournalWatch;
