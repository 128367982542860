import React, { FunctionComponent } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ViewProfileContent from "./ViewProfileContent";

const ViewProfile: FunctionComponent = () => {
  return (
    <>
      <Header />
      <ViewProfileContent />
      <Footer />
    </>
  );
};

export default ViewProfile;
