import React, { useState } from "react";
import { Formik, Form } from "formik";
import "./ReplyBoardForm.scss";
import { replyMessageInitialValues } from "./replyMessageInitialValues";
import { replyMessageValidationSchema } from "./replyMessageValidationSchema";
import { useHistory } from "react-router";
import TextError from "../../Form/TextError";
import LoadingSpinner from "../../LoadingSpinner";
import Button from "../../Button";
import FormikControl from "../../Form/FormikControl";
import { useParams } from "react-router-dom";
import messageService from "../../../services/messages";
import { useAuth } from "../../../navigation/Auth/ProvideAuth";
import { MESSAGESUCCESS } from "../../../navigation/CONSTANT";
import { messageErrorInformation } from "./errorTypes";
import { useProvideAuth } from "../../../navigation/Auth/useProvideAuth";
import { prepareErrorMsg } from "../../ArticleAddComment/helper";

const ReplyBoardForm = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const { auth } = useAuth();
  const { signout } = useProvideAuth();
  const history = useHistory();
  const { recipientId } = {
    recipientId: null,
    ...useParams(),
  };
  const initialValues = replyMessageInitialValues;
  const validationSchema = replyMessageValidationSchema;

  const onSubmit = async (values: any, actions: any) => {
    setError("");
    setLoading(true);
    const messageDetails = {
      receiverId: recipientId,
      messageText: values.replyMsg,
    };

    try {
      const response = await messageService.sendMessage(
        messageDetails,
        auth?.access_token
      );
      setLoading(false);
      history.push(MESSAGESUCCESS);
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.data?.errors?.status === "inappropriateContent") {
        setError(prepareErrorMsg(error?.response?.data?.errors?.data));
      } else {
        setError(
          messageErrorInformation[error?.response?.data?.errors] ||
            "Error while messaging"
        );
      }
      if (error?.response?.status === 401) {
        //place your reentry code
        await signout();
      }
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, isValid, setFieldValue }) => (
        <section className="reply-board-form">
          <div className="reply-board-form-container">
            <Form className="reply-board-form">
              <FormikControl control="textarea" label="Reply" name="replyMsg" />
              <Button
                type="submit"
                size="size-xxlg"
                disabled={!isValid}
                colorTheme="has-secondary-theme"
              >
                Reply
              </Button>
              <LoadingSpinner isLoading={isLoading} />
              {error && <TextError>{error}</TextError>}
            </Form>
          </div>
        </section>
      )}
    </Formik>
  );
};

export default ReplyBoardForm;
