import React from "react";
import "./Footer.scss";
import { ReactComponent as FooterLogo } from "../../assets/images/footer-logo.svg";

import Button from "../Button";
import { Link } from "react-router-dom";
import {
  ABOUTUS,
  COMMUNITY,
  CONTACTUS,
  LOGIN,
  REGISTER,
  TERMSANDCONDITION,
  PRIVACYPOLICY,
  COOKIESPOLICY,
} from "../../navigation/CONSTANT";

const Footer = () => {
  const year: number = new Date().getFullYear();
  return (
    <footer className="footer">
      <div>
        <ul className="footer-links">
          <Link to={COMMUNITY}>
            <li className="footer-link">Community</li>
          </Link>
          <Link to={LOGIN}>
            <li className="footer-link">Log In</li>
          </Link>
          <Link to={REGISTER}>
            <li className="footer-link">Create Account</li>
          </Link>
          <Link to={ABOUTUS}>
            <li className="footer-link">About Us</li>
          </Link>
        </ul>
      </div>
      <div>
        <ul className="footer-links">
          <Link to={PRIVACYPOLICY}>
            <li className="footer-link">Privacy and Consent Policy</li>
          </Link>
          <Link to={TERMSANDCONDITION}>
            <li className="footer-link">Terms of Use</li>
          </Link>
          <Link to={COOKIESPOLICY}>
            <li className="footer-link">Site Cookie Policy</li>
          </Link>
          <Link to={CONTACTUS}>
            <li className="footer-link">Contact Us</li>
          </Link>
        </ul>
      </div>
      <div className="feedback">
        <Link to="/feedback">
          <Button type="button" size="size-lg" colorTheme="has-primary-theme">
            Your Feedback
          </Button>
        </Link>
      </div>
      <div className="footer-logo-container">
        <FooterLogo />
        <p>Date of Prep, March 2022</p>
        <p>@{year} Interactive Pharma Solutions</p>
      </div>
    </footer>
  );
};

export default Footer;
