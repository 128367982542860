import React from "react";
import "./UpdateProfileHeading.scss";
const UpdateProfileHeading = () => {
  return (
    <section className="update-profile-heading">
      <h1>Update Profiling</h1>
    </section>
  );
};

export default UpdateProfileHeading;
