import React from "react";
import "./InviteFriendSuccessHeading.scss";

const InviteFriendSuccessHeading = () => {
  return (
    <section className="invite-friend-success-heading">
      <div className="title-container">
        <h1>Invite a friend</h1>
      </div>
    </section>
  );
};

export default InviteFriendSuccessHeading;
