import React from "react";
import Shimmer from "./Shimmer";
import SkeletonElement from "./SkeletonElement";

const SkeletonArticleBody = () => {
  return (
    <div className="skeleton-wrapper article-main">
      <div className="article-img">
        <SkeletonElement type="banner" />
      </div>
      <div className="article-info">
        <SkeletonElement type="info" />
        <SkeletonElement type="rating" />
      </div>

      <div className="article-content">
        <SkeletonElement type="text" />
        <SkeletonElement type="text" />
      </div>

      <Shimmer />
    </div>
  );
};

export default SkeletonArticleBody;
