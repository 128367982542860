import React from "react";
import "./TermsAndCondBody.scss";

const TermsAndCondBody = () => {
  return (
    <div className="terms-cond-body">
      <div className="content">
        <p>
          Welcome to the Takeda website MedUnifi, a non-promotional community
          for health care professionals (HCPs) (the “Website”).
        </p>
        <p>
          The information provided on this Website is about various disease
          areas; Hematology, Rare Genetic Diseases, Gastroenterology, Oncology,
          Immunology and Hereditary Angioedema (HAE).
        </p>

        <p>
          The information provided on this Website is for general information
          and education purposes only. Please read and review these Terms of Use
          carefully before accessing and using this Website.
        </p>
        <h3>1. Use of Takeda Website</h3>

        <p>
          Your access to and use of this Website is subject to the following
          terms and conditions and all applicable laws. By using any of the
          contents of this Website, including but not limited to, all text and
          images (the “Contents”) you hereby acknowledge that you have read,
          understood and agreed, without limitation or qualiﬁcation, to these
          Terms of Use and acknowledge that any other agreements between you and
          Takeda Pharmaceuticals FZE having its ofﬁces at One Central, The
          Ofﬁces [5], Ofﬁce No: 2.04, Dubai World Trade Center Free Zone, Dubai,
          UAE,, (“Takeda”) are superseded with respect to this subject matter.
        </p>

        <h3>2. Information purposes</h3>

        <p>
          This Website may contain speciﬁc information relating to various
          Takeda medicines, including but not limited to, prescription only
          medicines. All information presented on this Website is solely
          intended for Healthcare Professionals only. For the avoidance of
          doubt, a “Healthcare Professional” refers to any natural person that
          is a member of the medical, dental, pharmacy or nursing profession or
          any other person, who in the course of his/her professional activities
          may prescribe, purchase, supply, recommend or administer a medicine or
          medical devices. By using this Website you certify that you have a
          license to operate as a Healthcare Professional in the country this
          Site accessible from. This section of the Website is not intended for
          patients’ access and in this regard, patients must not act upon this
          Website.
        </p>

        <p>
          All information presented on this Website is solely intended for
          educational and informational purposes only. Please note that approved
          prescribing information must guide the appropriate use of all
          medicines and before prescribing any medicine, Healthcare
          Professionals should consult the approved prescribing information for
          that medicine in their country.
        </p>

        <p>
          This Website may contain information about products that may not be
          available in all countries, or may be available under different
          trademarks, for different indications, in different dosages, or in
          different strengths and subject to different regulatory requirements
          depending on the country of use. Many of the products listed are
          available only by prescription through Healthcare professionals.
          Nothing contained herein should be considered a solicitation,
          promotion or indication for any prescription drug including the ones
          under development.
        </p>

        <p>
          All information and references relating to handling, storage or use of
          products, to be implied from the relevant resource, are based on
          Takeda&apos;s knowledge at the time such recommendations are made.
        </p>

        <p>
          The information provided on this Website does not constitute an offer
          of or solicitation for the purchase or disposal of, trading or any
          transaction in any Takeda securities, its afﬁliates and/or partners
          securities. Investors must not rely on this information for investment
          decisions.
        </p>

        <h3>3. Use of Contents and Intellectual Property Rights</h3>

        <p>
          The entire Contents of this Website is subject to the relevant
          trademark and copyright protection. The trademarks, logos and service
          marks (collectively, the &ldquo;Trademarks&rdquo;) used or mentioned
          on this site are registered andunregistered trademarks of Takeda, one
          of its afﬁliates and are protected by applicable law. You may
          download, display or print information from this Website solely for
          non-commercial personal use or for use in non-commercial education and
          scientiﬁc work. No modiﬁcation for further reproduction of the content
          is permitted and all copyright and other proprietary notices contained
          herein shall be retained in all copies. The content may otherwise not
          be copied or used in any way without the prior written consent of
          Takeda or except as provided in these Terms of Use. This Website and
          all of its Contents, are owned by Takeda, its afﬁliates and/or
          partners.
        </p>

        <p>
          No license to or right in the Contents or any trademarks, patents,
          designs, trade secrets, technologies, products, process and other
          proprietary rights of Takeda, its afﬁliates and/or partners is granted
          to or conferred upon you.
        </p>

        <h3>4. Information “as is”</h3>

        <p>
          Takeda will use reasonable efforts to include accurate and up-to-date
          information on this Website but makes no warranties or representations
          of any kind as to its accuracy or completeness. Takeda disclaims all
          warranties, expressed or implied, including warranties of
          merchantability or ﬁtness for a particular purpose. Furthermore,
          Takeda neither warrants nor represents that your use of any of the
          Contents on this Website will not infringe rights of third parties,
          not owned by Takeda or any of its afﬁliates. You agree that access to
          and use of this Website and the Contents thereof is at your own risk.
        </p>

        <h3>5. Disclaimed liability</h3>

        <p>
          Neither Takeda nor any party involved in creating, producing or
          delivering this Website shall be liable for any damages or injury,
          including without limitation, direct, indirect, incidental,
          consequential, punitive, and special or other damages, lost
          opportunities, lost proﬁt or any loss or damages of any kind arising
          out of access to, use of or inability to use this Website, or any
          errors or omissions in the Contents thereof. This limitation includes
          damages to, or for any viruses that may affect your computer
          equipment.
        </p>

        <h3>6. Data Privacy</h3>

        <p>
          Takeda respects the privacy of its Website users. Please refer to
          Takeda’s Privacy Notice that explains user’s rights and
          responsibilities with respect to information that is disclosed on this
          Website.
        </p>
        <h3>7. Links to other internet sites</h3>

        <p>
          This Website may contain links or references to other internet sites
          maintained by third parties over which Takeda has no control. Such
          links are provided merely as a convenience. Similarly, this Website
          may be accessed by from third party links over which Takeda has no
          control. Takeda makes no warranties or representations of any kind as
          to the truthfulness, accuracy, currency, completeness of any
          information contained or compliance with applicable law in such
          third-party internet sites and assumes no liability for any damages or
          injuries of any kind arising from such content or information.
          Inclusion of any third- party links does not imply any endorsement or
          recommendation by Takeda.
        </p>

        <h3>8. Use of Questions and Comments</h3>

        <p>
          Except for transmissions covered by our Privacy Statement or made by
          Healthcare Professionals pursuant to the applicable laws, EU
          Regulations and Codes, communications which you forward to this
          Website or otherwise to Takeda shall be deemed non-conﬁdential and
          non-proprietary and Takedashall not be required to respond. Subject to
          the applicable laws, Takeda and its afﬁliates reserve the right to use
          such communications for any purpose and to reproduce, publish and
          disclose this information to third parties without limitation or
          compensation. Takeda and its afﬁliates also are free to use without
          limitation and without compensation to you any ideas, concepts,
          know-how, techniques or the like contained in any communication you
          send to Takeda for any purpose, including, but not limited to,
          developing, manufacturing and marketing products.
        </p>

        <h3>9. Applicable law and place of jurisdiction</h3>

        <p>
          Any breaches of the terms and conditions in the Terms of Use, Takeda
          reserves the right, to take corrective action, including, but not
          limited to, preventing you from using the services offered by Takeda
          and removing any information, data and content communicated to this
          site, at any moment and without notice.
        </p>

        <p>
          These Terms of Use, the Contents of this Website and your use of it
          shall be governed by and construed in accordance with substantive laws
          of UAE Any conﬂict arising out or in relation hereto, if not solved
          amicably, shall be settled by courts of competent jurisdiction in
          Dubai.
        </p>

        <p>
          In case any part of these Terms of Use be held invalid, illegal or
          unenforceable, the validity, legality and enforceability of the
          remaining provisions shall not in any way be affected or impaired.
        </p>

        <h3>
          10. Update of the Terms of Use and other policies provided on this
          Takeda Website
        </h3>

        <p>
          Takeda reserves the right to alter or delete material from theWebsite
          at any time, and Takeda may, at any time, revise these Terms of Use
          and/or any other policies set forth in this Website by updating this
          posting. You are bound by any such revisions and should, therefore,
          periodically visit this page to review the then current Terms of Use
          and policies.
        </p>
        <h3>11. Adverse Event (Pharmacovigilance) Disclaimer:</h3>
        <p>
          The Takeda community forum is not the appropriate place to report any
          medical adverse events or reactions or product complaints.
        </p>

        <p>
          Any medical adverse events or reactions or product complaints from a
          Takeda product should be reported directly to the local health
          authority &/or Takeda as per the following Takeda contact details.
        </p>

        <table>
          <thead>
            <tr>
              <th>Country</th>
              <th>Country Takeda Local Safety Reporting Mailbox</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Algeria, Tunisia, Morocco</td>
              <td>
                <a
                  href="mailto:AE.Algeria@takeda.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  AE.Algeria@takeda.com
                </a>
              </td>
            </tr>
            <tr>
              <td>Belarus</td>
              <td>
                <a
                  href="mailto:AE.Belarus@takeda.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  AE.Belarus@takeda.com
                </a>
              </td>
            </tr>
            <tr>
              <td>Egypt</td>
              <td>
                <a
                  href="mailto:AE.Egypt@takeda.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  AE.Egypt@takeda.com
                </a>
              </td>
            </tr>
            <tr>
              <td>India, Sri Lanka, Nepal, Bangladesh</td>
              <td>
                <a
                  href="mailto:AE.India@takeda.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  AE.India@takeda.com
                </a>
              </td>
            </tr>
            <tr>
              <td>
                Kazakhstan, Armenia, Azerbaijan, Georgia, Kyrgyzstan, Mongolia,
                Tajikistan, Turkmenistan, Uzbekistan
              </td>
              <td>
                <a
                  href="mailto:AE.AsiaCaucasus@takeda.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  AE.AsiaCaucasus@takeda.com
                </a>
              </td>
            </tr>
            <tr>
              <td>Saudi Arabia</td>
              <td>
                <a
                  href="mailto:AE.SaudiArabia@takeda.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  AE.SaudiArabia@takeda.com
                </a>
              </td>
            </tr>
            <tr>
              <td>
                South Africa, Lesotho, Swaziland, Kenya, Botswana, Mauritius,
                Namibia, Tanzania, Nigeria, Rwanda, Uganda, Zimbabwe & Ghana
              </td>
              <td>
                <a
                  href="mailto:AE.SouthAfricaSSA@takeda.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  AE.SouthAfricaSSA@takeda.com
                </a>
              </td>
            </tr>
            <tr>
              <td>Turkey</td>
              <td>
                <a
                  href="mailto:AE.Turkey@takeda.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  AE.Turkey@takeda.com
                </a>
              </td>
            </tr>
            <tr>
              <td>Ukraine, Moldova</td>
              <td>
                <a
                  href="mailto:AE.Ukraine@takeda.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  AE.Ukraine@takeda.com
                </a>
              </td>
            </tr>
            <tr>
              <td>
                United Arab Emirates, Bahrain, Iran, Iraq, Jordan, Kuwait,
                Lebanon, Libya, Oman, Pakistan, Qatar, Sudan, Syria & Yemen
              </td>
              <td>
                <a
                  href="mailto:AE.MiddleEast@takeda.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  AE.MiddleEast@takeda.com
                </a>
              </td>
            </tr>
          </tbody>
        </table>

        <p>
          However, if any medical adverse events or reactions or product
          complaints from a Takeda product is reported within Takeda community
          forum, Takeda has a legal obligation to register, monitor and analyze
          all safety information about Takeda products that are reported and to
          perform a medical and scientiﬁc assessment and evaluation of such
          safety information.
        </p>

        <p>
          For such purposes, we will collect, process, use and publish all
          information and personal data that you provide in accordance with
          current applicable laws regarding pharmacovigilance.
        </p>

        <p>
          The personal data collected will depend on your relationship with
          Takeda but may include your name, contact details and, for the
          purposes of reporting the adverse event information, information about
          you or the patient including gender, age, and details of your health
          condition or, if you are making a report as a healthcare professional,
          that of a patient, together with your professional information.
        </p>

        <p>
          Takeda will process such personal data only for the purposes of
          handling adverse events and maintaining the beneﬁt-risk proﬁle of the
          Takeda products in accordance with applicable legislation and in
          accordance with Takeda’s privacy and data protection policies.
        </p>

        <p>
          This is to ensure high standards of quality and safety of medicinal
          products, to enhance patient care and patient safety and to assess the
          risk-beneﬁt proﬁle of medicines.
        </p>

        <p>
          Where necessary, Takeda may contact you to collect further information
          on the reported adverse event or product complaint and may share
          personal data about you with third parties including regulatory
          agencies, other health authorities and service providers that Takeda
          contracts with to perform activities or functions related to the
          purposes speciﬁed above on behalf of Takeda.
        </p>

        <p>
          These service providers may include translation service providers,
          Takeda product partners, healthcare professionals, pharmacovigilance
          service providers (such as pharmacovigilance services vendors,
          pharmacovigilance call centers, digital media and/or mobile app
          vendors, CRO study management vendors, archiving vendors,
          pharmacovigilance auditing vendors, market research and/or patient
          support program suppliers) or medical party services or call centers.
        </p>

        <p>
          The sharing of your personal data may involve transfers to other
          countries. The data protection and privacy laws in these countries may
          not provide the same level of protection as your home country. Takeda
          has put in place safeguards to adequately protect your personal data
          in connection with such transfers.
        </p>

        <p>
          Takeda will take reasonable and appropriate physical, administrative
          and technical safeguards to protect the personal data about you from
          loss, misuse, unauthorized access, disclosure, alteration or
          destruction.
        </p>

        <p>
          We will keep your personal data for the period according to applicable
          legislation relating to pharmacovigilance, i.e., for the lifetime of
          the Takeda product in question plus additional 10 years (as a
          minimum). However, the retention period may be longer where required
          by national law.
        </p>

        <p>Dated: 29/03/2022</p>

        <p>The copyright and ownership of this Website reside with:</p>
        <div className="address">
          <p>Takeda Pharmaceuticals FZE </p>
          <p>One Central, The Ofﬁces [5] </p>
          <p>Ofﬁce No: 2.04</p>
          <p>Dubai World Trade Center Free Zone </p>
          <p>Dubai, UAE</p>
        </div>

        <p>
          Meduniﬁ.com has been developed by Takeda Pharmaceuticals FZE as an
          educational resource for healthcare providers.
        </p>

        <p>© Takeda Pharmaceuticals FZE, 2022</p>
        <p>Last edited 29/03/2022</p>
      </div>
    </div>
  );
};

export default TermsAndCondBody;
