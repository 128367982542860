import loginService from "../../services/login";
import { useAuth } from "./ProvideAuth";

interface CredentialType {
  email: string;
  password: string;
}
export function useProvideAuth() {
  const { setAuth } = useAuth();

  const signin = (credential: CredentialType, reCaptchaToken: any) => {
    return new Promise((resolve, reject) => {
      loginService
        .login(credential, reCaptchaToken)
        .then((auth) => {
          window.localStorage.setItem(
            "loggedCommunityAppUser",
            JSON.stringify(auth)
          );
          setAuth(auth);
          resolve(auth);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const signout = () => {
    return new Promise(() => {
      window.localStorage.removeItem("loggedCommunityAppUser");
      setAuth(null);
    });
  };

  return {
    signin,
    signout,
  };
}
