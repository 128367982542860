import React, { FunctionComponent, useEffect, useState } from "react";
import MyMessageBoard from "../../../../components/MyMessageBoard";
import { useAuth } from "../../../../navigation/Auth/ProvideAuth";
import messageService from "../../../../services/messages";
import "./MyMessageBody.scss";
import { MessageCardProps } from "../../../../types";
import PaginationByCount from "../../../../components/PaginationByCount";

const MyMessageBody: FunctionComponent = () => {
  const { auth } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<{
    data: Array<MessageCardProps>;
    totalCount: number;
  }>({ data: [], totalCount: 0 });
  const [pageNumber, setPageNumber] = useState<number>(1);

  const changePage = (changeVal: number) => {
    setPageNumber(pageNumber + changeVal);
  };
  useEffect(() => {
    const getUserMessages = async () => {
      setLoading(true);
      const response = await messageService.getMessages(
        auth?.access_token,
        pageNumber
      );
      setLoading(false);
      setMessages(response);
    };
    try {
      getUserMessages();
    } catch (error) {
      setLoading(false);
    }
  }, [pageNumber]);
  return (
    <section className="my-message-page-body">
      <MyMessageBoard messages={messages?.data} isLoading={loading} />
      <PaginationByCount
        currentPage={pageNumber}
        totalCount={messages?.totalCount}
        limit={3}
        changePage={changePage}
      />
    </section>
  );
};

export default MyMessageBody;
