import React, { FunctionComponent } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SearchConfContent from "./SearchConfContent";

const SearchConf: FunctionComponent = () => {
  return (
    <>
      <Header />
      <SearchConfContent />
      <Footer />
    </>
  );
};

export default SearchConf;
