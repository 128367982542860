export const extractFormat = (
  videoLinks: Array<string>,
  pdfLinks: Array<string>
): string => {
  if (videoLinks?.length > 0 && pdfLinks?.length > 0) {
    return "Video and PDF";
  } else if (videoLinks?.length > 0) {
    return "Video";
  } else if (pdfLinks?.length > 0) {
    return "PDF";
  } else {
    return "No media";
  }
};
