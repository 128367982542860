import React, { FunctionComponent } from "react";
import SyncLoader from "react-spinners/SyncLoader";
import { css } from "@emotion/react";

interface SyncLoaderSpinnerProps {
  isLoading: boolean;
  override: any;
}

const SyncLoaderSpinner: FunctionComponent<SyncLoaderSpinnerProps> = ({
  isLoading,
  override,
}: SyncLoaderSpinnerProps) => {
  // loading indicator styling
  const defaultOverride = css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  `;
  return (
    <SyncLoader
      color={"#ed1c24"}
      loading={isLoading}
      css={override ? override : defaultOverride}
      size={20}
    />
  );
};

export default SyncLoaderSpinner;
