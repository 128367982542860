import React, { useEffect, useState } from "react";
import Board from "../../components/Board";
import Pagination from "../../components/Pagination";
import { css } from "@emotion/react";
import { useAuth } from "../../navigation/Auth/ProvideAuth";
import { CLINICALNEWS } from "../../navigation/CONSTANT";
import newsService from "../../services/news";
import SyncLoaderSpinner from "../../components/SyncLoader";
import { useProvideAuth } from "../../navigation/Auth/useProvideAuth";

const ClincicalNews = () => {
  const { auth } = useAuth();
  const { signout } = useProvideAuth();

  const [news, setNews] = useState<any>({
    data: [],
    meta: {
      pagination: {
        limit: 3,
        next: null,
        page: 0,
        pages: 0,
        prev: null,
        total: 0,
      },
    },
  });

  const [activePage, setActivePage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const loaderStyle = css`
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
  `;
  const setPageNumber = (pageNumber: number) => {
    setActivePage(pageNumber);
  };
  useEffect(() => {
    const getAllNewsInformation = async () => {
      setLoading(true);
      try {
        const clinicalNewsResp = await newsService.getNewsType(
          "clinical-news",
          auth?.access_token,
          activePage
        );
        setNews({ ...news, ...clinicalNewsResp });
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        if (error?.response?.status === 401) {
          await signout();
        }
      }
    };

    getAllNewsInformation();
  }, [activePage]);
  return loading ? (
    <SyncLoaderSpinner isLoading={loading} override={loaderStyle} />
  ) : (
    <div className="information-menu">
      <Board
        articles={news?.data}
        title="Clinical News"
        navigateTo={CLINICALNEWS}
        viewAll={false}
      />
      <Pagination
        paginationDetails={news?.meta?.pagination}
        setPageNumber={setPageNumber}
      />
    </div>
  );
};

export default ClincicalNews;
