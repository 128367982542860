import React from "react";
import TermsAndCondHeader from "./TermsAndCondHeader";
import TermsAndCondBody from "./TermsAndCondBody";
import "./TermsAndCondContent.scss";

const TermsAndCondContent = () => {
  return (
    <main className="terms-cond-page">
      <TermsAndCondHeader />
      <TermsAndCondBody />
    </main>
  );
};

export default TermsAndCondContent;
