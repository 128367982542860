import React, { FunctionComponent } from "react";
const MessageHeader: FunctionComponent = () => {
  return (
    <section className="message-page-header">
      <h1>Contact</h1>
    </section>
  );
};

export default MessageHeader;
