import React, { FunctionComponent } from "react";
import MyMessageClickThroughHeader from "./MyMessageClickThroughHeader";
import MyMessageClickThroughBody from "./MyMessageClickThroughBody";
import "./MyMessageClickThroughContent.scss";

const MyMessageClickThroughContent: FunctionComponent = () => {
  return (
    <main className="my-message-clth-page">
      <MyMessageClickThroughHeader />
      <MyMessageClickThroughBody />
    </main>
  );
};

export default MyMessageClickThroughContent;
