import React from "react";
import Shimmer from "./Shimmer";
import SkeletonElement from "./SkeletonElement";

const SkeletonArticleHeading = () => {
  return (
    <div className="skeleton-wrapper title-container">
      <SkeletonElement type="title" />
      <SkeletonElement type="button" />
      <Shimmer />
    </div>
  );
};

export default SkeletonArticleHeading;
