import React, { FunctionComponent } from "react";
import LoginHeader from "./LoginHeader";
import LoginForm from "./LoginForm";
import LoginFooter from "./LoginFooter";
import "./LoginContent.scss";

const LoginContent: FunctionComponent = () => {
  return (
    <main className="login-page">
      <LoginHeader />
      <LoginForm />
      <LoginFooter />
    </main>
  );
};

export default LoginContent;
