import React, { createContext, useContext, useReducer } from "react";
import { newsReducer, initialNews } from "./NewsReducer";

interface AppContextInterface {
  news: any;
  setNews: React.Dispatch<any>;
}
const NewsContext = createContext<AppContextInterface>(
  {} as AppContextInterface
);

export const NewsProvider = ({ children }: { children: React.ReactNode }) => {
  const [news, setNews] = useReducer(newsReducer, initialNews);

  return (
    <NewsContext.Provider value={{ news, setNews }}>
      {children}
    </NewsContext.Provider>
  );
};

export const useNewsContext = () => useContext(NewsContext);
