import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL + "/information/news";
const ratingURL = process.env.REACT_APP_BASE_URL + "/rating";
const commentURL = process.env.REACT_APP_BASE_URL + "/comment";
const saveArticleURL = process.env.REACT_APP_BASE_URL + "/save-article";

// to get all news article
const getAllNews = async () => {
  const response = await axios.get(baseUrl, {
    params: {
      limit: 12345,
      page: 1,
      tag: "information",
    },
  });

  return response.data;
};
// to get news of particular type
const getNewsType = async (tag: string, accessToken: string, page = 1) => {
  const response = await axios.get(baseUrl, {
    params: {
      limit: 5,
      page: page,
      tag,
    },
    headers: { "takeda-access-token": accessToken },
  });

  return response.data;
};
// to get a particular news article
const getNews = async (newsId: string | undefined, accessToken: string) => {
  const response = await axios.get(baseUrl + "/" + newsId, {
    headers: { "takeda-access-token": accessToken },
  });
  return response.data;
};
// to rate a particular news article
const rateArticle = async (
  accessToken: string,
  articleId: string | undefined,
  rating: number,
  moduleType: string
) => {
  if (!articleId) {
    return;
  }
  const config = {
    headers: { "takeda-access-token": accessToken },
  };
  const ratingDetails = {
    rating,
    moduleType,
  };
  const response = await axios.post(
    ratingURL + "/" + articleId,
    ratingDetails,
    config
  );

  return response.data;
};

// to post a comment on a particular news article
const addComment = async (
  articleId: string,
  commentText: string,
  accessToken: string
) => {
  const config = {
    headers: { "takeda-access-token": accessToken },
  };
  const commentDetails = {
    commentText,
  };
  const response = await axios.post(
    commentURL + "/" + articleId,
    commentDetails,
    config
  );
  return response.data;
};

// to post a reply on a comment in a particular news article
const addReply = async (
  articleId: string,
  commentText: string,
  commentId: number,
  accessToken: string
) => {
  const config = {
    headers: { "takeda-access-token": accessToken },
  };
  const commentDetails = {
    commentText,
    commentId,
  };
  const response = await axios.post(
    commentURL + "/" + articleId,
    commentDetails,
    config
  );
  return response.data;
};

// to get comment on  a particular article
const getComment = async (
  articleId: string | undefined,
  limit: number,
  page: number,
  accessToken: string
) => {
  if (!articleId) return null;
  const response = await axios.get(commentURL + "/" + articleId, {
    params: {
      limit,
      page,
    },
    headers: { "takeda-access-token": accessToken },
  });

  return response.data;
};

// to edit a comment
const updateComment = async (
  commentText: string,
  commentId: number,
  accessToken: string
) => {
  const config = {
    headers: { "takeda-access-token": accessToken },
  };
  const commentDetails = {
    commentText,
  };
  const response = await axios.put(
    commentURL + "/" + commentId,
    commentDetails,
    config
  );
  return response.data;
};

// to delete a comment
const deleteComment = async (commentId: number, accessToken: string) => {
  const config = {
    headers: { "takeda-access-token": accessToken },
  };

  const response = await axios.delete(commentURL + "/" + commentId, config);
  return response.data;
};

// save news article
const saveArticle = async (
  articleId: string | undefined,
  accessToken: string
) => {
  if (!articleId) return;
  const config = {
    headers: { "takeda-access-token": accessToken },
  };

  const response = await axios.post(
    saveArticleURL + "/" + articleId,
    {},
    config
  );
  return response.data;
};
// unsave news article
const unsaveArticle = async (
  articleId: string | undefined,
  accessToken: string
) => {
  if (!articleId) return;
  const config = {
    headers: { "takeda-access-token": accessToken },
  };

  const response = await axios.delete(saveArticleURL + "/" + articleId, config);
  return response.data;
};

// save news article
const getSavedArticles = async (page: number, accessToken: string) => {
  const response = await axios.get(saveArticleURL, {
    params: {
      limit: 2,
      page,
    },
    headers: { "takeda-access-token": accessToken },
  });
  return response.data;
};

const newsService = {
  getAllNews,
  getNewsType,
  rateArticle,
  getNews,
  addComment,
  getComment,
  addReply,
  updateComment,
  deleteComment,
  saveArticle,
  unsaveArticle,
  getSavedArticles,
};

export default newsService;
