import React from "react";
import "./ActPreferenceHeading.scss";
const ActPreferenceHeading = () => {
  return (
    <section className="act-preference-heading">
      <div className="title-container">
        <h1>Activity Preferences</h1>
      </div>
    </section>
  );
};

export default ActPreferenceHeading;
