import "./UpdateProfileContent.scss";
import React, { FunctionComponent } from "react";
import UpdateProfileForm from "./UpdateProfileForm";
import UpdateProfileHeading from "./UpdateProfileHeading";
const UpdateProfileContent: FunctionComponent = () => {
  return (
    <main>
      <UpdateProfileHeading />
      <UpdateProfileForm />
    </main>
  );
};

export default UpdateProfileContent;
