import axios from "axios";

const baseEducationURL =
  process.env.REACT_APP_BASE_URL + "/education/education-modules";
const saveEducationModuleURL =
  process.env.REACT_APP_BASE_URL + "/education/save-education-modules";
const getEducationModuleTopicsURL =
  process.env.REACT_APP_BASE_URL + "/education/topics";

type sortByValues = "added" | "views" | "rating";
type orderByValues = "asc" | "desc";
const getAllEducationModules = async (
  page: number,
  accessToken: string,
  sortBy: sortByValues = "added",
  orderBy: orderByValues = "desc"
) => {
  const response = await axios.get(baseEducationURL, {
    params: {
      limit: 3,
      page,
      sortBy,
      orderBy,
    },
    headers: { "takeda-access-token": accessToken },
  });
  return response.data;
};
const getEducationModule = async (
  educationId: string | undefined,
  accessToken: string
) => {
  const response = await axios.get(baseEducationURL + "/" + educationId, {
    headers: { "takeda-access-token": accessToken },
  });
  return response.data;
};

const getSavedEducationModule = async (
  pageNumber: number,
  accessToken: string
) => {
  const response = await axios.get(saveEducationModuleURL, {
    params: {
      limit: 2,
      page: pageNumber,
    },
    headers: { "takeda-access-token": accessToken },
  });
  return response.data;
};

const saveEducationModule = async (
  educationModuleId: string | undefined,
  accessToken: string
) => {
  if (!educationModuleId) return;
  const config = {
    headers: { "takeda-access-token": accessToken },
  };
  const response = await axios.post(
    saveEducationModuleURL,
    { educationModuleId },
    config
  );

  return response.data;
};
const removeSavedEducationModule = async (
  educationModuleId: string | undefined,
  accessToken: string
) => {
  if (!educationModuleId) return;
  const config = {
    headers: { "takeda-access-token": accessToken },
    data: { educationModuleId },
  };
  const response = await axios.delete(saveEducationModuleURL, config);

  return response.data;
};

const getEducationModuleTopics = async (accessToken: string) => {
  const response = await axios.get(getEducationModuleTopicsURL, {
    headers: { "takeda-access-token": accessToken },
  });
  return response.data;
};

const educationService = {
  getAllEducationModules,
  getEducationModule,
  getSavedEducationModule,
  saveEducationModule,
  removeSavedEducationModule,
  getEducationModuleTopics,
};

export default educationService;
