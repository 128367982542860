import React from "react";
import AboutUsHeader from "./AboutUsHeader";
import AboutUsBody from "./AboutUsBody";
import "./AboutUsContent.scss";

const AboutUsContent = () => {
  return (
    <main className="about-us-page">
      <AboutUsHeader />
      <AboutUsBody />
    </main>
  );
};

export default AboutUsContent;
