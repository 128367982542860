import "./ViewProfileBody.scss";
import { Formik, Form, ErrorMessage } from "formik";
import React, { useEffect, useRef, useState } from "react";

import FormikControl from "../../../../components/Form/FormikControl";
import ImagePreviewComponent from "../../../../components/Form/ImagePreviewComponent";
import { ProfileInfoProps } from "../../../../types";
const ViewProfileBody = ({
  profileDetails,
}: {
  profileDetails: ProfileInfoProps;
}) => {
  const onSubmit = async (values: any, actions: any) => {
    return undefined;
  };
  const { title, firstName, lastName } = profileDetails;
  const fullName = [title, firstName, lastName].join(" ");
  const initialValues = { fullName, ...profileDetails };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, errors, isValid, setFieldValue }) => (
        <section className="view-profile-form">
          <div className="form-container">
            <Form className="profile-form">
              <ImagePreviewComponent file={values.image} />
              <FormikControl
                control="input"
                type="text"
                label="Name"
                name="fullName"
                disabled
              />
              <FormikControl
                control="input"
                type="text"
                label="Region"
                name="region"
                disabled
              />
              <FormikControl
                control="input"
                type="text"
                label="Organisation"
                name="organisation"
                disabled
              />

              <FormikControl
                control="input"
                type="text"
                label="Speciality"
                name="speciality"
                disabled
              />

              <FormikControl
                control="input"
                type="text"
                label="Seniority"
                name="seniority"
                disabled
              />
              {/* <FormikControl
                control="input"
                type="text"
                label="Email Address"
                name="email"
                disabled
              />
              <FormikControl
                control="input"
                type="text"
                label="Mobile Number"
                name="mobileNumber"
                disabled
              /> */}
            </Form>
          </div>
        </section>
      )}
    </Formik>
  );
};

export default ViewProfileBody;
