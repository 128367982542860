import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const resetPwdReqURL = "/user/reset-password-email";
const resetPwdURL = "/user/reset-password";

interface ResetPasswordEmailCredential {
  email: string;
}

interface ResetPasswordCredential {
  newPassword: string;
  recoveryToken: string;
}

const requestPasswordReset = async (
  credential: ResetPasswordEmailCredential,
  reCaptchaToken: any
) => {
  const config = {
    headers: { "g-recaptcha-response": reCaptchaToken },
  };
  const response = await axios.post(
    baseUrl + resetPwdReqURL,
    credential,
    config
  );
  return response.data;
};
const resetPassword = async (
  credential: ResetPasswordCredential,
  reCaptchaToken: any
) => {
  const config = {
    headers: { "g-recaptcha-response": reCaptchaToken },
  };
  const response = await axios.post(baseUrl + resetPwdURL, credential, config);
  return response.data;
};
const ResetPasswordService = {
  requestPasswordReset,
  resetPassword,
};

export default ResetPasswordService;
