import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import CommunityContent from "./CommunityContent";
interface CommunityProps {
  HeaderComponent: React.FunctionComponent;
  BodyComponent: React.FunctionComponent;
}

const Community = ({ HeaderComponent, BodyComponent }: CommunityProps) => {
  return (
    <>
      <Header />
      <CommunityContent
        HeaderComponent={() => <HeaderComponent />}
        BodyComponent={() => <BodyComponent />}
      />
      <Footer />
    </>
  );
};

export default Community;
