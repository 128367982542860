import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/images/header-logo.svg";
import DesktopNavigation from "./DesktopNavigation";
import "./Header.scss";
import MobileNavigation from "./MobileNavigation";

const Header: FunctionComponent = () => {
  return (
    <header className="header">
      <Link to="/">
        <div className="logo-container">
          <Logo />
        </div>
      </Link>
      <DesktopNavigation />
      <MobileNavigation />
    </header>
  );
};

export default Header;
