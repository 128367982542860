import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { SEARCH, SEARCHCONFERENCE } from "../../navigation/CONSTANT";
import { sortByValues } from "../../types";
import Button from "../Button";
import "./TherapyHeader.scss";

const TherapyHeader = ({
  filter,
  changeFilter,
  title,
  slug,
}: {
  filter: string;
  changeFilter: (value: sortByValues) => void;
  title: string;
  slug: string;
}) => {
  const history = useHistory();
  const searchLink = `/search/${slug}`;
  return (
    <div className="therapy-header">
      <h3>{title}</h3>
      <div className="filter-action">
        <div className="search-button">
          <Button
            type="button"
            size="size-lg"
            colorTheme="has-primary-theme"
            onClick={() => history.push(searchLink)}
          >
            Search
          </Button>
        </div>
        <div className="filter-bar">
          <div
            className={`filter-event ${
              filter === "added" ? "filter-event-active" : ""
            }`}
            onClick={() => changeFilter("added")}
          >
            Just added
          </div>
          <div
            className={`filter-event ${
              filter === "views" ? "filter-event-active" : ""
            }`}
            onClick={() => changeFilter("views")}
          >
            Most viewed
          </div>
          <div
            className={`filter-event ${
              filter === "rating" ? "filter-event-active" : ""
            }`}
            onClick={() => changeFilter("rating")}
          >
            Top rated
          </div>
        </div>
      </div>
    </div>
  );
};

export default TherapyHeader;
