import * as Yup from "yup";
export const ChangePwdValidationSchema = Yup.object({
  currentpwd: Yup.string().required("Current password is required"),
  newpwd: Yup.string()
    .trim()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])((?=.*?[0-9])|(?=.*?[#?!@$%^&*-])).{9,}$/,
      "Password criteria:\n 1. Have at least 9 characters\n 2. Contains mixed case letters\n 3. Contain at least 1 number OR a special character.\n 4. Allowed characters are: a-z, A-Z, 0-9 and !@#$*^().,{}[]~-"
    )
    .test(
      "no-match",
      "Current and New Password should be different",
      function (value) {
        return this.parent.currentpwd !== value;
      }
    )
    .required("New Password is required"),
  repeatpwd: Yup.string()
    .oneOf([Yup.ref("newpwd"), ""], "Passwords must match")
    .required("Repeat Password is required"),
});
