import React from "react";
import MessageCard from "../MessageCard";
import "./MyMessageBoard.scss";
import { MessageCardProps } from "../../types";
import { css } from "@emotion/react";
import SyncLoaderSpinner from "../SyncLoader";
const MyMessageBoard = ({
  messages,
  isLoading,
}: {
  messages: Array<MessageCardProps>;
  isLoading: boolean;
}) => {
  const loaderStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
  `;
  return (
    <div className="my-msg-board">
      {isLoading ? (
        <SyncLoaderSpinner isLoading={isLoading} override={loaderStyle} />
      ) : (
        messages.map((message, idx) => (
          <MessageCard key={"msg" + idx} {...message} cardType="MESSAGE" />
        ))
      )}
    </div>
  );
};

export default MyMessageBoard;
