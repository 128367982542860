import React, { useEffect, useState } from "react";
import "./ArticleInfoCard.scss";
import { ReactComponent as CommentIcon } from "../../assets/images/comment.svg";
import Rating from "../Rating";
import { useHistory } from "react-router";
import newsService from "../../services/news";
import { useAuth } from "../../navigation/Auth/ProvideAuth";
import { useProvideAuth } from "../../navigation/Auth/useProvideAuth";
interface ArticleInfoProps {
  id: string;
  title: string;
  excerpt: string;
  feature_image: string;
  rating?: number;
  commentsCount?: number;
  isSearchResult?: boolean;
}
interface ArticleInfoCardProps {
  articleInfo: ArticleInfoProps;
  baseURL: string;
}

const ArticleInfoCard = ({
  articleInfo: {
    id,
    title,
    excerpt,
    feature_image,
    rating,
    commentsCount,
    isSearchResult,
  },
  baseURL,
}: ArticleInfoCardProps) => {
  const { auth } = useAuth();
  const { signout } = useProvideAuth();
  const [commentCount, setCommentCount] = useState<number>(
    commentsCount ? commentsCount : 0
  );
  const [ratingVal, setRatingVal] = useState<number>(
    rating ? Math.round(rating) : 0
  );
  const history = useHistory();
  const handleClick = () => {
    history.push(baseURL + "/" + id);
  };

  useEffect(() => {
    const getCommentInfo = async () => {
      try {
        const commentInfo = await newsService.getComment(
          id,
          5,
          1,
          auth?.access_token
        );
        setCommentCount(commentInfo?.totalCommentsCount);
      } catch (error: any) {
        if (error?.response?.status === 401) {
          //place your reentry code
          await signout();
        }
      }
    };

    const getArticleInfo = async () => {
      try {
        const typeNewsResp = await newsService.getNews(id, auth?.access_token);
        setRatingVal(typeNewsResp?.data?.rating);
      } catch (error: any) {
        if (error?.response?.status === 401) {
          //place your reentry code
          await signout();
        }
      }
    };
    if (isSearchResult) {
      getCommentInfo();
      getArticleInfo();
    }
  }, []);
  return (
    <div className="article-info-card" onClick={handleClick}>
      <div className="article-header">
        <div className="article-img-container">
          <img src={feature_image} alt="feat-img" />
        </div>
        <div className="article-info-content">
          <h4 className="article-title">{title}</h4>
          <p className="article-desc">{excerpt}</p>
        </div>
      </div>
      <div className="article-footer">
        <div className="article-detail">
          <p>
            Posted by <span>Administrator</span>
          </p>
          <div className="space-breaker">|</div>
          <div className="comment-count">
            <CommentIcon />
            <p>
              <span>{commentCount} comments</span>
            </p>
          </div>
        </div>
        <Rating rating={ratingVal} />
      </div>
    </div>
  );
};

export default ArticleInfoCard;
