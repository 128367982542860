import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as CommentIcon } from "../../../assets/images/comment.svg";
import Button from "../../../components/Button";
import PdfViewer from "../../../components/PdfViewer";
import { useAuth } from "../../../navigation/Auth/ProvideAuth";
import educationService from "../../../services/cmemodules";

import "./Module.scss";
import { CMEModuleSingle } from "../../../types";
import { getVideoURL, prepareVideoLinks } from "./helper";
import PopUp from "../../../components/PopUp";
import SharePopUp from "../../../components/SharePopUp";
import ThankYou from "../../../components/ThankYouPopUp";
import SkeletonModuleBody from "../../../components/Skeleton/SkeletonModuleBody";
import { useLoggedUserContext } from "../../../context/LoggedUser";
const initialModuleInfo = {
  title: "",
  associatedTrials: "",
  conflictOfInterests: "",
  dateLaunched: "",
  description: "",
  figures: [],
  moduleIntro: "",
  pdfLinks: [],
  readings: [],
  references: [],
  topic: "",
  videoLinks: [],
  isSaved: false,
};
const Module = () => {
  const { moduleId } = useParams<{ moduleId?: string }>();
  const [loading, setLoading] = useState<boolean>(false);

  const { auth } = useAuth();
  const {
    loggedUserInfo: { digital_id, customer_id, crm_country },
  } = useLoggedUserContext();
  const [module, setModule] = useState<CMEModuleSingle>(initialModuleInfo);
  const [sharePopUp, setSharePopUp] = useState<boolean>(false);
  const [thankYouPopUp, setThankYouPopUp] = useState<string>("");

  const saveUnsaveModule = async () => {
    if (module?.isSaved) {
      // unsave it
      const removeModuleResp =
        await educationService.removeSavedEducationModule(
          moduleId,
          auth?.access_token
        );
    } else {
      // save it
      const saveModuleResp = await educationService.saveEducationModule(
        moduleId,
        auth?.access_token
      );
    }
    setModule({ ...module, isSaved: !module.isSaved });
  };

  const sharePopSubmitHandler = () => {
    setSharePopUp(false);
    setThankYouPopUp("YOU HAVE SUCCESSFULLY SHARED THE MODULE");
  };
  useEffect(() => {
    const getEducationModule = async () => {
      // get conference details
      setLoading(true);
      const moduleResp = await educationService.getEducationModule(
        moduleId,
        auth?.access_token
      );
      setLoading(false);
      setModule(moduleResp);
    };

    getEducationModule();
  }, [setModule]);

  const videoLinks = prepareVideoLinks(
    module?.videoLinks,
    digital_id,
    customer_id,
    crm_country
  );
  return (
    <div className="module">
      {loading && <SkeletonModuleBody />}
      {!loading && (
        <>
          <div className="about">
            {/* <h3>About this module</h3> */}
            {/* <p>
              <span>Date Launched:</span> {module?.dateLaunched}
            </p>
            <p>
              <span>Topic:</span> {module?.topic}
            </p>
            <p>
              <span>Associated Trials:</span> {module?.associatedTrials}
            </p>
            <p>
              <span>Conflict of Interest:</span> {module?.conflictOfInterests}
            </p> */}
            <p className="description">{module?.moduleIntro}</p>
          </div>
          <div className="module-content">
            {module?.pdfLinks.map((pdfLink, index) => (
              <div key={"pdf" + index} className="pdf-content">
                <PdfViewer pdfLink={pdfLink} />
              </div>
            ))}

            {videoLinks.map((video, index) => (
              <div key={"video" + index} className="video-content">
                <iframe
                  src={video}
                  allowFullScreen
                  allow="encrypted-media"
                  width="100%"
                  height="343"
                ></iframe>
              </div>
            ))}
          </div>
          {/* <div className="resources">
            <h3>resources</h3>
            <hr />
            <div className="references">
              <h3>references</h3>
              {module?.references.map((reference, index) => (
                <p key={"reference" + index}>
                  {reference.title} [
                  <a href={reference.link} target="_blank" rel="noreferrer">
                    full text
                  </a>
                  ]
                </p>
              ))}
              <hr />
            </div>
            <div className="readings">
              <h3>further reading and links</h3>
              {module?.readings.map((reading, index) => (
                <p key={"reading" + index}>
                  {reading.title} [
                  <a href={reading.link} target="_blank" rel="noreferrer">
                    full text
                  </a>
                  ]
                </p>
              ))}
              <hr />
            </div>
            <div className="figures">
              <h3>figures</h3>
              {module?.figures.map((figure, index) => (
                <p key={"figure" + index}>
                  <a href={figure.link} target="_blank" rel="noreferrer">
                    Figure {index + 1}:
                  </a>{" "}
                  {figure.title}
                </p>
              ))}
              <hr />
            </div>
          </div> */}
        </>
      )}

      <div className="module-action">
        <Button
          type="button"
          size="size-sml"
          colorTheme="has-primary-theme"
          onClick={saveUnsaveModule}
        >
          {module?.isSaved ? "Unsave" : "Save for Later"}
        </Button>
        <Button
          type="button"
          size="size-sml"
          colorTheme="has-primary-theme"
          onClick={() => setSharePopUp(true)}
        >
          Share
        </Button>
        {sharePopUp ? (
          <PopUp>
            <SharePopUp
              title={module?.title}
              submitHandler={sharePopSubmitHandler}
              cancelPopUp={() => setSharePopUp(false)}
            />
          </PopUp>
        ) : (
          ""
        )}
        {thankYouPopUp ? (
          <PopUp>
            <ThankYou
              msg={thankYouPopUp}
              submitHandler={() => setThankYouPopUp("")}
            />
          </PopUp>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Module;
