import React from "react";
import "./CommunityHeading.scss";
const CommunityHeading = () => {
  return (
    <section className="community-heading">
      <div className="title-container">
        <h1>Community</h1>
      </div>
    </section>
  );
};

export default CommunityHeading;
