import * as Yup from "yup";
// Supported File Formats
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

export const registrationValidationSchema = Yup.object({
  image: Yup.mixed()
    .test("FILE_SIZE", "Uploaded file is too big.", (value) => {
      const { size } = value || { size: null };
      return !size || size <= 1 * 1024 * 1024;
    })
    .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => {
      const { type } = value || { type: null };
      return !type || SUPPORTED_FORMATS.includes(type);
    }),
  title: Yup.string().required("Title is required"),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  region: Yup.string().required("Region is required"),
  organisation: Yup.string(),
  seniority: Yup.string(),
  speciality: Yup.string(),
  professionalNumber: Yup.string(),
  mobileNumber: Yup.string().test(
    "len",
    "Please enter mobile number with country code",
    (val) => (val ? val.length >= 12 : true)
  ),
});
