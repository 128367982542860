import React, { FunctionComponent, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Button from "../Button";
import "./PdfViewer.scss";

interface PdfViewerProps {
  pdfLink: string;
}

const PdfViewer: FunctionComponent<PdfViewerProps> = ({
  pdfLink,
}: PdfViewerProps) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };
  const changePage = (offSet: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offSet);
  };

  const changePageBack = () => {
    changePage(-1);
  };

  const changePageNext = () => {
    changePage(+1);
  };

  return (
    <div className="pdf-viewer">
      <div className="full-pdf">
        {/* <a href={pdfLink} target="_blank" rel="noreferrer">
          <Button
            type="button"
            size="size-sml"
            colorTheme="has-secondary-theme"
          >
            Full Screen
          </Button>
        </a> */}
      </div>
      <Document file={pdfLink} onLoadSuccess={onDocumentLoadSuccess}>
        <Page width={500} pageNumber={pageNumber} />
      </Document>
      <p>
        Page {pageNumber} of {numPages}
      </p>
      <div className="control-button">
        {pageNumber > 1 && (
          <Button
            type="button"
            onClick={changePageBack}
            size="size-sml"
            colorTheme="has-secondary-theme"
          >
            Previous Page
          </Button>
        )}
        {pageNumber < numPages && (
          <Button
            type="button"
            onClick={changePageNext}
            size="size-sml"
            colorTheme="has-secondary-theme"
          >
            Next Page
          </Button>
        )}
      </div>
    </div>
  );
};

export default PdfViewer;
