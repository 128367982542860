import React, { useEffect, useRef, useState } from "react";
import "./VerifyEmailRequestContent.scss";
import { Redirect, useParams } from "react-router";
import registerService from "../../../services/register";
import ReCaptcha from "react-google-recaptcha";
import {
  REGISTERSUCCESSUNVERIFIED,
  REGISTERSUCCESSVERIFIED,
} from "../../../navigation/CONSTANT";
import { verifyEmailErrorInformation } from "./errorTypes";

const VerifyEmailRequestContent = () => {
  const [error, setError] = useState("");
  const [takedaVerified, setTakedaVerified] = useState<number>(0);
  const { recoveryToken } = {
    recoveryToken: "",
    ...useParams(),
  };
  const reCaptchaRef = useRef<ReCaptcha>(null);

  useEffect(() => {
    const verifyEmailAndGenerateLead = async () => {
      try {
        let reCaptchaToken;
        if (reCaptchaRef.current) {
          reCaptchaToken = await reCaptchaRef.current.executeAsync();
          reCaptchaRef.current.reset();
        }
        const response = await registerService.verifyEmailAndGenerateLead(
          recoveryToken,
          reCaptchaToken
        );
        // emailVerifiedAndLeadCreated, emailVerifiedAndHcpVerifiedInstantly, failure case
        if (response === "emailVerifiedAndLeadCreated") {
          setTakedaVerified(1);
        } else if (response === "emailVerifiedAndHcpVerifiedInstantly") {
          setTakedaVerified(2);
        } else {
          setTakedaVerified(1);
        }
      } catch (error: any) {
        setError(
          verifyEmailErrorInformation[error?.response?.data?.errorMsg] ||
            verifyEmailErrorInformation[error?.response?.data] ||
            "Error in verifying email"
        );
        setTakedaVerified(3);
      }
    };

    verifyEmailAndGenerateLead();
  }, []);
  switch (takedaVerified) {
    case 0:
      return (
        <main className="verify-email-req-page">
          <ReCaptcha
            ref={reCaptchaRef}
            sitekey={
              process.env.REACT_APP_Recaptcha_Site_key
                ? process.env.REACT_APP_Recaptcha_Site_key
                : ""
            }
            size="invisible"
          />
          <section className="verify-email-req-page-content">
            <h3>Please wait while we verify your account</h3>
          </section>
        </main>
      );
    case 1:
      return <Redirect to={REGISTERSUCCESSUNVERIFIED} />;
    case 2:
      return <Redirect to={REGISTERSUCCESSVERIFIED} />;
    case 3:
      return (
        <main className="verify-email-req-page">
          <section className="verify-email-req-page-content">
            <h3>{error}</h3>
          </section>
        </main>
      );
    default:
      return null;
  }
};

export default VerifyEmailRequestContent;
