import React, { FunctionComponent } from "react";
import "./SupportMessage.scss";

interface SupportMessageProps {
  msg: string;
  email: string;
}

const SupportMessage: FunctionComponent<SupportMessageProps> = ({
  msg,
  email,
}: SupportMessageProps) => {
  return (
    <div className="supportmessage">
      <h5>
        {msg} <a href={"mailto:" + email}>{email}</a>
      </h5>
    </div>
  );
};

export default SupportMessage;
