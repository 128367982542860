import React, { FunctionComponent } from "react";
import ContactUsHeader from "./ContactUsHeader";
import ContactUsForm from "./ContactUsForm";
import "./ContactUsContent.scss";

const ContactUsContent: FunctionComponent = () => {
  return (
    <main className="contact-us-page">
      <ContactUsHeader />
      <ContactUsForm />
    </main>
  );
};

export default ContactUsContent;
