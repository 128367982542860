import React, { FunctionComponent } from "react";
const AboutUsHeader = () => {
  return (
    <section className="about-us-header">
      <h1>About Us</h1>
    </section>
  );
};

export default AboutUsHeader;
