export const getConfByConfId = (
  conferences: any,
  confId: string | undefined
) => {
  return conferences
    ? conferences.find((conference: any) => conference.id === confId)
    : null;
};

export const prepareVideoURL = (
  videoURL: string,
  digitalId: string,
  customerID: string,
  crmCountry: string
): string => {
  return `${videoURL}&autoplay=false&bccustomguid=${customerID}&bcknownuser=1&bctrackingid=${crmCountry}:${digitalId}`;
};
