import React, { useEffect, useState } from "react";
import { ReactComponent as CommentIcon } from "../../../assets/images/comment.svg";
import Rating from "../../../components/Rating";

import "./Article.scss";
import CommentCard from "../../../components/CommentCard";
import Button from "../../../components/Button";
import PopUp from "../../../components/PopUp";
import SharePopUp from "../../../components/SharePopUp";
import ThankYou from "../../../components/ThankYouPopUp";
import RatePopUp from "../../../components/RatePopUp";
import { useParams } from "react-router";
import { useNewsContext } from "../../../context/News";
import { getArticleByArticleId, getNewsRoute, getNewsType } from "../helper";
import newsService from "../../../services/news";
import { useAuth } from "../../../navigation/Auth/ProvideAuth";
import { ratingErrorInformation } from "../../ConfPage/Conference/errorTypes";
import { useLoggedUserContext } from "../../../context/LoggedUser";
import ArticleAddComment from "../../../components/ArticleAddComment";
import ArticleInteraction from "../../../components/ArticleInteraction";
import SkeletonArticleBody from "../../../components/Skeleton/SkeletonArticleBody";

const Article = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const { auth } = useAuth();
  const { loggedUserInfo } = useLoggedUserContext();
  const { articleType, articleId } =
    useParams<{ articleType?: string; articleId?: string }>();
  const { news, setNews } = useNewsContext();

  const [ratePopUp, setRatePopUp] = useState<boolean>(false);
  const [sharePopUp, setSharePopUp] = useState<boolean>(false);
  const [commentsLoading, setCommentsLoading] = useState<boolean>(true);
  const [thankYouPopUp, setThankYouPopUp] = useState<string>("");

  const submitCommenthandler = async (commentText: string) => {
    const response = await newsService.addComment(
      articleId ? articleId : "",
      commentText,
      auth?.access_token
    );
    const comment = {
      id: response.commentId,
      userId: loggedUserInfo.id,
      image: loggedUserInfo.image,
      firstName: loggedUserInfo.firstName,
      lastName: loggedUserInfo.lastName,
      commentText,
    };
    setNews({
      type: "ADD_COMMENT",
      payload: {
        articleId,
        comment,
        articleType: getNewsType(articleType),
      },
    });
  };
  const submitReplyHandler = async (commentText: string, commentId: number) => {
    const response = await newsService.addReply(
      articleId ? articleId : "",
      commentText,
      commentId,
      auth?.access_token
    );
    const comment = {
      id: response.commentId,
      userId: loggedUserInfo.id,
      image: loggedUserInfo.image,
      firstName: loggedUserInfo.firstName,
      lastName: loggedUserInfo.lastName,
      commentText,
    };
    setNews({
      type: "ADD_REPLY",
      payload: {
        articleType: getNewsType(articleType),
        articleId,
        commentId,
        comment,
      },
    });
  };

  const ratePopSubmitHandler = async (ratingValue: number) => {
    try {
      const response = await newsService.rateArticle(
        auth?.access_token,
        articleId,
        ratingValue,
        "clinical-news"
      );
      setThankYouPopUp("your rating has been submitted");
      setRatePopUp(false);
      setNews({
        type: "RATE_NEWS_ARTICLE",
        payload: {
          articleId,
          articleType: getNewsType(articleType),
          rating: ratingValue,
        },
      });
    } catch (error: any) {
      setThankYouPopUp(
        ratingErrorInformation[error.response.data] ||
          "Rating could not be saved."
      );
      setRatePopUp(false);
    }
  };

  const deleteCommentHandler = async (commentId: number, replyId?: number) => {
    if (replyId) {
      //delete the reply
      const response = await newsService.deleteComment(
        replyId,
        auth?.access_token
      );
      setNews({
        type: "DELETE_REPLY",
        payload: {
          articleId,
          commentId,
          replyId,
          articleType: getNewsType(articleType),
        },
      });
    } else {
      //delete the comment
      const response = await newsService.deleteComment(
        commentId,
        auth?.access_token
      );
      setNews({
        type: "DELETE_COMMENT",
        payload: {
          articleId,
          commentId,
          replyId,
          articleType: getNewsType(articleType),
        },
      });
    }
  };

  const sharePopSubmitHandler = () => {
    setSharePopUp(false);
    setThankYouPopUp("YOU HAVE SUCCESSFULLY SHARED THE ARTICLE");
  };

  const changePageNumber = (changeVal: number) => {
    setPageNumber(pageNumber + changeVal);
  };

  const getCommentInformation = async (pageNumber: number) => {
    // comment information
    setCommentsLoading(true);
    const commentInfo = await newsService.getComment(
      articleId,
      5,
      pageNumber,
      auth?.access_token
    );
    setNews({
      type: "GET_COMMENTS",
      payload: {
        articleId,
        commentInfo,
        articleType: getNewsType(articleType),
      },
    });
    setCommentsLoading(false);
  };

  const selectedNews = getArticleByArticleId(
    news,
    getNewsType(articleType),
    articleId
  );

  useEffect(() => {
    const getAllNewsInformation = async () => {
      setLoading(true);
      const typeNewsResp = await newsService.getNews(
        articleId,
        auth?.access_token
      );
      setNews({
        type: `GET_NEWS_ARTICLE`,
        payload: {
          articleDetails: typeNewsResp.data,
          articleType: getNewsType(articleType),
        },
      });
      setLoading(false);

      // comment information
      await getCommentInformation(1);
    };

    getAllNewsInformation();
  }, []);

  const {
    title,
    feature_image,
    excerpt,
    rating,
    html,
    commentCount,
    comments,
  } = selectedNews
    ? selectedNews
    : {
        title: "",
        feature_image: "",
        excerpt: "",
        html: "",
        rating: 0,
        commentCount: 0,
        comments: [],
      };

  return (
    <div className="article">
      <div className="article-top">
        {loading && <SkeletonArticleBody />}
        {!loading && (
          <div className="article-main">
            <div className="article-img">
              <img src={feature_image} alt={title} />
            </div>
            <div className="article-info">
              <p>
                Posted by <span>Administrator</span>
              </p>
              <Rating rating={rating} />
            </div>

            <div
              className="article-content"
              dangerouslySetInnerHTML={{ __html: html }}
            ></div>
          </div>
        )}

        <ArticleInteraction
          commentCount={commentCount}
          comments={comments}
          submitReplyHandler={submitReplyHandler}
          deleteCommentHandler={deleteCommentHandler}
          changePageNumber={changePageNumber}
          getCommentInformation={getCommentInformation}
          pageNumber={pageNumber}
          isLoading={commentsLoading}
        />
        <ArticleAddComment submitCommenthandler={submitCommenthandler} />
      </div>

      <div className="article-footer">
        <div className="contribute-buttons">
          <Button
            type="button"
            size="size-lg"
            colorTheme="has-secondary-theme"
            onClick={() => setRatePopUp(true)}
          >
            Rate
          </Button>
          <Button
            type="button"
            size="size-lg"
            colorTheme="has-secondary-theme"
            onClick={() => setSharePopUp(true)}
          >
            Share
          </Button>
          {ratePopUp ? (
            <PopUp>
              <RatePopUp
                submitHandler={ratePopSubmitHandler}
                cancelPopUp={() => setRatePopUp(false)}
              />
            </PopUp>
          ) : (
            ""
          )}
          {sharePopUp ? (
            <PopUp>
              <SharePopUp
                title={title}
                submitHandler={sharePopSubmitHandler}
                cancelPopUp={() => setSharePopUp(false)}
              />
            </PopUp>
          ) : (
            ""
          )}
          {thankYouPopUp ? (
            <PopUp>
              <ThankYou
                msg={thankYouPopUp}
                submitHandler={() => setThankYouPopUp("")}
              />
            </PopUp>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Article;
