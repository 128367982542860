import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const feedbackURL = "/user/feedback";
const contactURL = "/user/contact";
const inviteFriendURL = "/user/invite";

interface sendFeedbackPayload {
  feature?: string;
  feedbackText: string;
}
interface contactPayload {
  topic?: string;
  messageText: string;
}

interface inviteFriendPayload {
  email: string;
}

const userFeedback = async (
  accessToken: string,
  feedbackDetails: sendFeedbackPayload
) => {
  const config = {
    headers: { "takeda-access-token": accessToken },
  };
  const response = await axios.post(
    baseUrl + feedbackURL,
    feedbackDetails,
    config
  );
  return response.data;
};

const userContact = async (
  accessToken: string,
  contactDetails: contactPayload
) => {
  const config = {
    headers: { "takeda-access-token": accessToken },
  };
  const response = await axios.post(
    baseUrl + contactURL,
    contactDetails,
    config
  );
  return response.data;
};
const inviteFriend = async (
  accessToken: string,
  inviteDetails: inviteFriendPayload
) => {
  const config = {
    headers: { "takeda-access-token": accessToken },
  };
  const response = await axios.post(
    baseUrl + inviteFriendURL,
    inviteDetails,
    config
  );
  return response.data;
};

const feedbackService = { userFeedback, userContact, inviteFriend };

export default feedbackService;
