import React, { useEffect } from "react";
import { useState } from "react";
import defaultAvatar from "../../assets/images/default-avatar.png";
import "./ImagePreview.scss";

const ImagePreviewComponent = ({ file }: { file: any }) => {
  const [previewImage, setPreviewImage] = useState<string | ArrayBuffer | null>(
    null
  );

  useEffect(() => {
    if (file === null) {
      setPreviewImage(null);
      return;
    }
    if (typeof file === "string") {
      setPreviewImage(file);
      return;
    }
    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
    }
  }, [file]);

  return (
    <div className="previewImage">
      {typeof previewImage !== "string" ? (
        <img src={defaultAvatar} alt="preview" />
      ) : (
        <img src={previewImage} alt="preview" />
      )}
    </div>
  );
};

export default ImagePreviewComponent;
