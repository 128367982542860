import Button from "../Button";
import React from "react";
import { useActPreferenceContext } from "../../context/ActivityPreference";
import "./ActivityCard.scss";
import actPreferenceService from "../../services/activityPreference";
import { useAuth } from "../../navigation/Auth/ProvideAuth";

interface ActivityCardType {
  activityTypeId: number;
  name: string;
  description: string;
  optInStatus: boolean;
}

const ActivityCard = ({
  activityTypeId,
  name,
  description,
  optInStatus,
}: ActivityCardType) => {
  const { auth } = useAuth();
  const { setActPreference } = useActPreferenceContext();
  const handleClick = async () => {
    if (optInStatus) {
      await actPreferenceService.activityOptOut(
        {
          activityTypeId,
        },
        auth?.access_token
      );
      setActPreference({
        type: "UPDATE_OPT_STATUS",
        payload: { activityTypeId },
      });
    } else {
      await actPreferenceService.activityOptIn(
        {
          activityTypeId,
        },
        auth?.access_token
      );
      setActPreference({
        type: "UPDATE_OPT_STATUS",
        payload: { activityTypeId },
      });
    }
  };

  return (
    <div className="activity-card">
      <div className="activity-card-info">
        <h3>{name}</h3>
        <p>{description}</p>
      </div>
      <Button
        type="button"
        size="size-sm"
        colorTheme={!optInStatus ? "has-primary-theme" : "has-secondary-theme"}
        onClick={handleClick}
      >
        {optInStatus ? "Opt-Out" : "Opt-In"}
      </Button>
    </div>
  );
};

export default ActivityCard;
