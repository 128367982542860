export const ROOT = "/";
export const HOME = "/";
export const LOGOUT = "/";
export const LOGIN = "/login";
export const REGISTERSUCCESSVERIFIED = "/registersuccess/verified";
export const REGISTERSUCCESSUNVERIFIED = "/registersuccess/notverified";
export const VERIFYEMAIL = "/verifyemail";
export const VERIFYEMAILREQUEST = "/verifyEmail/:recoveryToken";
export const RESETPASSWORDSUCCESS = "/resetpasswordsuccess";
export const RESETPASSWORDREQUEST = "/resetpasswordrequest";
export const RESETPASSWORDREQUESTSUCCESS = "/resetpasswordrequestsuccess";
export const RESETPASSWORD = "/resetpassword/:recoveryToken";
export const CHANGEPASSWORD = "/changepassword";
export const CHANGEPASSWORDSUCCESS = "/changepasswordsuccess";
export const LOGINCALLBACK = "/callback";
export const REGISTER = "/register";
export const REGISTERSUCCESS = "/registersuccess/:verificationStatus";
export const ABOUTUS = "/about-us";
export const CONTACTUS = "/contact-us";
export const DASHBOARD = "/dashboard";
export const COMMUNITY = "/community";
export const FEEDBACK = "/feedback";
export const UPDATEPROFILE = "/updateprofile";
export const INVITEFRIEND = "/invitefriend";
export const INVITEFRIENDSUCCESS = "/invitefriendsuccess";
export const ARTICLE = "/community/news/:articleType/:articleId";
export const PRIVACYPOLICY = "/privacypolicy";
export const COOKIESPOLICY = "/cookiespolicy";
export const TERMSANDCONDITION = "/termsandcondition";
export const NEWS = "/community/news";
export const ACTIVITYPREFERENCE = "/community/activitypreference";
export const EDUCATION = "/community/education";
export const ACCOUNTS = "/community/accounts";
export const CLINICALNEWS = "/community/news/clinicalnews";
export const JOURNALWATCH = "/community/news/journalwatch";
export const OTHERRESOURCES = "/community/news/otherresources";
export const SEARCHARTICLE = "/community/news/searcharticle/:searchQuery";
export const SEARCHARTICLEBASE = "/community/news/searcharticle/";
export const CMEMODULES = "/community/education/cmemodules";
export const CONFERENCEHIGHLIGHT = "/community/education/conferencehighlights";
export const RAREHAEMATOLOGY = "/community/education/rarehaematology";
export const RAREGENETICDISEASE = "/community/education/raregeneticdisease";
export const ONCOLOGY = "/community/education/oncology";
export const IMMUNOLOGY = "/community/education/immunology";
export const GASTROENTEROLOGY = "/community/education/gastroenterology";
export const CONFERENCE =
  "/community/education/conferencehighlights/:conferenceId";
export const CMEMODULE = "/community/education/cmemodules/:moduleId";
export const POSTRAREHAEMATOLOGY =
  "/community/education/rarehaematology/:postId";
export const POSTRAREGENETICDISEASE =
  "/community/education/raregeneticdisease/:postId";
export const POSTONCOLOGY = "/community/education/oncology/:postId";
export const POSTIMMUNOLOGY = "/community/education/immunology/:postId";
export const POSTGASTROENTEROLOGY =
  "/community/education/gastroenterology/:postId";

export const MESSAGEBASE = "/message";
export const MESSAGE = "/message/:receiverId";
export const MESSAGESUCCESS = "/messagesuccess";
export const MYMESSAGE = "/mymessage";
export const MYMESSAGECLICKTHROUGHBASE = "/mymessageclickthrough/";
export const MYMESSAGECLICKTHROUGH = "/mymessageclickthrough/:recipientId";
export const COMMUNITYMEMBERS = "/communitymembers";
export const VIEWPROFILE = "/viewprofile/:profileId";
export const VIEWPROFILEBASE = "/viewprofile";
export const SEARCH = "/search";
export const FILTERMODULES = "/filtermodules";
export const SEARCHCONFERENCE = "/search/:searchCategory";
export const ANALYTICSDASH = "/report";
