import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import ArticleInfoCard from "../ArticleInfoCard";

import "./Board.scss";
interface ArticleProps {
  id: string;
  title: string;
  excerpt: string;
  feature_image: string;
  rating?: number;
}
interface BoardProps {
  title: string;
  navigateTo: string;
  articles: Array<ArticleProps>;
  viewAll: boolean;
}

const Board: FunctionComponent<BoardProps> = ({
  title,
  articles,
  navigateTo,
  viewAll,
}: BoardProps) => {
  return (
    <div className="board">
      <h3 className="board-title">{title}</h3>
      <div className="board-content">
        {articles.length > 0 ? (
          articles.map((article) => (
            <ArticleInfoCard
              key={article.id}
              articleInfo={article}
              baseURL={navigateTo}
            />
          ))
        ) : (
          <div className="no-article-found">
            <p>No relevant article found</p>
          </div>
        )}
        {viewAll ? <Link to={navigateTo}>View All</Link> : ""}
      </div>
    </div>
  );
};

export default Board;
