import React, { FunctionComponent } from "react";
import FeedbackHeader from "./FeedbackHeader";
import FeedbackForm from "./FeedbackForm";
import "./FeedbackContent.scss";

const FeedbackContent: FunctionComponent = () => {
  return (
    <main className="feedback-page">
      <FeedbackHeader />
      <FeedbackForm />
    </main>
  );
};

export default FeedbackContent;
