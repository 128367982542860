import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const OktaIssuer = process.env.REACT_APP_OKTA_ISSUER;
const OktaClientId = process.env.REACT_APP_OKTA_CLIENT_ID;
const authenticateURL = "/auth/login";
const getTokensURL = "/user/token";
const revokeTokenURL = "/user/revoke-access-token";
const sessionURL = `${OktaIssuer}/api/v1/sessions/me`;
interface CredentialType {
  email: string;
  password: string;
}
const login = async (credential: CredentialType, reCaptchaToken: any) => {
  const config = {
    headers: { "g-recaptcha-response": reCaptchaToken },
  };

  const response = await axios.post(
    baseUrl + authenticateURL,
    credential,
    config
  );
  return response.data;
};

const generateAuthorizeURL = (sessionToken: string) => {
  return `${OktaIssuer}/oauth2/default/v1/authorize?client_id=${OktaClientId}&response_type=code&scope=openid&sessionToken=${sessionToken}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&state=state-296bc9a0-a2a2-4a57-be1a-d0e2fd9bb601&nonce=g5ly497e8ps&response_mode=fragment`;
};
const generateLogoutURL = (idToken: string) => {
  return `${OktaIssuer}/oauth2/default/v1/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${process.env.REACT_APP_LOGOUT_URI}`;
};

const authorizeUser = async (code: string, reCaptchaToken: any) => {
  const credential = {
    code,
  };
  const config = {
    headers: { "g-recaptcha-response": reCaptchaToken },
  };
  const response = await axios.post(baseUrl + getTokensURL, credential, config);
  return response.data;
};

// delete the current session
const deleteSessionToken = async () => {
  const response = await axios.delete(sessionURL, {
    withCredentials: true,
  });
  return response.data;
};

// get the current session
const getCurrentSession = async () => {
  const response = await axios.get(sessionURL, { withCredentials: true });
  return response.data;
};

// revoke access token
const revokeAccessToken = async (accessToken: string) => {
  const config = {
    headers: { "takeda-access-token": accessToken },
  };
  const tokenDetails = {
    token: accessToken,
    token_type_hint: "access_token",
  };
  const response = await axios.post(
    baseUrl + revokeTokenURL,
    tokenDetails,
    config
  );
  return response.data;
};

const loginService = {
  login,
  generateAuthorizeURL,
  authorizeUser,
  deleteSessionToken,
  getCurrentSession,
  revokeAccessToken,
  generateLogoutURL,
};

export default loginService;
