export const prepareConversations = (
  conversationInfo: any,
  userDetails: any
) => {
  const { conversation, recipient } = conversationInfo;
  return conversation
    .reverse()
    .map((conv: any) =>
      convertConversationToMessage(conv, recipient, userDetails)
    );
};

const convertConversationToMessage = (
  conversation: any,
  recipient: any,
  userDetails: any
) => {
  // check whether the message belongs to logged in user or not
  if (conversation.senderId === recipient.id) {
    return { ...conversation, ...recipient };
  } else {
    return {
      ...conversation,
      ...userDetails,
    };
  }
};
