export const getVideoURL = (fullVideoURL: string): string => {
  return fullVideoURL.split("=")[1];
};

export const prepareVideoURL = (
  videoURL: string,
  digitalId: string,
  customerID: string,
  crmCountry: string
): string => {
  return `${videoURL}&autoplay=false&bccustomguid=${customerID}&bcknownuser=1&bctrackingid=${crmCountry}:${digitalId}`;
};

export const prepareVideoLinks = (
  videoLinks: Array<string>,
  digitalId: string,
  customerID: string,
  crmCountry: string
): Array<string> => {
  return videoLinks.map((videoLink) =>
    prepareVideoURL(videoLink, digitalId, customerID, crmCountry)
  );
};
