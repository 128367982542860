import React, { FunctionComponent } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import MyMessageClickThroughContent from "./MyMessageClickThroughContent";

const MyMessageClickThrough: FunctionComponent = () => {
  return (
    <>
      <Header />
      <MyMessageClickThroughContent />
      <Footer />
    </>
  );
};

export default MyMessageClickThrough;
