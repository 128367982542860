import React, { FunctionComponent } from "react";
import Button from "../../../../components/Button";

const FilterModulesHeader: FunctionComponent = () => {
  return (
    <section className="filter-mod-page-header">
      <div className="header-content">
        <h1>Modules</h1>
      </div>
    </section>
  );
};

export default FilterModulesHeader;
