import React, { FunctionComponent } from "react";
const CookiePolicyHeader = () => {
  return (
    <section className="cookie-pol-header">
      <h1>Site Cookie Policy</h1>
    </section>
  );
};

export default CookiePolicyHeader;
