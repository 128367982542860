import React from "react";
import "./ResetPwdHeader.scss";
const ResetPwdHeader = () => {
  return (
    <section className="reset-pwd-header">
      <h3>Reset Password</h3>
      <p>
        We have successfully identified your account, please enter a new
        password.
      </p>
    </section>
  );
};

export default ResetPwdHeader;
