import React, { useEffect, useState } from "react";
import Pagination from "../../../components/Pagination";
import { css } from "@emotion/react";
import { useAuth } from "../../../navigation/Auth/ProvideAuth";
import { sortByValues } from "../../../types";
import "./TherapyAreaMenu.scss";
import SyncLoaderSpinner from "../../../components/SyncLoader";
import TherapyHeader from "../../../components/TherapyHeader";
import TherapyBoard from "../../../components/TherapyBoard";
import { slugMapper } from "./slugMapper";
import therapyService from "../../../services/therapyarea";
import { useProvideAuth } from "../../../navigation/Auth/useProvideAuth";

const TherapyAreaMenu = ({ slug }: { slug: string }) => {
  const { auth } = useAuth();
  const { signout } = useProvideAuth();
  const [therapies, setTherapies] = useState({
    data: [],
    meta: {
      pagination: {
        limit: 3,
        next: null,
        page: 0,
        pages: 0,
        prev: null,
        total: 0,
      },
    },
  });
  const [confFilter, setConfFilter] = useState<sortByValues>("added");
  const [activePage, setActivePage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const loaderStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  `;

  const setPageNumber = (pageNumber: number) => {
    setActivePage(pageNumber);
  };

  useEffect(() => {
    const getAllConferences = async () => {
      setLoading(true);
      try {
        const therapiesResp = await therapyService.getAllTherapyModules(
          slug,
          activePage,
          auth?.access_token,
          confFilter
        );
        setTherapies({ ...therapies, ...therapiesResp });
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        if (error?.response?.status === 401) {
          await signout();
        }
      }
    };
    getAllConferences();
  }, [confFilter, activePage]);

  return (
    <div className="conf-menu">
      <TherapyHeader
        slug={slug}
        title={slugMapper[slug]}
        filter={confFilter}
        changeFilter={(value: sortByValues) => setConfFilter(value)}
      />
      {loading ? (
        <SyncLoaderSpinner isLoading={loading} override={loaderStyle} />
      ) : (
        <>
          <TherapyBoard therapyposts={therapies.data} slug={slug} />
          <Pagination
            paginationDetails={therapies?.meta?.pagination}
            setPageNumber={setPageNumber}
          />
        </>
      )}
    </div>
  );
};

export default TherapyAreaMenu;
