import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TherapyHeader from "../../../components/TherapyHeader";
import { useConferenceContext } from "../../../context/Conferences";
import { useAuth } from "../../../navigation/Auth/ProvideAuth";
import { css } from "@emotion/react";
import { sortByValues } from "../../../types";
import SyncLoaderSpinner from "../../../components/SyncLoader";
import { slugMapper } from "../../TherapyArea/TherapyAreaMenu/slugMapper";
import TherapyBoard from "../../../components/TherapyBoard";
import therapyService from "../../../services/therapyarea";
import { useProvideAuth } from "../../../navigation/Auth/useProvideAuth";

const EdTherapyArea = ({ slug }: { slug: string }) => {
  const { auth } = useAuth();
  const { signout } = useProvideAuth();
  const [confFilter, setConfFilter] = useState<sortByValues>("added");
  const [therapies, setTherapies] = useState({ data: [] });
  const [loading, setLoading] = useState<boolean>(false);
  const loaderStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;
  `;
  const viewMoreLink = `/community/education/${slug}`;
  useEffect(() => {
    const getAllConferences = async () => {
      setLoading(true);
      try {
        const therapiesResp = await therapyService.getAllTherapyModules(
          slug,
          1,
          auth?.access_token,
          confFilter
        );
        setTherapies({ ...therapies, ...therapiesResp });
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        if (error?.response?.status === 401) {
          await signout();
        }
      }
    };

    getAllConferences();
  }, [confFilter]);

  return (
    <div className="item-2">
      <TherapyHeader
        filter={confFilter}
        changeFilter={(value: sortByValues) => setConfFilter(value)}
        title={slugMapper[slug]}
        slug={slug}
      />
      {loading ? (
        <SyncLoaderSpinner isLoading={loading} override={loaderStyle} />
      ) : (
        <>
          <TherapyBoard
            therapyposts={therapies?.data?.slice(0, 3)}
            slug={slug}
          />
          <div className="view-all-conf">
            <Link to={viewMoreLink}>View More {slugMapper[slug]}</Link>
          </div>
        </>
      )}
    </div>
  );
};

export default EdTherapyArea;
