import { ErrorMessage, Field, FieldProps } from "formik";
import React, { Component } from "react";
import AsyncSelect from "react-select/async";

import TextError from "./TextError";

export const AsyncSelectField = ({
  loadOptions,
  field,
  label,
  placeholder,
  form: { setFieldValue },
}: FieldProps & {
  label: string;
  placeholder: string;
  loadOptions: any;
}) => (
  <div className="form-control">
    <label htmlFor={field.name}>{label}</label>
    <AsyncSelect
      name={field.name}
      className={"react-select-container"}
      classNamePrefix={"react-select"}
      placeholder={placeholder}
      loadOptions={loadOptions}
      onChange={(option) => setFieldValue(field.name, (option as any).value)}
    />
    <ErrorMessage component={TextError} name={field.name} />
  </div>
);

export default AsyncSelectField;
