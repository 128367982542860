import React from "react";
import { Switch, Route, Redirect } from "react-router";
import Community from "../pages/Community";
import CommunityHeading from "../pages/CommunityHome/CommunityHeading";
import CommunityMenu from "../pages/CommunityHome/CommunityMenu";
import InformationMenu from "../pages/Information/InformationMenu";
import DashboardHeading from "../pages/Information/InformationHeading";
import Landing from "../pages/Landing";
import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import Registration from "../pages/Registration";
import RegistrationSuccess from "../pages/RegistrationSuccess";
import JournalWatch from "../pages/JournalWatch";
import OtherResources from "../pages/OtherResources";
import ClincicalNews from "../pages/ClinicalNews";
import ArticleHeading from "../pages/ArticlePage/ArticleHeading";
import Article from "../pages/ArticlePage/Article";
import LoginCallback from "../pages/Callback";
import PrivateRoute from "./Auth/PrivateRoute";
import EducationHeading from "../pages/Education/EducationHeading";
import EducationMenu from "../pages/Education/EducationMenu";
import ConfHighlightHeading from "../pages/ConfHighlights/ConfHighlightHeading";
import ConfMenu from "../pages/ConfHighlights/ConfHighlightMenu";
import Conference from "../pages/ConfPage/Conference";
import ConfHeading from "../pages/ConfPage/ConfHeading";
import CMEModulesHeading from "../pages/CMEModules/CMEModulesHeading";
import CMEModulesMenu from "../pages/CMEModules/CMEModulesMenu";
import AccountsHeading from "../pages/Accounts/AccountsHeading";
import AccountsBody from "../pages/Accounts/AccountsBody";
import SearchArticle from "../pages/SearchArticle";
import ResetPasswordSuccess from "../pages/ResetPasswordSuccess";
import ResetPassword from "../pages/ResetPassword";
import ResetPasswordRequest from "../pages/ResetPasswordRequest";
import ChangePassword from "../pages/ChangePassword";
import ResetPasswordReqSuccess from "../pages/ResetPasswordReqSuccess";
import ActPreferenceHeading from "../pages/ActPreference/ActPreferenceHeading";
import ActPreferenceMenu from "../pages/ActPreference/ActPreferenceMenu";
import InviteFriendSuccessHeading from "../pages/InviteFriendSuccess/InviteFriendSuccessHeading";
import InviteFriendSuccessBody from "../pages/InviteFriendSuccess/InviteFriendSuccessBody";
import InviteFriendHeading from "../pages/InviteFriend/InviteFriendHeading";
import InviteFriendBody from "../pages/InviteFriend/InviteFriendBody";
import UpdateProfile from "../pages/UpdateProfile";
import VerifyEmail from "../pages/VerifyEmail";
import VerifyEmailReq from "../pages/VerifyEmailRequest";
import ChangePasswordSuccess from "../pages/ChangePasswordSuccess";
import Message from "../pages/Message";
import MessageSuccess from "../pages/MessageSuccess";
import MyMessage from "../pages/MyMessage";
import MyMessageClickThrough from "../pages/MyMessageClickThrough";
import CommunityMembers from "../pages/CommunityMembers";
import ViewProfile from "../pages/ViewProfile";
import Search from "../pages/Search";
import {
  ROOT,
  REGISTER,
  LOGIN,
  REGISTERSUCCESS,
  COMMUNITY,
  ARTICLE,
  NEWS,
  CLINICALNEWS,
  JOURNALWATCH,
  OTHERRESOURCES,
  LOGINCALLBACK,
  EDUCATION,
  CONFERENCEHIGHLIGHT,
  CONFERENCE,
  CMEMODULES,
  ACCOUNTS,
  SEARCHARTICLE,
  RESETPASSWORDSUCCESS,
  RESETPASSWORDREQUEST,
  RESETPASSWORD,
  CHANGEPASSWORD,
  RESETPASSWORDREQUESTSUCCESS,
  ACTIVITYPREFERENCE,
  INVITEFRIEND,
  INVITEFRIENDSUCCESS,
  UPDATEPROFILE,
  VERIFYEMAIL,
  VERIFYEMAILREQUEST,
  CHANGEPASSWORDSUCCESS,
  MESSAGE,
  MESSAGESUCCESS,
  MYMESSAGE,
  MYMESSAGECLICKTHROUGH,
  COMMUNITYMEMBERS,
  VIEWPROFILE,
  SEARCH,
  FEEDBACK,
  CMEMODULE,
  FILTERMODULES,
  CONTACTUS,
  ABOUTUS,
  TERMSANDCONDITION,
  PRIVACYPOLICY,
  COOKIESPOLICY,
  SEARCHCONFERENCE,
  ANALYTICSDASH,
  RAREHAEMATOLOGY,
  RAREGENETICDISEASE,
  ONCOLOGY,
  IMMUNOLOGY,
  GASTROENTEROLOGY,
  POSTRAREHAEMATOLOGY,
  POSTIMMUNOLOGY,
  POSTRAREGENETICDISEASE,
  POSTGASTROENTEROLOGY,
  POSTONCOLOGY,
} from "./CONSTANT";
import Feedback from "../pages/Feedback";
import ModuleHeading from "../pages/ModulePage/ModuleHeading";
import Module from "../pages/ModulePage/Module";
import FilterModules from "../pages/FilterModules";
import ContactUs from "../pages/ContactUs";
import StaticPage from "../pages/StaticPage";
import SearchConf from "../pages/SearchConf";
import { useAuth } from "./Auth/ProvideAuth";
import AboutUs from "../pages/AboutUs";
import ReportingHeading from "../pages/Reporting/ReportingHeading";
import ReportingMenu from "../pages/Reporting/ReportingMenu";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TherapyAreaHeading from "../pages/TherapyArea/TherapyAreaHeading";
import TherapyAreaMenu from "../pages/TherapyArea/TherapyAreaMenu";
import TherapyHeading from "../pages/TherapyPage/TherapyHeading";
import Therapy from "../pages/TherapyPage/Therapy";
import CookiePolicy from "../pages/CookiePolicy";
import TermsAndCond from "../pages/TermsAndCondition";

const RouterConfig = () => {
  const { auth } = useAuth();
  const isLoggedIn = auth && auth.id_token;

  return (
    <Switch>
      {/* Public Routes */}
      {/* <Route exact path={ROOT} component={Landing} /> */}
      <Route exact path={REGISTER}>
        {isLoggedIn ? <Redirect to={ROOT} /> : <Registration />}
      </Route>
      <Route exact path={LOGIN}>
        {isLoggedIn ? <Redirect to={ROOT} /> : <Login />}
      </Route>
      <Route path={LOGINCALLBACK} component={LoginCallback} />

      {/* Static Pages */}
      <Route exact path={ABOUTUS}>
        <AboutUs />
      </Route>
      <Route exact path={PRIVACYPOLICY}>
        <PrivacyPolicy />
      </Route>
      <Route exact path={TERMSANDCONDITION}>
        <TermsAndCond />
      </Route>
      <Route exact path={COOKIESPOLICY}>
        <CookiePolicy />
      </Route>
      <PrivateRoute exact path={`${COMMUNITY}`}>
        <Community
          HeaderComponent={CommunityHeading}
          BodyComponent={CommunityMenu}
        />
      </PrivateRoute>
      <PrivateRoute exact path={`${NEWS}`}>
        <Community
          HeaderComponent={DashboardHeading}
          BodyComponent={InformationMenu}
        />
      </PrivateRoute>
      <PrivateRoute exact path={`${CLINICALNEWS}`}>
        <Community
          HeaderComponent={DashboardHeading}
          BodyComponent={ClincicalNews}
        />
      </PrivateRoute>
      <PrivateRoute exact path={`${JOURNALWATCH}`}>
        <Community
          HeaderComponent={DashboardHeading}
          BodyComponent={JournalWatch}
        />
      </PrivateRoute>
      <PrivateRoute exact path={`${OTHERRESOURCES}`}>
        <Community
          HeaderComponent={DashboardHeading}
          BodyComponent={OtherResources}
        />
      </PrivateRoute>
      <PrivateRoute exact path={`${SEARCHARTICLE}`}>
        <Community
          HeaderComponent={DashboardHeading}
          BodyComponent={SearchArticle}
        />
      </PrivateRoute>

      <PrivateRoute path={ARTICLE}>
        <Community HeaderComponent={ArticleHeading} BodyComponent={Article} />
      </PrivateRoute>
      <PrivateRoute exact path={EDUCATION}>
        <Community
          HeaderComponent={EducationHeading}
          BodyComponent={EducationMenu}
        />
      </PrivateRoute>
      <PrivateRoute exact path={CONFERENCEHIGHLIGHT}>
        <Community
          HeaderComponent={ConfHighlightHeading}
          BodyComponent={ConfMenu}
        />
      </PrivateRoute>
      <PrivateRoute exact path={RAREHAEMATOLOGY}>
        <Community
          HeaderComponent={TherapyAreaHeading}
          BodyComponent={() => <TherapyAreaMenu slug="rarehaematology" />}
        />
      </PrivateRoute>
      <PrivateRoute exact path={RAREGENETICDISEASE}>
        <Community
          HeaderComponent={TherapyAreaHeading}
          BodyComponent={() => <TherapyAreaMenu slug="raregeneticdisease" />}
        />
      </PrivateRoute>
      <PrivateRoute exact path={ONCOLOGY}>
        <Community
          HeaderComponent={TherapyAreaHeading}
          BodyComponent={() => <TherapyAreaMenu slug="oncology" />}
        />
      </PrivateRoute>
      <PrivateRoute exact path={IMMUNOLOGY}>
        <Community
          HeaderComponent={TherapyAreaHeading}
          BodyComponent={() => <TherapyAreaMenu slug="immunology" />}
        />
      </PrivateRoute>
      <PrivateRoute exact path={GASTROENTEROLOGY}>
        <Community
          HeaderComponent={TherapyAreaHeading}
          BodyComponent={() => <TherapyAreaMenu slug="gastroenterology" />}
        />
      </PrivateRoute>

      <PrivateRoute path={CONFERENCE}>
        <Community HeaderComponent={ConfHeading} BodyComponent={Conference} />
      </PrivateRoute>
      <PrivateRoute path={CMEMODULE}>
        <Community HeaderComponent={ModuleHeading} BodyComponent={Module} />
      </PrivateRoute>
      <PrivateRoute path={POSTRAREHAEMATOLOGY}>
        <Community
          HeaderComponent={() => <TherapyHeading slug="rarehaematology" />}
          BodyComponent={() => <Therapy slug="rarehaematology" />}
        />
      </PrivateRoute>
      <PrivateRoute path={POSTIMMUNOLOGY}>
        <Community
          HeaderComponent={() => <TherapyHeading slug="immunology" />}
          BodyComponent={() => <Therapy slug="immunology" />}
        />
      </PrivateRoute>
      <PrivateRoute path={POSTRAREGENETICDISEASE}>
        <Community
          HeaderComponent={() => <TherapyHeading slug="raregeneticdisease" />}
          BodyComponent={() => <Therapy slug="raregeneticdisease" />}
        />
      </PrivateRoute>
      <PrivateRoute path={POSTGASTROENTEROLOGY}>
        <Community
          HeaderComponent={() => <TherapyHeading slug="gastroenterology" />}
          BodyComponent={() => <Therapy slug="gastroenterology" />}
        />
      </PrivateRoute>
      <PrivateRoute path={POSTONCOLOGY}>
        <Community
          HeaderComponent={() => <TherapyHeading slug="oncology" />}
          BodyComponent={() => <Therapy slug="oncology" />}
        />
      </PrivateRoute>

      {/* CME Modules Page */}
      <PrivateRoute exact path={CMEMODULES}>
        <Community
          HeaderComponent={CMEModulesHeading}
          BodyComponent={CMEModulesMenu}
        />
      </PrivateRoute>
      {/* Accounts Page */}
      <PrivateRoute exact path={ACCOUNTS}>
        <Community
          HeaderComponent={AccountsHeading}
          BodyComponent={AccountsBody}
        />
      </PrivateRoute>
      {/*  Activity Preference Page*/}
      <PrivateRoute exact path={ACTIVITYPREFERENCE}>
        <Community
          HeaderComponent={ActPreferenceHeading}
          BodyComponent={ActPreferenceMenu}
        />
      </PrivateRoute>
      <PrivateRoute exact path={INVITEFRIEND}>
        <Community
          HeaderComponent={InviteFriendHeading}
          BodyComponent={InviteFriendBody}
        />
      </PrivateRoute>
      <PrivateRoute exact path={INVITEFRIENDSUCCESS}>
        <Community
          HeaderComponent={InviteFriendSuccessHeading}
          BodyComponent={InviteFriendSuccessBody}
        />
      </PrivateRoute>
      {/* Reporting Page */}

      <PrivateRoute exact path={ANALYTICSDASH}>
        <Community
          HeaderComponent={ReportingHeading}
          BodyComponent={ReportingMenu}
        />
      </PrivateRoute>
      <PrivateRoute exact path={UPDATEPROFILE}>
        <UpdateProfile />
      </PrivateRoute>
      <PrivateRoute exact path={MESSAGE}>
        <Message />
      </PrivateRoute>
      <PrivateRoute exact path={MYMESSAGE}>
        <MyMessage />
      </PrivateRoute>
      <PrivateRoute exact path={MYMESSAGECLICKTHROUGH}>
        <MyMessageClickThrough />
      </PrivateRoute>
      <PrivateRoute exact path={MESSAGESUCCESS}>
        <MessageSuccess />
      </PrivateRoute>
      <PrivateRoute exact path={COMMUNITYMEMBERS}>
        <CommunityMembers />
      </PrivateRoute>
      <PrivateRoute exact path={FILTERMODULES}>
        <FilterModules />
      </PrivateRoute>
      <PrivateRoute exact path={SEARCHCONFERENCE}>
        <SearchConf />
      </PrivateRoute>
      <PrivateRoute exact path={VIEWPROFILE}>
        <ViewProfile />
      </PrivateRoute>
      <PrivateRoute exact path={SEARCH}>
        <Search />
      </PrivateRoute>
      <PrivateRoute exact path={FEEDBACK}>
        <Feedback />
      </PrivateRoute>
      <PrivateRoute exact path={CONTACTUS}>
        <ContactUs />
      </PrivateRoute>
      <PrivateRoute exact path={CHANGEPASSWORD}>
        <ChangePassword />
      </PrivateRoute>
      <PrivateRoute exact path={CHANGEPASSWORDSUCCESS}>
        <ChangePasswordSuccess />
      </PrivateRoute>

      <Route exact path={REGISTERSUCCESS}>
        <RegistrationSuccess />
      </Route>
      <Route exact path={RESETPASSWORDSUCCESS}>
        <ResetPasswordSuccess />
      </Route>
      <Route exact path={RESETPASSWORDREQUEST}>
        <ResetPasswordRequest />
      </Route>
      <Route exact path={RESETPASSWORDREQUESTSUCCESS}>
        <ResetPasswordReqSuccess />
      </Route>
      <Route exact path={VERIFYEMAIL}>
        <VerifyEmail />
      </Route>
      <Route exact path={VERIFYEMAILREQUEST}>
        <VerifyEmailReq />
      </Route>
      <Route exact path={RESETPASSWORD}>
        <ResetPassword />
      </Route>
      <Route exact path={ROOT}>
        <Landing />
      </Route>

      {/* No match found */}
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default RouterConfig;
