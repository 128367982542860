import React from "react";
import "./ChangePasswordContent.scss";
import ChangePwdForm from "./ChangePwdForm";
import ChangePwdHeader from "./ChangePwdHeader";

const ChangePasswordContent = () => {
  return (
    <main className="change-pwd-page">
      <ChangePwdHeader />
      <ChangePwdForm />
    </main>
  );
};

export default ChangePasswordContent;
