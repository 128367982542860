import React from "react";
import { Link } from "react-router-dom";

import "./Card.scss";

interface ActionProps {
  [key: string]: string;
}

interface CardProps {
  title: { navigateTo: string; name: string };
  actions: Array<ActionProps>;
}

const Card = ({ title, actions }: CardProps) => {
  return (
    <div className="card">
      <Link to={title.navigateTo}>
        <h3>{title.name}</h3>
      </Link>
      {actions.map((action, index) => (
        <Link key={`act ${index}`} to={action.navigateTo}>
          <p>{action.title}</p>
        </Link>
      ))}
    </div>
  );
};

export default Card;
