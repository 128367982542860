import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const getConferenceURL = "conference-highlights";
const baseConferenceUrl =
  process.env.REACT_APP_BASE_URL + "/information/conference";
type sortByValues = "added" | "views" | "rating";
type orderByValues = "asc" | "desc";

const getAllConferences = async (
  page: number,
  accessToken: string,
  sortBy: sortByValues = "added",
  orderBy: orderByValues = "desc"
) => {
  const response = await axios.get(baseUrl + "/" + getConferenceURL, {
    params: {
      limit: 3,
      page,
      sortBy,
      orderBy,
    },
    headers: { "takeda-access-token": accessToken },
  });
  return response.data;
};

const getConference = async (
  conferenceId: string | undefined,
  accessToken: string
) => {
  const response = await axios.get(baseConferenceUrl + "/" + conferenceId, {
    headers: { "takeda-access-token": accessToken },
  });
  return response.data;
};

const conferenceService = {
  getAllConferences,
  getConference,
};

export default conferenceService;
