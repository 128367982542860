import * as Yup from "yup";
export const resetPwdValidationScheme = Yup.object({
  newpwd: Yup.string()
    .trim()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])((?=.*?[0-9])|(?=.*?[#?!@$%^&*-])).{9,}$/,
      "Password criteria:\n 1. Have at least 9 characters\n 2. Contains mixed case letters\n 3. Contain at least 1 number OR a special character.\n 4. Allowed characters are: a-z, A-Z, 0-9 and !@#$*^().,{}[]~-"
    )
    .required("New Password is required"),
  cofirmpwd: Yup.string()
    .oneOf([Yup.ref("newpwd"), ""], "Passwords must match")
    .required("Confirm Password is required"),
});
