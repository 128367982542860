import React, { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReplyBoard from "../../../../components/ReplyBoard";
import { useLoggedUserContext } from "../../../../context/LoggedUser";
import { useAuth } from "../../../../navigation/Auth/ProvideAuth";
import messageService from "../../../../services/messages";
import registerService from "../../../../services/register";
import "./MyMessageClickThroughBody.scss";
import { prepareConversations } from "./prepareConversation";

const MyMessageClickThroughBody: FunctionComponent = () => {
  const { auth } = useAuth();
  const { loggedUserInfo, setLoggedUserInfo } = useLoggedUserContext();
  const [conversation, setConversation] = useState<{
    data: [];
    totalCount: number;
    receiverBlock: boolean;
    senderBlock: boolean;
  }>({ data: [], totalCount: 0, receiverBlock: false, senderBlock: false });
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const { recipientId } = {
    recipientId: null,
    ...useParams(),
  };
  const handlePageChange = (change: number) => {
    setPageNumber(pageNumber + change);
  };

  useEffect(() => {
    const getUserMessages = async () => {
      setLoading(true);
      const userDetails = await registerService.getUser(auth?.access_token);

      const response = await messageService.getConversation(
        auth?.access_token,
        pageNumber,
        recipientId
      );
      const unseenMsgCountResp = await messageService.getUnseenMsgCount(
        auth?.access_token
      );
      setLoading(false);
      setConversation({
        data: prepareConversations(response.data, userDetails),
        totalCount: response.totalCount,
        receiverBlock: response.receiverBlock,
        senderBlock: response.senderBlock,
      });
      setLoggedUserInfo({
        type: "UPDATE_MESSAGE_COUNT",
        payload: {
          unseenMsgCount: unseenMsgCountResp?.count,
        },
      });
    };
    try {
      getUserMessages();
    } catch (error) {
      setLoading(false);
    }
  }, [pageNumber]);
  return (
    <section className="my-message-clth-page-body">
      <ReplyBoard
        messages={conversation?.data}
        handlePageChange={handlePageChange}
        currentPage={pageNumber}
        totalCount={conversation?.totalCount}
        isLoading={loading}
        senderBlock={conversation?.senderBlock}
        receiverBlock={conversation?.receiverBlock}
      />
    </section>
  );
};

export default MyMessageClickThroughBody;
