import * as React from "react";
import { Link } from "react-router-dom";
import MessageCounter from "../MessageCounter";
import {
  ACCOUNTS,
  CHANGEPASSWORD,
  HOME,
  LOGOUT,
  MYMESSAGE,
  UPDATEPROFILE,
} from "../../navigation/CONSTANT";
import { ReactComponent as DropdownSVG } from "../../assets/images/dropdown.svg";
import defaultAvatar from "../../assets/images/default-avatar.png";
interface DropDownItemProps {
  handleLogout: () => void;
  loggedUserDetails: any;
}

const subMenuItems = [
  { itemId: 1, type: "normal", navigateTo: ACCOUNTS, label: "My Account" },
  { itemId: 2, type: "message", navigateTo: MYMESSAGE, label: "Messages" },
  {
    itemId: 3,
    type: "normal",
    navigateTo: UPDATEPROFILE,
    label: "Edit Profile",
  },
  {
    itemId: 4,
    type: "normal",
    navigateTo: CHANGEPASSWORD,
    label: "Change Password",
  },
  { itemId: 5, type: "action", navigateTo: LOGOUT, label: "Logout" },
];
const DropDownItem = ({
  handleLogout,
  loggedUserDetails,
}: DropDownItemProps) => {
  const { title, firstName, lastName, image, unseenMsgCount } =
    loggedUserDetails;
  const fullName = [title, firstName, lastName].join(" ");
  return (
    <div className="dropdown-link">
      <li className="dropdown-item">
        <div className="profile-info">
          <MessageCounter msgCount={unseenMsgCount} />
          <div className="img-container">
            <img
              src={typeof image === "string" ? image : defaultAvatar}
              alt={fullName}
            />
          </div>
          <p>{fullName}</p>
          <DropdownSVG />
        </div>
        <ul className="sub-menu">
          {subMenuItems.map((item) => {
            if (item.type === "action") {
              return (
                <Link
                  key={item.itemId}
                  to={item.navigateTo}
                  onClick={handleLogout}
                >
                  <li className="sub-menu-item">{item.label}</li>
                </Link>
              );
            }
            return (
              <Link key={item.itemId} to={item.navigateTo}>
                <li className="sub-menu-item">{item.label}</li>
              </Link>
            );
          })}
        </ul>
      </li>
    </div>
  );
};

export default DropDownItem;
