import React, { FunctionComponent } from "react";
import Button from "../Button";

import "./ThankYouPopUp.scss";
interface ThankYouProps {
  msg: string;
  submitHandler: () => void;
}

const ThankYou: FunctionComponent<ThankYouProps> = ({
  msg,
  submitHandler,
}: ThankYouProps) => {
  return (
    <div className="thankyoupopup">
      <h1>Thank you</h1>
      <h3>{msg}</h3>
      <Button
        type="button"
        size="size-lg"
        colorTheme="has-primary-theme"
        onClick={submitHandler}
      >
        Close
      </Button>
    </div>
  );
};

export default ThankYou;
