import React, { FunctionComponent } from "react";
import ActionCircle from "./ActionCircle";
import { ReactComponent as Information } from "../../../assets/images/information.svg";
import { ReactComponent as Education } from "../../../assets/images/education.svg";
import { ReactComponent as Collaboration } from "../../../assets/images/collaboration.svg";
import { ReactComponent as Opportunities } from "../../../assets/images/opportunities.svg";

import "./LandingContent.scss";
const LandingContent: FunctionComponent = () => {
  return (
    <main className="landing-content">
      <section className="hero">
        <div className="hero-content">
          <h1>A COMMUNITY OF HEALTH CARE PROFESSIONALS</h1>
          <h2>
            <span>RARE HAEMATOLOGY</span>-<span>IMMUNOLOGY</span>-
            <span>HEREDITARY ANGIOEDEMA</span>-
            <span>RARE GENETIC DISEASES</span>-<span>ONCOLOGY</span>-
            <span>GASTROENTEROLOGY</span>
          </h2>
        </div>
      </section>
      <section className="info">
        <p>
          This Medical Community is an invitation only community, supported by
          Takeda.
        </p>
      </section>
      <section className="action">
        <div className="action-content">
          <ActionCircle bgType="dark" title="Information">
            <Information />
          </ActionCircle>
          <ActionCircle bgType="dark" title="Education">
            <Education />
          </ActionCircle>
          <ActionCircle bgType="red" title="">
            <h3>Join</h3>
          </ActionCircle>
          <ActionCircle bgType="dark" title="Collaboration">
            <Collaboration />
          </ActionCircle>
          <ActionCircle bgType="dark" title="Opportunities">
            <Opportunities />
          </ActionCircle>
        </div>
      </section>
    </main>
  );
};

export default LandingContent;
