import React, { FunctionComponent } from "react";
import "./PaginationByCount.scss";
interface PaginationByCountProps {
  totalCount: number;
  currentPage: number;
  limit: number;
  changePage: (changeVal: number) => void;
}

const PaginationByCount: FunctionComponent<PaginationByCountProps> = ({
  totalCount,
  currentPage,
  limit,
  changePage,
}: PaginationByCountProps) => {
  const prevPresent = currentPage > 1;
  const nextPresent = currentPage * limit < totalCount;
  return (
    <div className="pagination-count">
      {prevPresent && (
        <div className="pagination-link" onClick={() => changePage(-1)}>
          Prev
        </div>
      )}
      {nextPresent && (
        <div className="pagination-link" onClick={() => changePage(1)}>
          Next
        </div>
      )}
    </div>
  );
};

export default PaginationByCount;
