export const initialConferences = { data: [], meta: {} };

export const conferencesReducer = (state: any, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_CONFERENCES":
      return { ...payload };
    case "SET_CONFERENCE":
      return {
        data: state.data.length > 0 ? state.data : [payload.data],
        meta: state.meta,
      };
    case "UPDATE_CONFERENCE_RATING":
      return {
        data: state.data.map((conference: any) => {
          if (conference.id !== payload.conferenceId) return conference;

          return {
            ...conference,
            rating: Math.floor(
              (conference.rating * conference.ratingsCount + payload.rating) /
                (conference.ratingsCount + 1)
            ),
            ratingsCount: conference.ratingsCount + 1,
          };
        }),
        meta: state.meta,
      };
    case "GET_CONF_COMMENTS":
      return {
        data: state.data.map((conference: any) => {
          if (conference.id !== payload.conferenceId) return conference;

          return {
            ...conference,
            comments: payload.commentInfo.comments,
            commentCount: payload.commentInfo.totalCommentsCount,
          };
        }),
        meta: state.meta,
      };
    case "ADD_CONF_COMMENT":
      return {
        data: state.data.map((conference: any) => {
          if (conference.id !== payload.conferenceId) return conference;

          return {
            ...conference,
            comments: [...conference.comments, payload.comment],
            commentCount: conference.commentCount + 1,
          };
        }),
        meta: state.meta,
      };
    case "ADD_CONF_REPLY":
      return {
        data: state.data.map((conference: any) => {
          if (conference.id !== payload.conferenceId) return conference;

          return {
            ...conference,
            comments: conference.comments.map((comment: any) => {
              if (comment.id !== payload.commentId) return comment;

              return {
                ...comment,
                replies: comment.replies
                  ? [...comment.replies, payload.comment]
                  : [payload.comment],
              };
            }),
          };
        }),
        meta: state.meta,
      };
    case "DELETE_CONF_COMMENT":
      return {
        data: state.data.map((conference: any) => {
          if (conference.id !== payload.conferenceId) return conference;

          return {
            ...conference,
            comments: conference.comments.filter(
              (conference: any) => conference.id !== payload.commentId
            ),
            commentCount: conference.commentCount - 1,
          };
        }),
        meta: state.meta,
      };
    case "DELETE_CONF_REPLY":
      return {
        data: state.data.map((conference: any) => {
          if (conference.id !== payload.conferenceId) return conference;

          return {
            ...conference,
            comments: conference.comments.map((comment: any) => {
              if (comment.id !== payload.commentId) return comment;

              return {
                ...comment,
                replies: comment.replies.filter(
                  (reply: any) => reply.id !== payload.replyId
                ),
              };
            }),
            commentCount: conference.commentCount,
          };
        }),
        meta: state.meta,
      };
    default:
      return state;
  }
};
