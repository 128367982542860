import React, { FunctionComponent } from "react";
const FeedbackHeader: FunctionComponent = () => {
  return (
    <section className="feedback-page-header">
      <h1>Provide Feedback</h1>
    </section>
  );
};

export default FeedbackHeader;
