import React, { useEffect, useState } from "react";
import "./InformationMenu.scss";
import Board from "../../../components/Board";
import { css } from "@emotion/react";
import newsService from "../../../services/news";
import { CLINICALNEWS } from "../../../navigation/CONSTANT";
import { useAuth } from "../../../navigation/Auth/ProvideAuth";
import SyncLoaderSpinner from "../../../components/SyncLoader";
import { useProvideAuth } from "../../../navigation/Auth/useProvideAuth";

const InformationMenu = () => {
  const [news, setNews] = useState<any>({
    data: [],
    meta: {
      pagination: {
        limit: 3,
        next: null,
        page: 0,
        pages: 0,
        prev: null,
        total: 0,
      },
    },
  });
  const { auth } = useAuth();
  const { signout } = useProvideAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const loaderStyle = css`
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
  `;
  useEffect(() => {
    const getAllNewsInformation = async () => {
      setLoading(true);
      try {
        const clinicalNewsResp = await newsService.getNewsType(
          "clinical-news",
          auth?.access_token
        );
        setLoading(false);
        setNews({ ...news, ...clinicalNewsResp });
      } catch (error: any) {
        setLoading(false);
        if (error?.response?.status === 401) {
          await signout();
        }
      }
    };

    getAllNewsInformation();
  }, []);

  {
    return loading ? (
      <SyncLoaderSpinner isLoading={loading} override={loaderStyle} />
    ) : (
      <div className="information-menu">
        <Board
          articles={news?.data?.slice(0, 2)}
          title="Clinical News"
          navigateTo={CLINICALNEWS}
          viewAll={true}
        />
      </div>
    );
  }
};

export default InformationMenu;
