import React from "react";
import CookiePolicyBody from "./CookiePolicyBody";
import CookiePolicyHeader from "./CookiePolicyHeader";
import "./CookiePolicyContent.scss";

const CookiePolicyContent = () => {
  return (
    <main className="cookie-pol-page">
      <CookiePolicyHeader />
      <CookiePolicyBody />
    </main>
  );
};

export default CookiePolicyContent;
