import React from "react";
import "./SavedArticleInfoCard.scss";
import { ReactComponent as CommentIcon } from "../../assets/images/comment.svg";
import { useHistory } from "react-router";
import { SavedArticleInfoCardProps } from "../../types";
import newsService from "../../services/news";
import { useAuth } from "../../navigation/Auth/ProvideAuth";
import { useProvideAuth } from "../../navigation/Auth/useProvideAuth";
interface ArticleInfoCardProps {
  articleInfo: SavedArticleInfoCardProps;
  removeArticle: (articleId: string) => void;
  baseURL: string;
}

const SavedArticleInfoCard = ({
  articleInfo: { id, title, excerpt, feature_image, commentsCount },
  removeArticle,
  baseURL,
}: ArticleInfoCardProps) => {
  const { auth } = useAuth();
  const { signout } = useProvideAuth();
  const history = useHistory();
  const viewArticle = () => {
    history.push(baseURL + "/" + id);
  };

  const removeSavedArticle = async () => {
    try {
      const removeArticleResponse = await newsService.unsaveArticle(
        id,
        auth?.access_token
      );
      removeArticle(id);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        //place your reentry code
        await signout();
      }
    }
  };
  return (
    <div className="saved-article-info-card">
      <div className="article-header">
        <div className="article-img-container">
          <img src={feature_image} alt="feat-img" />
        </div>
        <div className="article-info-content">
          <h4 className="article-title">{title}</h4>
          <p className="article-desc">{excerpt}</p>
        </div>
      </div>
      <div className="article-footer">
        <div className="article-detail">
          <p>
            Posted by <span>Administrator</span>
          </p>
          <div className="space-breaker">|</div>
          <div className="comment-count">
            <CommentIcon />
            <p>
              <span>{commentsCount} comments</span>
            </p>
          </div>
        </div>
        <div className="article-action">
          <p onClick={viewArticle}>View</p>
          <p onClick={removeSavedArticle}>Unsave</p>
        </div>
      </div>
    </div>
  );
};

export default SavedArticleInfoCard;
