import React, { FunctionComponent, ReactChild } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import TermsAndCondContent from "./TermsAndCondContent";

const TermsAndCond = () => {
  return (
    <>
      <Header />
      <TermsAndCondContent />
      <Footer />
    </>
  );
};

export default TermsAndCond;
