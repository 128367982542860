export interface MatchEntry {
  match: string;
  start: number;
  end: number;
}

export interface WordListType {
  [key: string]: Array<MatchEntry>;
}
type InappropriateContentType =
  | "personal"
  | "profanity"
  | "External Link"
  | "Product brand names";
export const prepareInappropriateWordList = (
  wordList: WordListType
): Array<MatchEntry> => {
  return [
    ...wordList.profanity,
    ...wordList.link,
    ...wordList.personal,
    ...wordList.blacklist,
  ];
};

export const prepareErrorMsg = (wordList: WordListType): string => {
  const mapCategoryDict: {
    [key: string]: string;
  } = {
    personal: "Personal branding",
    profanity: "Profanity",
    link: "External link",
    blacklist: "Product brand names",
  };
  const errorCategory = Object.keys(wordList).reduce(
    (acc: Array<string>, k: string) => {
      if (wordList[k].length === 0) return [...acc];
      return [...acc, mapCategoryDict[k]];
    },
    []
  );

  const errorMsg = errorCategory.reduce((acc, current_val, index) => {
    if (index == 0) return current_val;
    if (index < errorCategory.length - 1) return acc + ", " + current_val;
    return acc + " and " + current_val;
  }, "");

  return `Inappropriate Content: ${errorMsg} not allowed (please refer to the website terms and conditions).`;
};
