export const initialNews = {
  CLINICAL_NEWS: [],
  JOURNAL_WATCH: [],
  OTHER_RESOURCES: [],
};

export const newsReducer = (state: any, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case "GET_CLINICAL_NEWS":
      return { ...state, CLINICAL_NEWS: [...payload] };
    case "GET_JOURNAL_WATCH":
      return { ...state, JOURNAL_WATCH: [...payload] };
    case "GET_OTHER_RESOURCES":
      return { ...state, OTHER_RESOURCES: [...payload] };
    case "GET_NEWS_ARTICLE":
      return {
        ...state,
        [payload.articleType]:
          state[payload.articleType].length > 0
            ? [...state[payload.articleType], payload.articleDetails]
            : [payload.articleDetails],
      };
    case "RATE_NEWS_ARTICLE":
      return {
        ...state,
        [payload.articleType]: state[payload.articleType].map(
          (article: any) => {
            if (article.id !== payload.articleId) return article;
            return {
              ...article,
              rating: Math.floor(
                (article.rating * article.ratingsCount + payload.rating) /
                  (article.ratingsCount + 1)
              ),
              ratingsCount: article.ratingsCount + 1,
            };
          }
        ),
      };
    case "GET_COMMENTS":
      return {
        ...state,
        [payload.articleType]: state[payload.articleType].map(
          (article: any) => {
            if (article.id !== payload.articleId) return article;
            return {
              ...article,
              comments: payload.commentInfo.comments,
              commentCount: payload.commentInfo.totalCommentsCount,
            };
          }
        ),
      };
    case "ADD_COMMENT":
      return {
        ...state,
        [payload.articleType]: state[payload.articleType].map(
          (article: any) => {
            if (article.id !== payload.articleId) return article;
            return {
              ...article,
              comments: [...article.comments, payload.comment],
              commentCount: article.commentCount + 1,
            };
          }
        ),
      };
    case "DELETE_COMMENT":
      return {
        ...state,
        [payload.articleType]: state[payload.articleType].map(
          (article: any) => {
            if (article.id !== payload.articleId) return article;
            return {
              ...article,
              comments: article.comments.filter(
                (comment: any) => comment.id !== payload.commentId
              ),
              commentCount: article.commentCount - 1,
            };
          }
        ),
      };
    case "ADD_REPLY":
      return {
        ...state,
        [payload.articleType]: state[payload.articleType].map(
          (article: any) => {
            if (article.id !== payload.articleId) return article;
            return {
              ...article,
              comments: article.comments.map((comment: any) => {
                if (comment.id !== payload.commentId) return comment;

                return {
                  ...comment,
                  replies: comment.replies
                    ? [...comment.replies, payload.comment]
                    : [payload.comment],
                };
              }),
            };
          }
        ),
      };
    case "DELETE_REPLY":
      return {
        ...state,
        [payload.articleType]: state[payload.articleType].map(
          (article: any) => {
            if (article.id !== payload.articleId) return article;
            return {
              ...article,
              comments: article.comments.map((comment: any) => {
                if (comment.id !== payload.commentId) return comment;

                return {
                  ...comment,
                  replies: comment.replies.filter(
                    (reply: any) => reply.id !== payload.replyId
                  ),
                };
              }),
            };
          }
        ),
      };
    case "SAVE_NEWS_ARTICLE":
      return {
        ...state,
        [payload.articleType]: state[payload.articleType].map(
          (article: any) => {
            if (article.id !== payload.articleId) return article;
            return {
              ...article,
              isSaved: !article.isSaved,
            };
          }
        ),
      };
    default:
      return state;
  }
};
