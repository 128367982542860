import React, { FunctionComponent } from "react";

import "./PopUp.scss";
interface PopUpProps {
  children: React.ReactNode;
}

const PopUp: FunctionComponent<PopUpProps> = ({ children }: PopUpProps) => {
  return (
    <div className="popup">
      <div className="innerpopup">{children}</div>
    </div>
  );
};

export default PopUp;
