import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Board from "../../components/Board";
import algoliasearch from "algoliasearch/lite";
import { css } from "@emotion/react";
import { CLINICALNEWS } from "../../navigation/CONSTANT";
import { clinicalNews } from "./clinicalNews";
import { ArticleProps } from "../../types";
import { extractArticles } from "./helper";
import SyncLoaderSpinner from "../../components/SyncLoader";
const SearchArticle = () => {
  const { searchQuery } = { searchQuery: "", ...useParams() };
  const [articles, setArticles] = useState<Array<ArticleProps>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const loaderStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
  `;

  useEffect(() => {
    const getMatchedArticles = async () => {
      setLoading(true);
      const searchClient = algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP_ID as string,
        process.env.REACT_APP_ALGOLIA_APP_KEY as string
      );
      const index = await searchClient.initIndex("hcpcomm_information_posts");
      const result = await index.search(searchQuery);
      setArticles(extractArticles(result));
      setLoading(false);
    };
    try {
      getMatchedArticles();
    } catch (error) {
      setLoading(false);
    }
  }, [searchQuery]);

  return (
    <div className="information-menu">
      {loading ? (
        <SyncLoaderSpinner isLoading={loading} override={loaderStyle} />
      ) : (
        <Board
          articles={articles}
          title={`SEARCH RESULTS: ${searchQuery}`}
          navigateTo={CLINICALNEWS}
          viewAll={false}
        />
      )}
    </div>
  );
};

export default SearchArticle;
