import React, { useEffect, useState } from "react";
import { ReactComponent as CommentIcon } from "../../../assets/images/comment.svg";
import Rating from "../../../components/Rating";
import "./Conference.scss";
import CommentCard from "../../../components/CommentCard";
import Button from "../../../components/Button";
import PopUp from "../../../components/PopUp";
import SharePopUp from "../../../components/SharePopUp";
import ThankYou from "../../../components/ThankYouPopUp";
import RatePopUp from "../../../components/RatePopUp";
import { useParams } from "react-router";
import { useConferenceContext } from "../../../context/Conferences";
import { getConfByConfId, prepareVideoURL } from "../helper";
import conferenceService from "../../../services/conferences";
import { useAuth } from "../../../navigation/Auth/ProvideAuth";
import newsService from "../../../services/news";
import { ratingErrorInformation } from "./errorTypes";
import { useLoggedUserContext } from "../../../context/LoggedUser";
import ArticleAddComment from "../../../components/ArticleAddComment";
import ArticleInteraction from "../../../components/ArticleInteraction";
import SkeletonConfBody from "../../../components/Skeleton/SkeletonConfBody";

const Conference = () => {
  const { auth } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const { loggedUserInfo } = useLoggedUserContext();
  const [ratePopUp, setRatePopUp] = useState<boolean>(false);
  const [sharePopUp, setSharePopUp] = useState<boolean>(false);
  const [thankYouPopUp, setThankYouPopUp] = useState<string>("");
  const { conferenceId } = useParams<{ conferenceId?: string }>();
  const { conferences, setConferences } = useConferenceContext();

  // handles comment submission
  const submitCommenthandler = async (commentText: string) => {
    const response = await newsService.addComment(
      conferenceId ? conferenceId : "",
      commentText,
      auth?.access_token
    );
    const comment = {
      // add actual id here
      id: response.commentId,
      userId: loggedUserInfo.id,
      image: loggedUserInfo.image,
      firstName: loggedUserInfo.firstName,
      lastName: loggedUserInfo.lastName,
      commentText,
    };
    setConferences({
      type: "ADD_CONF_COMMENT",
      payload: {
        conferenceId,
        comment,
      },
    });
  };
  // handles comment updation
  const updateCommentHandler = async (
    commentText: string,
    commentId: number
  ) => {
    const response = await newsService.updateComment(
      commentText,
      commentId,
      auth?.access_token
    );
    const comment = {
      // add actual id here
      id: response.commentId,
      userId: loggedUserInfo.id,
      image: loggedUserInfo.image,
      firstName: loggedUserInfo.firstName,
      lastName: loggedUserInfo.lastName,
      commentText,
    };
    setConferences({
      type: "UPDATE_CONF_COMMENT",
      payload: {
        conferenceId,
        comment,
      },
    });
  };
  // handles comment deletion
  const deleteCommentHandler = async (commentId: number, replyId?: number) => {
    if (replyId) {
      //delete the reply
      const response = await newsService.deleteComment(
        replyId,
        auth?.access_token
      );
      setConferences({
        type: "DELETE_CONF_REPLY",
        payload: {
          conferenceId,
          commentId,
          replyId,
        },
      });
    } else {
      //delete the comment
      const response = await newsService.deleteComment(
        commentId,
        auth?.access_token
      );
      setConferences({
        type: "DELETE_CONF_COMMENT",
        payload: {
          conferenceId,
          commentId,
          replyId,
        },
      });
    }
  };
  // handles reply submission
  const submitReplyHandler = async (commentText: string, commentId: number) => {
    const response = await newsService.addReply(
      conferenceId ? conferenceId : "",
      commentText,
      commentId,
      auth?.access_token
    );
    const comment = {
      // add actual id here
      id: response.commentId,
      userId: loggedUserInfo.id,
      image: loggedUserInfo.image,
      firstName: loggedUserInfo.firstName,
      lastName: loggedUserInfo.lastName,
      commentText,
    };
    setConferences({
      type: "ADD_CONF_REPLY",
      payload: {
        conferenceId,
        commentId,
        comment,
      },
    });
  };

  const ratePopSubmitHandler = async (ratingValue: number) => {
    try {
      const response = await newsService.rateArticle(
        auth?.access_token,
        conferenceId,
        ratingValue,
        "conference-highlights"
      );
      setThankYouPopUp("your rating has been submitted");
      setRatePopUp(false);
      setConferences({
        type: "UPDATE_CONFERENCE_RATING",
        payload: { conferenceId, rating: ratingValue },
      });
    } catch (error: any) {
      setThankYouPopUp(
        ratingErrorInformation[error.response.data] || error.response.data
      );
      setRatePopUp(false);
    }
  };
  const sharePopSubmitHandler = () => {
    setSharePopUp(false);
    setThankYouPopUp("YOU HAVE SUCCESSFULLY SHARED THE ARTICLE");
  };

  const getCommentInformation = async (pageNumber: number) => {
    // get comment information on conference
    const commentInfo = await newsService.getComment(
      conferenceId,
      5,
      pageNumber,
      auth?.access_token
    );
    setConferences({
      type: "GET_CONF_COMMENTS",
      payload: {
        conferenceId,
        commentInfo,
      },
    });
  };

  const changePageNumber = (changeVal: number) => {
    setPageNumber(pageNumber + changeVal);
  };

  useEffect(() => {
    const getAllConferences = async () => {
      // get conference details
      setLoading(true);
      const conferencesResp = await conferenceService.getConference(
        conferenceId,
        auth?.access_token
      );
      setConferences({
        type: "SET_CONFERENCE",
        payload: conferencesResp,
      });

      await getCommentInformation(1);
      setLoading(false);
    };

    getAllConferences();
  }, []);

  const selectedConference = getConfByConfId(conferences.data, conferenceId);

  const {
    title,
    feature_image,
    embed_video_link,
    pdf_link,
    rating,
    comments,
    commentCount,
  } = selectedConference
    ? selectedConference
    : {
        title: "",
        feature_image: "",
        embed_video_link: "",
        pdf_link: "",
        rating: 0,
        commentCount: 0,
        comments: [],
      };
  const videoLink = prepareVideoURL(
    embed_video_link,
    loggedUserInfo.digital_id,
    loggedUserInfo.customer_id,
    loggedUserInfo.crm_country
  );
  return (
    <div className="conf">
      <div className="conf-top">
        {loading && <SkeletonConfBody />}
        {!loading && (
          <div className="conf-main">
            <div className="conf-img">
              <img src={feature_image} alt="Rare Disease artice" />
            </div>
            <div className="conf-info">
              <a href={pdf_link} target="_blank" rel="noreferrer">
                <Button
                  type="button"
                  size="size-lg"
                  colorTheme="has-primary-theme"
                >
                  View documents
                </Button>
              </a>
              <Rating rating={rating} />
            </div>

            <div className="conf-content">
              <div className="video-content">
                {/* <iframe
                  width="100%"
                  height="343"
                  src="https://player.vimeo.com/video/670699199?h=c2cccd95a4&badge=0&autopause=0&player_id=0&app_id=58479&byline=0&title=0&portrait=0"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe> */}
                <iframe
                  src={videoLink}
                  allowFullScreen
                  allow="encrypted-media"
                  width="100%"
                  height="343"
                ></iframe>
              </div>
            </div>
          </div>
        )}

        <ArticleInteraction
          commentCount={commentCount}
          comments={comments}
          submitReplyHandler={submitReplyHandler}
          deleteCommentHandler={deleteCommentHandler}
          changePageNumber={changePageNumber}
          getCommentInformation={getCommentInformation}
          pageNumber={pageNumber}
          isLoading={false}
        />
        <ArticleAddComment submitCommenthandler={submitCommenthandler} />
      </div>

      <div className="conf-footer">
        <div className="contribute-buttons">
          <Button
            type="button"
            size="size-lg"
            colorTheme="has-secondary-theme"
            onClick={() => setRatePopUp(true)}
          >
            Rate
          </Button>
          <Button
            type="button"
            size="size-lg"
            colorTheme="has-secondary-theme"
            onClick={() => setSharePopUp(true)}
          >
            Share
          </Button>
          {ratePopUp ? (
            <PopUp>
              <RatePopUp
                submitHandler={ratePopSubmitHandler}
                cancelPopUp={() => setRatePopUp(false)}
              />
            </PopUp>
          ) : (
            ""
          )}
          {sharePopUp ? (
            <PopUp>
              <SharePopUp
                title={title}
                submitHandler={sharePopSubmitHandler}
                cancelPopUp={() => setSharePopUp(false)}
              />
            </PopUp>
          ) : (
            ""
          )}
          {thankYouPopUp ? (
            <PopUp>
              <ThankYou
                msg={thankYouPopUp}
                submitHandler={() => setThankYouPopUp("")}
              />
            </PopUp>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Conference;
