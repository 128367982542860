import React from "react";
import "./AboutUsBody.scss";

const AboutUsBody = () => {
  return (
    <div className="about-us-body">
      <div className="content">
        <h3>About Takeda:</h3>
        <p>
          Takeda Pharmaceutical Company Limited is a global, values-based,
          R&D-driven biopharmaceutical leader headquartered in Japan, committed
          to discovering and delivering life-transforming treatments guided by
          our commitment to patients, our people, and the planet. Takeda focuses
          its R&D efforts on four therapeutic areas: Oncology, Rare Genetics and
          Hematology, Neuroscience, and Gastroenterology (GI). We also make
          targeted R&D investments in Plasma-Derived Therapies and Vaccines. We
          focus on developing highly innovative medicines that contribute to
          making a difference in people&apos;s lives by advancing the frontier
          of new treatment options and leveraging our enhanced collaborative R&D
          engine and capabilities to create a robust, modality-diverse pipeline.
          Our employees are committed to improving patients&apos; quality of
          life and working with our healthcare partners in approximately 80
          countries and regions.
        </p>
        <p>
          Our passion and pursuit of potentially life-changing treatments for
          patients are deeply rooted in over 240 years of distinguished history
          in Japan. As a global pharmaceutical leader, we focus on solving unmet
          needs to make a real difference and put patients first. Our
          therapeutic focus is in the areas of Rare diseases, Plasma-derived
          therapies, oncology, gastroenterology, neurosciences, and vaccines.
        </p>
        <p>
          Today, as a values-based and R&D-driven biopharmaceutical leader with
          approximately 50,000 employees in 80 countries, we are positioned and
          equipped to deliver highly innovative medicines and transformative
          care around the world. Our unwavering commitment to putting patients
          first drives our scientific discovery and helps us as we strive to
          address unmet medical needs
        </p>
        <p>
          Takeda&apos;s long history has been built through sincerity and
          striving for innovation at all times.
        </p>
        <p>
          Our mission is to continue contributing to patients&apos; health
          worldwide by creating superior medicines. With the patient at the
          center, we will continue to implement changes to create innovation in
          the future.
        </p>
        <h3>About MedUnifi:</h3>
        <p>
          MedUnifi is a non-promotional medically driven Artificial Intelligence
          (AI) powered online community for health care professionals (HCPs) who
          are interested in learning about the latest treatment guidelines,
          management protocols and any new developments related to the
          management of diseases in the areas of rare hematology, rare genetic
          diseases, immunology, hereditary angioedema, oncology and
          gastroenterology. It provides them with the opportunity to access
          relevant news articles, slide modules/education and videos of
          scientific talks and tutorials related to the management of these
          diseases, while also providing them with an opportunity to connect
          with the wider medical community of HCPs who have similar areas of
          interest, thus nurturing a collaborative learning environment. The AI
          element embedded in the platform ensures that HCPs are able to
          prioritize the content relevant to them and content that they want to
          see.
        </p>
      </div>
    </div>
  );
};

export default AboutUsBody;
