import React, { FunctionComponent } from "react";
import "./ConferenceBoard.scss";
import { ConferenceCardType } from "../../types";
import ConferenceCard from "../ConferenceCard";
interface ConferenceBoardProps {
  conferences: Array<ConferenceCardType>;
}

const ConferenceBoard: FunctionComponent<ConferenceBoardProps> = ({
  conferences,
}: ConferenceBoardProps) => {
  return (
    <div className="conference-board">
      {conferences.map((conference) => (
        <ConferenceCard key={conference.id} {...conference} />
      ))}
    </div>
  );
};

export default ConferenceBoard;
