import React, { FunctionComponent, useEffect, useState } from "react";
import MessageHeader from "./MessageHeader";
import MessageForm from "./MessageForm";
import "./MessageContent.scss";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../navigation/Auth/ProvideAuth";
import { ProfileInfoProps } from "../../../types";
import communityMemService from "../../../services/communityMembers";

const initialProfileValue = {
  email: "",
  firstName: "",
  id: null,
  image: null,
  lastName: "",
  organisation: "",
  professionalNumber: "",
  region: "",
  seniority: "",
  speciality: "",
  takedaId: "",
  title: "",
};
const MessageContent: FunctionComponent = () => {
  const { auth } = useAuth();
  const { receiverId } = {
    receiverId: null,
    ...useParams(),
  };

  const [viewProfileDetails, setViewProfileDetails] =
    useState<ProfileInfoProps>(initialProfileValue);

  useEffect(() => {
    const getProfileByProfileId = async () => {
      const userProfileResp = await communityMemService.getCommunityMemberById(
        auth?.access_token,
        receiverId
      );
      setViewProfileDetails(userProfileResp);
    };

    getProfileByProfileId();
  }, [receiverId]);
  return (
    <main className="message-page">
      <MessageHeader />
      <MessageForm
        profileDetails={viewProfileDetails}
        receiverId={receiverId}
      />
    </main>
  );
};

export default MessageContent;
