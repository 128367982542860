import React from "react";
import "./CommunityBody.scss";
import CommunityInfo from "./CommunityInfo";
interface CommunityBodyProps {
  BodyComponent: React.FunctionComponent;
}

const CommunityBody = ({ BodyComponent }: CommunityBodyProps) => {
  return (
    <section className="community-body">
      <CommunityInfo />
      <BodyComponent />
    </section>
  );
};

export default CommunityBody;
