import React, { FunctionComponent } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SearchContent from "./SearchContent";

const Search: FunctionComponent = () => {
  return (
    <>
      <Header />
      <SearchContent />
      <Footer />
    </>
  );
};

export default Search;
