export const getNewsType = (articleType?: string): string => {
  switch (articleType) {
    case "clinicalnews":
      return "CLINICAL_NEWS";
    case "journalwatch":
      return "JOURNAL_WATCH";
    case "otherresources":
      return "OTHER_RESOURCES";
    default:
      return "";
  }
};

export const getNewsRoute = (articleType?: string): string => {
  switch (articleType) {
    case "clinicalnews":
      return "clinical-news";
    case "journalwatch":
      return "journal-watch";
    case "otherresources":
      return "other-resources";
    default:
      return "";
  }
};

export const getArticleByArticleId = (
  news: any,
  articleType?: string,
  id?: string
) => {
  return articleType
    ? news[articleType].find((article: any) => article.id === id)
    : null;
};
