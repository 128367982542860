import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ChangePasswordContent from "./ChangePasswordContent";

const ChangePassword = () => {
  return (
    <>
      <Header />
      <ChangePasswordContent />
      <Footer />
    </>
  );
};

export default ChangePassword;
