import React, { useEffect, useState } from "react";
import ActivityCard from "../../../components/ActivityCard";
import ServiceCard from "../../../components/ServiceCard";
import "./ActPreferenceMenu.scss";
import { css } from "@emotion/react";
import { useActPreferenceContext } from "../../../context/ActivityPreference";
import actPreferenceService from "../../../services/activityPreference";
import { useAuth } from "../../../navigation/Auth/ProvideAuth";
import SyncLoaderSpinner from "../../../components/SyncLoader";
import { useProvideAuth } from "../../../navigation/Auth/useProvideAuth";

const ActPreferenceMenu = () => {
  const { auth } = useAuth();
  const { signout } = useProvideAuth();
  const { actPreference, setActPreference } = useActPreferenceContext();
  const [loading, setLoading] = useState<boolean>(true);
  const loaderStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  `;

  useEffect(() => {
    const getActivityPreferences = async () => {
      try {
        const payload = await actPreferenceService.getActPreferences(
          auth?.access_token
        );
        setActPreference({ type: "INIT_ACT_PREF", payload });
        setLoading(false);
      } catch (error: any) {
        if (error?.response?.status === 401) {
          //place your reentry code
          await signout();
        }
      }
    };
    getActivityPreferences();
  }, [setActPreference]);

  return (
    <div className="activity-menu">
      <h3 className="activity-menu-title">
        please choose activities that you would like to participate in
      </h3>
      {loading ? (
        <SyncLoaderSpinner isLoading={loading} override={loaderStyle} />
      ) : (
        <div className="activity-menu-container">
          {actPreference.map((activity: any) => (
            <ActivityCard
              key={activity.activityTypeId}
              activityTypeId={activity.activityTypeId}
              name={activity.name}
              description={activity.description}
              optInStatus={activity.optInStatus}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ActPreferenceMenu;
