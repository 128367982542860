import React, { FunctionComponent } from "react";
import CommunityMembersBody from "./CommunityMembersBody";
import CommunityMembersHeader from "./CommunityMembersHeader";
import "./CommunityMembersContent.scss";

const CommunityMembersContent: FunctionComponent = () => {
  return (
    <main className="comm-mem-page">
      <CommunityMembersHeader />
      <CommunityMembersBody />
    </main>
  );
};

export default CommunityMembersContent;
