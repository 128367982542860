import React from "react";
import ServiceCard from "../../../components/ServiceCard";
import "./CommunityMenu.scss";
import { servicecards } from "./serviceCards";

const CommunityMenu = () => {
  return (
    <div className="community-menu">
      {servicecards.map((servicecard, index) => (
        <ServiceCard
          key={"service" + index}
          title={servicecard.title}
          subTitle={servicecard.subTitle}
          cardImage={servicecard.cardImage}
          categories={servicecard.categories}
          cardLink={servicecard.cardLink}
        />
      ))}
    </div>
  );
};

export default CommunityMenu;
