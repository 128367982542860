import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { SavedArticleInfoCardProps } from "../../types";
import ArticleInfoCard from "../ArticleInfoCard";
import SavedArticleInfoCard from "../SavedArticleInfoCard";

import "./SavedArticleDisplayBoard.scss";
interface BoardProps {
  articles: Array<SavedArticleInfoCardProps>;
  removeArticle: (articleId: string) => void;
  navigateTo: string;
}

const SavedArticleDisplayBoard: FunctionComponent<BoardProps> = ({
  articles,
  removeArticle,
  navigateTo,
}: BoardProps) => {
  return (
    <div className="saved-article-board">
      <div className="board-content">
        {articles?.length > 0 ? (
          articles.map((article) => (
            <SavedArticleInfoCard
              key={article.id}
              articleInfo={article}
              baseURL={navigateTo}
              removeArticle={removeArticle}
            />
          ))
        ) : (
          <p className="instruction">No saved articles found</p>
        )}
      </div>
    </div>
  );
};

export default SavedArticleDisplayBoard;
