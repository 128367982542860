import React, { FunctionComponent, useState } from "react";
import Button from "../Button";
import CancelPopUpSVG from "../CancelPopUpSVG";
import Rating from "../Rating";
import "./RatePopUp.scss";
interface RatePopUpProps {
  submitHandler: (rating: number) => void;
  cancelPopUp: () => void;
}

const RatePopUp: FunctionComponent<RatePopUpProps> = ({
  submitHandler,
  cancelPopUp,
}: RatePopUpProps) => {
  const [rating, setRating] = useState<number>(0);
  const handleClick = (ratingValue: number) => {
    setRating(ratingValue);
  };
  return (
    <div className="rate-popup">
      <CancelPopUpSVG cancelPopUp={cancelPopUp} />
      <h1>Rate this article</h1>
      <h3>Rare Diseases</h3>
      <Rating rating={rating} handleClick={handleClick} />
      <Button
        type="button"
        size="size-lg"
        colorTheme="has-primary-theme"
        onClick={() => submitHandler(rating)}
      >
        Submit
      </Button>
    </div>
  );
};

export default RatePopUp;
