import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router";
import Button from "../../../components/Button";
import FormikControl from "../../../components/Form/FormikControl";
import TextError from "../../../components/Form/TextError";
import InviteConfirmPopUp from "../../../components/InviteConfirmPopUp";
import LoadingSpinner from "../../../components/LoadingSpinner";
import PopUp from "../../../components/PopUp";
import { useAuth } from "../../../navigation/Auth/ProvideAuth";
import { useProvideAuth } from "../../../navigation/Auth/useProvideAuth";
import { INVITEFRIENDSUCCESS } from "../../../navigation/CONSTANT";
import feedbackService from "../../../services/feedback";
import { inviteFriendErrorInformation } from "./errorTypes";
import "./InviteFriendBody.scss";
import { inviteFriendInitialValues } from "./inviteFriendInitialValues";
import { inviteFriendValidationScheme } from "./inviteFriendValidationScheme";

const InviteFriendBody = () => {
  const { auth } = useAuth();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [popup, setPopup] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const { signout } = useProvideAuth();
  const history = useHistory();
  const initialValues = inviteFriendInitialValues;
  const validationSchema = inviteFriendValidationScheme;

  const backBtnHandler = async () => {
    setPopup(false);
  };

  const confirmBtnHandler = async (email: string) => {
    setPopup(false);
    setLoading(true);
    setError("");
    const inviteFriendPayload = {
      email,
    };
    try {
      const inviteFriendResp = await feedbackService.inviteFriend(
        auth?.access_token,
        inviteFriendPayload
      );
      setLoading(false);
      history.push(INVITEFRIENDSUCCESS);
    } catch (error: any) {
      setError(
        inviteFriendErrorInformation[error?.response?.data] ||
          "Error occured while sending the invite"
      );
      setLoading(false);
      if (error?.response?.status === 401) {
        await signout();
      }
    }
  };

  const onSubmit = async (values: any, actions: any) => {
    setEmail(values.email);
    setPopup(true);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, isValid, setFieldValue }) => (
        <section className="invite-friend-body">
          <div className="invite-friend-form-container">
            <Form className="invite-friend-form" onChange={() => setError("")}>
              <FormikControl
                control="input"
                type="email"
                label="Friends email address"
                name="email"
              />
              <Button
                type="submit"
                size="size-xxlg"
                disabled={!isValid}
                colorTheme="has-primary-theme"
              >
                Send email request
              </Button>
              <LoadingSpinner isLoading={isLoading} />
              {error && <TextError>{error}</TextError>}
            </Form>
            {popup && (
              <PopUp>
                <InviteConfirmPopUp
                  confirmBtnHandler={() => confirmBtnHandler(email)}
                  backBtnHandler={backBtnHandler}
                />
              </PopUp>
            )}
          </div>
        </section>
      )}
    </Formik>
  );
};

export default InviteFriendBody;
