import React, { FunctionComponent } from "react";
const LoginHeader: FunctionComponent = () => {
  return (
    <section className="login-page-header">
      <h1>MedUnifi Login</h1>
    </section>
  );
};

export default LoginHeader;
