import axios from "axios";

const baseEducationURL = process.env.REACT_APP_BASE_URL + "/education/";

type sortByValues = "added" | "views" | "rating";
type orderByValues = "asc" | "desc";

const getAllTherapyModules = async (
  category: string,
  page: number,
  accessToken: string,
  sortBy: sortByValues = "added",
  orderBy: orderByValues = "desc"
) => {
  const response = await axios.get(baseEducationURL + category, {
    params: {
      limit: 3,
      page,
      sortBy,
      orderBy,
    },
    headers: { "takeda-access-token": accessToken },
  });
  return response.data;
};
const getTherapyModule = async (
  category: string,
  postId: string,
  accessToken: string
) => {
  const response = await axios.get(baseEducationURL + category + "/" + postId, {
    headers: { "takeda-access-token": accessToken },
  });
  return response.data;
};

const therapyService = {
  getAllTherapyModules,
  getTherapyModule,
};

export default therapyService;
