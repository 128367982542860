import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../../components/Button";
import { COMMUNITY } from "../../../../navigation/CONSTANT";

const CommunityMembersHeader: FunctionComponent = () => {
  const history = useHistory();
  return (
    <section className="comm-mem-page-header">
      <div className="header-content">
        <h1>Community Members</h1>
        <Button
          type="button"
          size="size-lg"
          colorTheme="has-secondary-theme"
          onClick={() => history.push(COMMUNITY)}
        >
          Back to Community
        </Button>
      </div>
    </section>
  );
};

export default CommunityMembersHeader;
