import React from "react";
import { Field, ErrorMessage } from "formik";
import { ReactComponent as Eye } from "../../assets/images/eye.svg";
import { ReactComponent as EyeHide } from "../../assets/images/eye-hide.svg";
import { useState } from "react";
import TextError from "./TextError";

const Password = (props: any) => {
  const { label, name, ...rest } = props;
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="form-control">
      <label htmlFor={name}>{label}</label>
      <Field
        name={name}
        id={name}
        {...rest}
        type={showPassword ? "text" : "password"}
      />
      {showPassword ? (
        <EyeHide
          className="pwd-icon"
          onClick={() => setShowPassword(!showPassword)}
        />
      ) : (
        <Eye
          className="pwd-icon"
          onClick={() => setShowPassword(!showPassword)}
        />
      )}
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

export default Password;
