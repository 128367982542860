import algoliasearch from "algoliasearch";
import { CMEDisplayCardProps, OptionProp, TopicProps } from "../../../../types";
export const searchEducationModules = async (
  searchQuery: string,
  filter: string
) => {
  if (!searchQuery && !filter) {
    return { hits: [] };
  }
  const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID as string,
    process.env.REACT_APP_ALGOLIA_APP_KEY as string
  );
  const index = await searchClient.initIndex("hcpcomm_education_modules");

  if (filter && filter !== "Select...") {
    return await index.search(searchQuery, {
      filters: `topic:'${filter}'`,
    });
  }

  return await index.search(searchQuery);
};

export const extractEducationModule = (result: any) => {
  return result.hits.map(
    ({ objectID, title, topic, pdfLinks, dateLaunched, videoLinks }: any) => {
      return { id: objectID, title, topic, pdfLinks, dateLaunched, videoLinks };
    }
  );
};

const compareString = (a: string, b: string): number => {
  if (a.toUpperCase() <= b.toUpperCase()) {
    return -1;
  }
  return 1;
};

const compareDates = (a: string, b: string): number => {
  if (Date.parse(a) <= Date.parse(b)) {
    return -1;
  }
  return 1;
};

export const sortResultsByCriteria = (
  result: Array<CMEDisplayCardProps>,
  criteria: string
): Array<CMEDisplayCardProps> => {
  switch (criteria) {
    case "A":
      // sort by date launched descending
      return result.sort((a, b) =>
        compareDates(b.dateLaunched, a.dateLaunched)
      );
    case "B":
      // sort by date launched ascending
      return result.sort((a, b) =>
        compareDates(a.dateLaunched, b.dateLaunched)
      );
    case "C":
      // sort by title alphabetical
      return result.sort((a, b) => compareString(a.title, b.title));
    case "D":
      // sort by title reverse alphabetical
      return result.sort((a, b) => compareString(b.title, a.title));

    default:
      return result;
  }
};

export const prepareTopics = (topics: Array<TopicProps>): Array<OptionProp> => {
  return topics.reduce(
    (optionAcc, topic) => {
      return [...optionAcc, { key: topic.topic, value: topic.topic }];
    },
    [{ value: "", key: "Select..." }]
  );
};

export const selectOnlyBookmarked = (
  result: Array<CMEDisplayCardProps>,
  bookmarked: Array<CMEDisplayCardProps>
): Array<CMEDisplayCardProps> => {
  const bookmarkedIDs = bookmarked.map((currentModule) => currentModule.id);

  return result.filter((currentModule) =>
    bookmarkedIDs.includes(currentModule.id)
  );
};
