import React, { FunctionComponent } from "react";
import DefaultAvatar from "../../assets/images/default-avatar.png";
import Button from "../Button";
import MessageCounter from "../MessageCounter";
import "./MessageCard.scss";
import { MessageCardProps } from "../../types";
import { useHistory } from "react-router-dom";
import { MYMESSAGECLICKTHROUGHBASE } from "../../navigation/CONSTANT";

const MessageCard: FunctionComponent<MessageCardProps> = ({
  cardType,
  image,
  firstName,
  lastName,
  messageText,
  recipientId,
  title,
  unseenCount,
}: MessageCardProps) => {
  const history = useHistory();
  const openConversation = () => {
    history.push(MYMESSAGECLICKTHROUGHBASE + recipientId);
  };
  const fullName = [title, firstName, lastName].join(" ");
  return (
    <div className="message-card">
      <div className="msg-info">
        <div className="img-container">
          <img src={image || DefaultAvatar} alt={fullName} />
        </div>
        <div
          className={[
            "msg-content",
            cardType === "REPLY" ? "full-width" : "",
          ].join(" ")}
        >
          <div className="sender-info">
            <p className="sender-name">{fullName}</p>
            <MessageCounter msgCount={unseenCount} />
          </div>
          <div className="message-text">{messageText}</div>
        </div>
      </div>
      {cardType === "MESSAGE" ? (
        <div className="msg-view-btn">
          <Button
            type="button"
            size="size-lg"
            colorTheme="has-secondary-theme"
            onClick={openConversation}
          >
            View
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MessageCard;
