import React, { useEffect, useReducer, useState } from "react";
import Rating from "../../../components/Rating";
import "./Therapy.scss";
import Button from "../../../components/Button";
import PopUp from "../../../components/PopUp";
import SharePopUp from "../../../components/SharePopUp";
import ThankYou from "../../../components/ThankYouPopUp";
import RatePopUp from "../../../components/RatePopUp";
import { useParams } from "react-router";
import { useAuth } from "../../../navigation/Auth/ProvideAuth";
import newsService from "../../../services/news";
import { ratingErrorInformation } from "./errorTypes";
import { useLoggedUserContext } from "../../../context/LoggedUser";
import ArticleAddComment from "../../../components/ArticleAddComment";
import ArticleInteraction from "../../../components/ArticleInteraction";
import SkeletonConfBody from "../../../components/Skeleton/SkeletonConfBody";
import therapyService from "../../../services/therapyarea";
import {
  initialTherapyDetails,
  therapyReducer,
  therapyActionKind,
  prepareVideoURL,
} from "./helper";
import PdfViewer from "../../../components/PdfViewer";
import { useProvideAuth } from "../../../navigation/Auth/useProvideAuth";

const Therapy = ({ slug }: { slug: string }) => {
  const { auth } = useAuth();
  const { signout } = useProvideAuth();
  const { postId } = useParams<{ postId?: string }>();
  const [therapyDetails, setTherapyDetails] = useReducer(
    therapyReducer,
    initialTherapyDetails
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const { loggedUserInfo } = useLoggedUserContext();
  const [ratePopUp, setRatePopUp] = useState<boolean>(false);
  const [sharePopUp, setSharePopUp] = useState<boolean>(false);
  const [thankYouPopUp, setThankYouPopUp] = useState<string>("");

  // handles comment submission
  const submitCommenthandler = async (commentText: string) => {
    const response = await newsService.addComment(
      postId ? postId : "",
      commentText,
      auth?.access_token
    );
    const comment = {
      // add actual id here
      id: response.commentId,
      userId: loggedUserInfo.id,
      image: loggedUserInfo.image,
      firstName: loggedUserInfo.firstName,
      lastName: loggedUserInfo.lastName,
      commentText,
    };
    setTherapyDetails({
      type: therapyActionKind.ADDTHERAPYCOMMENT,
      payload: {
        postId,
        comment,
      },
    });
  };

  // handles reply submission
  const submitReplyHandler = async (commentText: string, commentId: number) => {
    const response = await newsService.addReply(
      postId ? postId : "",
      commentText,
      commentId,
      auth?.access_token
    );
    const comment = {
      // add actual id here
      id: response.commentId,
      userId: loggedUserInfo.id,
      image: loggedUserInfo.image,
      firstName: loggedUserInfo.firstName,
      lastName: loggedUserInfo.lastName,
      commentText,
    };
    setTherapyDetails({
      type: therapyActionKind.ADDTHERAPYREPLY,
      payload: {
        postId,
        commentId,
        comment,
      },
    });
  };

  // handles comment deletion
  const deleteCommentHandler = async (commentId: number, replyId?: number) => {
    if (replyId) {
      //delete the reply
      const response = await newsService.deleteComment(
        replyId,
        auth?.access_token
      );
      setTherapyDetails({
        type: therapyActionKind.DELETETHERAPYREPLY,
        payload: {
          postId,
          commentId,
          replyId,
        },
      });
    } else {
      //delete the comment
      const response = await newsService.deleteComment(
        commentId,
        auth?.access_token
      );
      setTherapyDetails({
        type: therapyActionKind.DELETETHERAPYCOMMENT,
        payload: {
          postId,
          commentId,
          replyId,
        },
      });
    }
  };

  const ratePopSubmitHandler = async (ratingValue: number) => {
    try {
      const response = await newsService.rateArticle(
        auth?.access_token,
        postId,
        ratingValue,
        slug
      );
      setThankYouPopUp("your rating has been submitted");
      setRatePopUp(false);
      setTherapyDetails({
        type: therapyActionKind.UPDATETHERAPYRATING,
        payload: { postId, rating: ratingValue },
      });
    } catch (error: any) {
      setThankYouPopUp(
        ratingErrorInformation[error?.response?.data] ||
          "Error in rating the article"
      );
      setRatePopUp(false);
    }
  };

  const sharePopSubmitHandler = () => {
    setSharePopUp(false);
    setThankYouPopUp("YOU HAVE SUCCESSFULLY SHARED THE ARTICLE");
  };

  const getCommentInformation = async (pageNumber: number) => {
    // get comment information on therapyerence
    const commentInfo = await newsService.getComment(
      postId,
      5,
      pageNumber,
      auth?.access_token
    );
    const commentInfoResp = {
      comments: commentInfo?.comments,
      commentCount: commentInfo?.totalCommentsCount,
    };
    setTherapyDetails({
      type: therapyActionKind.GETTHERAPYCOMMENTS,
      payload: {
        postId,
        commentInfo,
      },
    });
  };

  const changePageNumber = (changeVal: number) => {
    setPageNumber(pageNumber + changeVal);
  };

  useEffect(() => {
    const getTherapyPost = async () => {
      setLoading(true);
      try {
        if (!postId) return;
        const therapyPostResp = await therapyService.getTherapyModule(
          slug,
          postId,
          auth?.access_token
        );
        setTherapyDetails({
          type: therapyActionKind.GETTHERAPY,
          payload: therapyPostResp,
        });
      } catch (error: any) {
        if (error?.response?.status === 401) {
          //place your reentry code
          await signout();
        }
      }

      await getCommentInformation(1);
      setLoading(false);
    };

    getTherapyPost();
  }, []);

  const {
    title,
    feature_image,
    embed_video_link,
    pdf_link,
    rating,
    comments,
    commentCount,
  } = therapyDetails;
  const videoLink = prepareVideoURL(
    embed_video_link,
    loggedUserInfo.digital_id,
    loggedUserInfo.customer_id,
    loggedUserInfo.crm_country
  );
  return (
    <div className="therapy">
      <div className="therapy-top">
        {loading && <SkeletonConfBody />}
        {!loading && (
          <div className="therapy-main">
            <div className="therapy-img">
              {/* <img src={feature_image} alt="Rare Disease artice" /> */}
            </div>
            <div className="therapy-info">
              {/* <a href={pdf_link} target="_blank" rel="noreferrer">
                <Button
                  type="button"
                  size="size-lg"
                  colorTheme="has-primary-theme"
                >
                  View documents
                </Button>
              </a> */}
              <Rating rating={rating} />
            </div>

            <div className="therapy-content">
              {pdf_link && (
                <div className="pdf-content">
                  <PdfViewer pdfLink={pdf_link} />
                </div>
              )}
              {videoLink && (
                <div className="video-content">
                  <iframe
                    src={videoLink}
                    allowFullScreen
                    allow="encrypted-media"
                    width="100%"
                    height="343"
                  ></iframe>
                </div>
              )}
            </div>
          </div>
        )}

        <ArticleInteraction
          commentCount={commentCount}
          comments={comments}
          submitReplyHandler={submitReplyHandler}
          deleteCommentHandler={deleteCommentHandler}
          changePageNumber={changePageNumber}
          getCommentInformation={getCommentInformation}
          pageNumber={pageNumber}
          isLoading={false}
        />
        <ArticleAddComment submitCommenthandler={submitCommenthandler} />
      </div>

      <div className="therapy-footer">
        <div className="contribute-buttons">
          <Button
            type="button"
            size="size-lg"
            colorTheme="has-secondary-theme"
            onClick={() => setRatePopUp(true)}
          >
            Rate
          </Button>
          <Button
            type="button"
            size="size-lg"
            colorTheme="has-secondary-theme"
            onClick={() => setSharePopUp(true)}
          >
            Share
          </Button>
          {ratePopUp ? (
            <PopUp>
              <RatePopUp
                submitHandler={ratePopSubmitHandler}
                cancelPopUp={() => setRatePopUp(false)}
              />
            </PopUp>
          ) : (
            ""
          )}
          {sharePopUp ? (
            <PopUp>
              <SharePopUp
                title={title}
                submitHandler={sharePopSubmitHandler}
                cancelPopUp={() => setSharePopUp(false)}
              />
            </PopUp>
          ) : (
            ""
          )}
          {thankYouPopUp ? (
            <PopUp>
              <ThankYou
                msg={thankYouPopUp}
                submitHandler={() => setThankYouPopUp("")}
              />
            </PopUp>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Therapy;
