import "./LoginForm.scss";
import { Formik, Form } from "formik";
import FormikControl from "../../../../components/Form/FormikControl";
import Button from "../../../../components/Button";
import SupportMessage from "../../../../components/SupportMessage";
import { Link } from "react-router-dom";
import TextError from "../../../../components/Form/TextError";
import React, { useRef, useState } from "react";
import ReCaptcha from "react-google-recaptcha";
import {
  REGISTER,
  RESETPASSWORDREQUEST,
} from "../../../../navigation/CONSTANT";
import { loginValidationSchema } from "./loginValidationSchema";
import { loginInitialValues } from "./loginInitialValues";
import loginService from "../../../../services/login";
import { loginErrorInformation } from "./errorTypes";
import { useCookies } from "react-cookie";
import LoadingSpinner from "../../../../components/LoadingSpinner";

const LoginForm = () => {
  const reCaptchaRef = useRef<ReCaptcha>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [cookies, setCookie] = useCookies(["sessionToken"]);

  const initialValues = loginInitialValues;
  const validationSchema = loginValidationSchema;

  const onSubmit = async (values: any, actions: any) => {
    setLoading(true);
    setError("");
    const credentials = {
      email: values.email,
      password: values.password,
    };
    let reCaptchaToken;
    if (reCaptchaRef.current) {
      reCaptchaToken = await reCaptchaRef.current.executeAsync();
      reCaptchaRef.current.reset();
    }
    try {
      const response = await loginService.login(credentials, reCaptchaToken);
      const authorizeURL = loginService.generateAuthorizeURL(
        response.sessionToken
      );
      // setting the cookie for session token
      // const expires = new Date();
      // expires.setTime(expires.getTime() + response.expires_in * 1000);
      // await setCookie("sessionToken", response.sessionToken, {
      //   path: "/",
      //   expires,
      // });

      window.open(authorizeURL, "_self");
    } catch (error: any) {
      setError(
        loginErrorInformation[error?.response?.data?.errors] ||
          error?.response?.data?.errors
      );
    }
    setLoading(false);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, isValid, setFieldValue }) => (
        <section className="login-page-form">
          <div className="login-form-container">
            <Form className="login-form" onChange={() => setError("")}>
              <ReCaptcha
                ref={reCaptchaRef}
                sitekey={
                  process.env.REACT_APP_Recaptcha_Site_key
                    ? process.env.REACT_APP_Recaptcha_Site_key
                    : ""
                }
                size="invisible"
              />
              <FormikControl
                control="input"
                type="email"
                label="Email Address"
                name="email"
              />

              <FormikControl
                control="password"
                type="password"
                label="Password"
                name="password"
              />

              <Button
                type="submit"
                size="size-xxlg"
                disabled={!isValid}
                colorTheme="has-primary-theme"
              >
                Login
              </Button>
              <LoadingSpinner isLoading={isLoading} />
              {error && <TextError>{error}</TextError>}
              <Link to={RESETPASSWORDREQUEST}>Forgot your password&#63;</Link>
            </Form>
            <SupportMessage
              msg="Facing an issue? Contact Support at "
              email="medunifi@interactivepharma.com"
            />
          </div>
        </section>
      )}
    </Formik>
  );
};

export default LoginForm;
