import React, { FunctionComponent } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import UpdateProfileContent from "./UpdateProfileContent";

const UpdateProfile: FunctionComponent = () => {
  return (
    <>
      <Header />
      <UpdateProfileContent />
      <Footer />
    </>
  );
};

export default UpdateProfile;
