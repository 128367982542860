import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import SkeletonConfHeading from "../../../components/Skeleton/SkeletonConfHeading";
import { useAuth } from "../../../navigation/Auth/ProvideAuth";
import { useProvideAuth } from "../../../navigation/Auth/useProvideAuth";
import therapyService from "../../../services/therapyarea";
import { TherapyDetailsProps } from "../../../types";
import "./TherapyHeading.scss";
const initialTherapyDetails = {
  id: "",
  title: "",
  embed_video_link: "",
  pdf_link: "",
  feature_image: "",
  description: "",
  rating: 0,
  ratingsCount: 0,
  isSaved: false,
  comments: [],
  commentCount: 0,
};
const TherapyHeading = ({ slug }: { slug: string }) => {
  const { postId } = useParams<{ postId?: string }>();
  const [therapyDetails, setTherapyDetails] = useState<TherapyDetailsProps>(
    initialTherapyDetails
  );
  const { auth } = useAuth();
  const { signout } = useProvideAuth();

  const { title } = therapyDetails;

  useEffect(() => {
    const getTherapyPost = async () => {
      // get conference details
      try {
        if (!postId) return;
        const therapyPostResp = await therapyService.getTherapyModule(
          slug,
          postId,
          auth?.access_token
        );
        setTherapyDetails({ ...therapyDetails, ...therapyPostResp });
      } catch (error: any) {
        if (error?.response?.status === 401) {
          //place your reentry code
          await signout();
        }
      }
    };

    getTherapyPost();
  }, []);
  return (
    <section className="conf-heading">
      {title.length == 0 && <SkeletonConfHeading />}
      {title.length > 0 && (
        <div className="title-container">
          <h1>{title}</h1>
        </div>
      )}
    </section>
  );
};

export default TherapyHeading;
