import React, { FunctionComponent } from "react";
import "./Header.scss";
import NavigationMenu from "./NavigationMenu";

const DesktopNavigation: FunctionComponent = () => {
  return (
    <nav className="navigation navigation-desktop">
      <NavigationMenu />
    </nav>
  );
};

export default DesktopNavigation;
