import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useProvideAuth } from "../../navigation/Auth/useProvideAuth";
import Button from "../Button";
import FormikControl from "../Form/FormikControl";
import TextError from "../Form/TextError";
import LoadingSpinner from "../LoadingSpinner";
import { AddCommentInitialVal } from "./AddCommentInitialValues";
import { AddCommentValidationSchema } from "./AddCommentValidationScheme";
import { addCommentErrorInformation } from "./errorTypes";
import { prepareErrorMsg, prepareInappropriateWordList } from "./helper";

interface ArticleAddComment {
  submitCommenthandler: (commentText: string) => void;
}
const ArticleAddComment = ({ submitCommenthandler }: ArticleAddComment) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const { signout } = useProvideAuth();

  const initialValues = AddCommentInitialVal;
  const validationSchema = AddCommentValidationSchema;

  const onSubmit = async (values: any, actions: any) => {
    setLoading(true);
    setError("");
    const { commentText } = values;
    try {
      await submitCommenthandler(commentText);
      actions.resetForm();
      setLoading(false);
    } catch (error: any) {
      if (error?.response?.data?.status === "inappropriateContent") {
        setError(prepareErrorMsg(error?.response?.data?.data));
      } else {
        setError(
          addCommentErrorInformation[error?.response?.data] ||
            "Error in adding comment"
        );
      }
      setLoading(false);
      if (error?.response?.status === 401) {
        //place your reentry code
        await signout();
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, isValid, setFieldValue }) => (
        <section className="article-add-comment">
          <div className="new-comment-form-container">
            <Form className="new-comment-form" onChange={() => setError("")}>
              <FormikControl
                control="textarea"
                label="New Comment"
                name="commentText"
                required
              />
              <Button
                type="submit"
                size="size-lg"
                disabled={!isValid}
                colorTheme="has-primary-theme"
              >
                Post
              </Button>
            </Form>
            {error && <TextError>{error}</TextError>}
            <LoadingSpinner isLoading={isLoading} />
          </div>
        </section>
      )}
    </Formik>
  );
};

export default ArticleAddComment;
