import React from "react";
import { useHistory } from "react-router";
import { LOGIN } from "../../../../navigation/CONSTANT";
import "./ActionCircle.scss";

interface ActionCircleProps {
  bgType: "dark" | "red";
  children: React.ReactNode;
  title: string;
}
const ActionCircle = ({ bgType, children, title }: ActionCircleProps) => {
  const history = useHistory();
  const handleClick = () => {
    history.push(LOGIN);
  };
  return (
    <div
      className="action-circle-content"
      onClick={bgType === "red" ? handleClick : () => null}
    >
      <div className={`action-circle circle-bg-${bgType}`}>{children}</div>
      <h3 className="action-circle-title">{title}</h3>
    </div>
  );
};

export default ActionCircle;
