import React from "react";
import "./SavedModuleDisplayBoard.scss";
import { CMEDisplayCardProps } from "../../types";
import CMEDisplayCard from "../CMEDisplayCard";

interface SavedModuleDisplayBoardProps {
  modules: Array<CMEDisplayCardProps>;
}
const SavedModuleDisplayBoard = ({ modules }: SavedModuleDisplayBoardProps) => {
  return (
    <div className="saved-mod-display-board">
      {modules?.length > 0 ? (
        <>
          {modules.map((module) => (
            <CMEDisplayCard key={module.id} moduleInfo={module} />
          ))}
        </>
      ) : (
        <p className="instruction">No saved modules found</p>
      )}
    </div>
  );
};

export default SavedModuleDisplayBoard;
