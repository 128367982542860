import React from "react";
import "./AccountsHeading.scss";
const AccountsHeading = () => {
  return (
    <section className="accounts-heading">
      <div className="title-container">
        <h1>My Account</h1>
      </div>
    </section>
  );
};

export default AccountsHeading;
