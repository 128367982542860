import React, { FunctionComponent, ReactChild } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import CookiePolicyContent from "./CookiePolicyContent";

const CookiePolicy = () => {
  return (
    <>
      <Header />
      <CookiePolicyContent />
      <Footer />
    </>
  );
};

export default CookiePolicy;
