import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;
const getUsersURL = "/user/all";
const getUserById = "/user";
const searchUserURL = "/user/search";

const getAllCommunityMember = async (accessToken: string, page: number) => {
  const response = await axios.get(baseUrl + getUsersURL, {
    params: {
      limit: 6,
      page,
    },
    headers: { "takeda-access-token": accessToken },
  });
  return response.data;
};
const getCommunityMemberById = async (
  accessToken: string,
  profileId: number | null | undefined
) => {
  if (!profileId) return;
  const response = await axios.get(baseUrl + getUserById + "/" + profileId, {
    headers: { "takeda-access-token": accessToken },
  });
  return response.data;
};

const searchUser = async (
  accessToken: string,
  searchQuery: string,
  type: string
) => {
  if (!searchQuery) return;
  const response = await axios.get(baseUrl + searchUserURL, {
    params: {
      type,
      q: searchQuery,
    },
    headers: { "takeda-access-token": accessToken },
  });
  return response.data;
};

const communityMemService = {
  getAllCommunityMember,
  getCommunityMemberById,
  searchUser,
};

export default communityMemService;
