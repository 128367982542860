import React from "react";
import "./PrivacyPolicyBody.scss";

const PrivacyPolicyBody = () => {
  return (
    <div className="priv-policy-body">
      <div className="content">
        <p>
          Takeda recognizes and respects the privacy rights of individuals with
          regard to their personal data. This Privacy Notice (“Notice”) explains
          what type of personal data we may collect about you and how we use it.
        </p>
        <p>
          Your privacy is important to us. If you have any questions concerning
          Takeda’s privacy practices or wish to exercise your rights relating to
          personal data that Takeda has collected about you, please contact us
          as described in the “How to Contact Us” section below.
        </p>
        <h3>Applicability and Organizations Covered by This Privacy Notice</h3>
        <p>
          Takeda is a group of companies with operations globally. The Takeda
          enterprise (“Takeda”, “we”, “us”, “our”) includes the parent company
          Takeda Pharmaceutical Company Limited, and its afﬁliated entities. For
          contact information related to your local afﬁliate, please refer to
          Takeda’s website at
          <a
            href="https://www.takeda.com/who-we-are/company-information/worldwide-ofﬁces/"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            https://www.takeda.com/who-we-are/company-information/worldwide-ofﬁces/.
          </a>
        </p>
        <p>
          This Notice applies to Takeda websites, as well as other Takeda
          services and activities that display or reference this Notice
          (together “Services”). Any person accessing, browsing or otherwise
          using the Services, either manually or via an automated device or
          program, shall be considered a “User.”
        </p>
        <h3>Personal Data We Collect and Use</h3>
        <p>
          Takeda collects personal data to operate effectively and provide you
          with the best experiences from our Services.
        </p>
        <p>
          Takeda may collect information directly from you through your
          interactions with us when you sign up for a Service, enter into a
          contract with us, submit an application to us, and through various
          other engagements we may have with you. We may use technologies that
          automatically collect information when you visit our sites, view our
          advertisements, or use our products or services.
        </p>
        <p>
          We also may collect information about you from third parties, such as
          our vendors, suppliers, contractors, and business partners. For
          example, we may use such third-party data to conﬁrm contact or
          ﬁnancial information, to verify qualiﬁcations of healthcare
          professionals and to check references.
        </p>
        <p>
          The type of personal data we collect depends on the interactions you
          have with Takeda and the Takeda Services you use. The speciﬁc type of
          personal data we process about you will be outlined in the speciﬁc
          privacy notice that you receive from us. If you are simply browsing
          one of Takeda websites, we would only process limited types of
          personal data such as:
        </p>
        <p>
          • Name and contact data: We may collect your name, postal address,
          email address, telephone number and other similar contact data if you
          voluntarily provide this to us.
        </p>
        <p>
          • Usage data: We may collect data about how you and your device
          interact with our Services, such as Internet protocol (IP) address
          used to connect your computer to the Internet, your login information,
          browser type and version, time zone setting, browser-plug-in types and
          versions, operating system and platform; Information about your visit
          and online activities (e.g. page response times, download errors,
          length of visits to certain pages, page interaction information (such
          as scrolling, clicks, and mouse-overs), and methods used to browse
          away from the page.)
        </p>
        <p>
          • Relationship and interaction data: We may collect data about our
          interactions and meetings, such as when you contact us for information
          and support.
        </p>
        <p>
          • Location data: We may collect imprecise data such as. a location
          derived from your IP address or data that indicates where you are
          located with less precision, such as at a city or postcode level.
        </p>
        <p>
          • Legally Required Information: We may collect additional data about
          you that is related to patient safety and adverse events, or that may
          be required by laws that apply to Takeda. In some cases, and only for
          certain types of services, we may enhance the information we hold
          about individuals with information we receive from third parties and
          with information which is publicly or commercially available and/or
          which is obtained by any other legal means.
        </p>
        <p>
          For example, we may collect publicly available information, including
          online postings, social media platforms, publication databases,
          journals, and societies. Please note that any information you post or
          disclose through these services may become publicly available
          information to users of the service you provided it to, and to the
          general public. We urge you to be very careful when deciding to
          disclose your personal data, or any other information, in these
          forums, and to carefully review and familiarize yourself with
          applicable privacy settings and options.
        </p>
        <h3>How We Use Personal Data</h3>
        <p>
          Depending on your relationship with Takeda, Takeda uses the personal
          data we collect for a number of basic purposes, described in more
          detail below: (1) for business operations (marketing and sales,
          research and development, patient support, donations and sponsorships,
          communications), (2) for business administration (ﬁnance and
          accounting, human resources, prevention and investigatory activities),
          (3) for business management (internal audit, asset management, system
          and business controls), and (4) as necessary to protect the health,
          safety, and security of Takeda personnel and to comply with legal
          requirements and obligations. Takeda does not sell your personal data.
        </p>
        <p>
          Legal Basis for Processing Personal Data When Takeda engages in the
          processing of your personal data, we will only do so if this is
          allowed, also referred to as having a legal basis to process your
          personal data. The legal bases Takeda would rely on include:
        </p>
        <p>
          • when we have a contract or agreement with you – for example, a
          contract to supply goods or services (i.e. when you engage with us to
          visit a Takeda organized event, or order information on-line), or an
          employee contract;
        </p>
        <p>
          • when we must comply with a legal obligation – for example, when
          processing your data is a legal requirement, for example in some
          countries there are transparency requirements regarding interaction
          with healthcare professionals, or to provide information to
          authorities in case of adverse events related to use of our products;
        </p>
        <p>
          • when data processing is in your vital interests – for example, when
          this might protect your life;
        </p>
        <p>
          • when processing your data for our legitimate interests as a
          pharmaceutical company in compliance with our corporate policies – for
          example, to communicate with you about our products and services,
          about scientiﬁc research and educational opportunities, scientiﬁc and
          market research surveys, as well as for the effective continuation of
          our business operations.
        </p>
        <p>
          In all situations where there is no other legal basis, Takeda will ask
          for your agreement (known as “consent”) as legal basis to process your
          personal data.
        </p>
        <p>
          Note that if you provide your consent, you may also atany time
          withdraw your consent by contacting us as described in the “How to
          Contact Us” section. It is also important to know that you have other
          rights regarding the processing of your personal data, see the section
          on “Your rights related to your personal data”.
        </p>
        <h3>Who We Share Personal Data With</h3>
        <p>
          We share data with Takeda-controlled afﬁliates and subsidiaries; with
          vendors working on our behalf; when required by law or to respond to
          legal process; to protect our customers; to protect lives; to maintain
          the security of our Services; and to protect the rights or property of
          Takeda. In such cases, Takeda will require these third parties to
          protect the conﬁdentialityand security of the personal data that is
          shared with them. These third parties will be required to agree that
          they will not use or disclose personal data about you except as
          necessary to provide services to us or perform services on our behalf,
          or as necessary to comply with applicable laws or regulations. Takeda
          may share your personal data with afﬁliates and third parties as
          follows:
        </p>
        <p>
          • Among afﬁliates for the purposes described in this Notice. Takeda is
          the party responsible for the management of the jointly used personal
          data.
        </p>
        <p>
          • To our third-party service providers that provide services such as
          website hosting, data analysis, payment processing, order fulﬁllment,
          information technology andrelated infrastructure provision, customer
          service, email delivery, auditing and other similar services. Takeda
          policy requires that our service providers adhere to appropriate
          restrictions on access and use of your personal data.
        </p>
        <p>
          • To third parties to allow them to send marketing communications.
        </p>
        <p>
          • To third-party sponsors of sweepstakes, contests and similar
          promotions.
        </p>
        <p>
          We may also disclose your personal data in the following
          circumstances:
        </p>
        <p>
          • In the event of a merger, reorganization, acquisition, joint
          venture, assignment, spin-off, transfer or sale or disposition of all
          or any portion of our business, including in connection with any
          bankruptcy or similar proceedings. As required by law, including laws
          outside your country of residence, to comply with a subpoena, required
          registration, or legal process.
        </p>
        <p>
          We may also disclose aggregate or de-identiﬁed data that is not
          personally identiﬁable to third parties. Aggregate data is created by
          collecting and processing information about individuals and
          summarizing the data, eliminating the possibility to identify an
          individual.
        </p>
        <h3>How Do We Protect Your Personal Data</h3>
        <p>
          We have implemented a variety of security technologies and
          organizational procedures to protect your personal data from
          unauthorized access, use and disclosure. For example, we store your
          personal data on computer systems that have various types of technical
          and physical access controls, such as encryption. Takeda has
          implemented data security controls consistent with industry standards;
          however, Takeda cannot guarantee the security of your information. It
          is also important for you to protect against unauthorized access to
          your password(s) and your computer, mobile devices, etc. If you have
          reason to believe that your interaction with us is no longer secure
          (for example, if you feel that the security of any account you might
          have with us has been compromised), please notify us immediately as
          described in the “How to Contact Us” section below.
        </p>
        <h3>Where We Store and Process Personal Data</h3>
        <p>
          As Takeda is a multi-national organization with locations in many
          countries around the world, your personal data may be stored and
          processed in any country in which we operate, including where we have
          facilities, or in which we engage service providers. Takeda takes
          steps to process personal data according to the provisions of this
          Notice and the requirements of applicable law.
        </p>
        <p>
          Takeda may transfer personal data to countries outside of your country
          of residence. The laws ofthe receiving countries may not provide as
          stringent protections for personal data as your country of residence.
          In instances where a lesser level of protection is provided by a
          receiving country, Takeda undertakes that adequate safeguards are in
          place and that applicable laws and regulations are complied with in
          connection with such transfers.
        </p>
        <p>
          In certain cases, Takeda undertakes to enter into contractual
          agreements (e.g., European Union Standard Contractual Clauses), or
          relies on other available data transfer mechanisms that aim to provide
          adequate protections. A copy of the EU Standard Contractual Clauses
          boilerplate contracts may be found at:
          <a
            href="http://ec.europa.eu/justice/data-protection/international-transfers/transfer/"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            http://ec.europa.eu/justice/data-protection/international-transfers/transfer/.
          </a>
          To obtain additional information regarding the safeguards that Takeda
          has put in place to govern cross-border transfers of personal data,
          please contact us using the information provided in the “How to
          Contact Us” section.
        </p>
        <h3>Retention Period</h3>
        <p>
          Takeda will retain your personal data only for the minimum period
          necessary to fulﬁll the purposes outlined in this Notice unless a
          longer retention period is required or permitted by law.
        </p>
        <h3>Your Rights Related to Your Personal Data</h3>

        <p>
          You have choices about the data we collect. When you are asked to
          share your personal data with Takeda, you may decline; however, your
          choice not to share your personal data with Takeda may mean you will
          not be able to use or (fully) beneﬁt from our Services, features or
          offerings.
        </p>

        <p>
          Takeda respects your right, or where permitted by law, your authorized
          agent, to know and inquire about what personal data we have collected,
          used or disclosed. In addition, you or your authorized agent, have the
          right to request correction or deletion of such personal data, as well
          as to request removal of your personal data held by third parties with
          whom we conduct business. Takeda will not discriminate against you
          based on your exercise of any of these rights.
        </p>

        <p>
          For more information about your privacy rights, or if you are not able
          to resolve a problem directly with us and wish to make a complaint,
          please contact the relevant data protection authority that is
          responsible for making sure that privacy laws are followed in your
          country of residence. Individuals residing in the EEA may ﬁnd a list
          of their local data protection authorities at
          <a
            href="https://edpb.europa.eu/about-edpb/board/members_en"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            https://edpb.europa.eu/about-edpb/board/members_en.
          </a>
          For further details on other jurisdictions’ data protection
          authorities, please contact us as described in the “How to Contact Us”
          section below.
        </p>

        <p>
          If you would like to make a request for Takeda to access, correct or
          delete personal data that you have provided to Takeda, please contact
          us as described in the “How to Contact Us” section below, andwe will
          respond in a reasonable time. We will make a good faith effort to
          provide you with access to your personal data and to correct any
          inaccuracies or delete such information at your request if it is not
          otherwise required to be retained by law or under other legally
          permitted exceptions. If for any reason your request is declined, we
          will notify you. Before fulﬁlling your requests we may require
          additional information from you in order to verify your identity.
        </p>

        <p>
          Takeda will inform you if it intends to use your data for marketing
          purposes and to send marketing communications to you or if it intends
          to disclose your information to any third party for such purposes. You
          have the right to opt out from marketing communications that are being
          sent to you by Takeda. You can exercise your right to prevent such
          processing by informing Takeda at any time that you do not wish to
          receive such marketing. Where you wish to exercise this right, please
          use the “unsubscribe” functionality in the relevant communications or
          refer to the “How to Contact Us” section below.
        </p>

        <p>
          If you would like to make a request for Takeda to access, correct or
          delete personal data that you have provided to Takeda, please contact
          us as described in the “How to Contact Us” section below, andwe will
          respond in a reasonable time. We will make a good faith effort to
          provide you with access to your personal data and to correct any
          inaccuracies or delete such information at your request if it is not
          otherwise required to be retained by law or under other legally
          permitted exceptions. If for any reason your request is declined, we
          will notify you. Before fulﬁlling your requests we may require
          additional information from you in order to verify your identity.
        </p>

        <p>
          Takeda will inform you if it intends to use your data for marketing
          purposes and to send marketing communications to you or if it intends
          to disclose your information to any third party for such purposes. You
          have the right to opt out from marketing communications that are being
          sent to you by Takeda. You can exercise your right to prevent such
          processing by informing Takeda at any time that you do not wish to
          receive such marketing. Where you wish to exercise this right, please
          use the “unsubscribe” functionality in the relevant communications or
          refer to the “How to Contact Us” section below.
        </p>

        <p>
          Where necessary, Takeda may contact you to collect further information
          on the reported adverse event or product complaint and may share
          personal data about you with third parties including regulatory
          agencies, other health authorities and service providers that Takeda
          contracts with to perform activities or functions related to the
          purposes speciﬁed above on behalf of Takeda. These service providers
          may include translation service providers, Takeda product partners,
          healthcare professionals, pharmacovigilance service providers (such as
          pharmacovigilance services vendors, pharmacovigilance call centers,
          digital media and/or mobile app vendors, CRO study management vendors,
          archiving vendors, pharmacovigilance auditing vendors, market research
          and/or patient support program suppliers) or medical party services or
          call centers.
        </p>

        <p>
          The sharing of your personal data may involve transfers to other
          countries. The data protection and privacy laws in these countries may
          not provide the same level of protection as your home country. Takeda
          has put in place safeguards to adequately protect your personal data
          in connection with such transfers.
        </p>

        <p>
          Takeda will take reasonable and appropriate physical, administrative
          and technical safeguards to protect the personal data about you from
          loss, misuse, unauthorized access, disclosure, alteration or
          destruction.
        </p>

        <p>
          We will keep your personal data for the period according to applicable
          legislation relating to pharmacovigilance, i.e., for the lifetime of
          the Takeda product in question plus additional 10 years (as a
          minimum). However, the retention period may be longer where required
          by national law.
        </p>

        <p>
          If you have any questions about this Privacy Notice, please contact us
          by email at{" "}
          <a
            href="mailto:privacyoffice@takeda.com"
            target="_blank"
            rel="noreferrer"
          >
            privacyoffice@takeda.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicyBody;
