export const extractFormat = (videoLinks: string, pdfLinks: string): string => {
  if (videoLinks && pdfLinks) {
    return "Video and PDF";
  } else if (videoLinks) {
    return "Video";
  } else if (pdfLinks) {
    return "PDF";
  } else {
    return "No media";
  }
};
