import "./RegistrationContent.scss";
import React, { FunctionComponent } from "react";
import RegistrationForm from "./RegistrationForm";
import RegistrationHeading from "./RegistrationHeading";
const RegistrationContent: FunctionComponent = () => {
  return (
    <main className="registration-content">
      <RegistrationHeading />
      <RegistrationForm />
    </main>
  );
};

export default RegistrationContent;
