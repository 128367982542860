export const countryTypes = [
  { value: "BH", key: "Bahrain" },
  { value: "EG", key: "Egypt" },
  { value: "KW", key: "Kuwait" },
  { value: "LB", key: "Lebanon" },
  { value: "OM", key: "Oman" },
  { value: "QA", key: "Qatar" },
  { value: "SA", key: "Saudi Arabia" },
  { value: "AE", key: "United Arab Emirates" },
];
