import React, { FunctionComponent, useEffect } from "react";
import { css } from "@emotion/react";
import { ReactComponent as CommentIcon } from "../../assets/images/comment.svg";
import CommentCard from "../CommentCard";
import SyncLoaderSpinner from "../SyncLoader";

interface ArticleInteractionProps {
  commentCount: number;
  comments: Array<any>;
  deleteCommentHandler: (
    commentId: number,
    replyId?: number | undefined
  ) => void;
  submitReplyHandler: (commentText: string, commentId: number) => void;
  changePageNumber: (change: number) => void;
  getCommentInformation: (pageNumber: number) => void;
  pageNumber: number;
  isLoading: boolean;
}

const ArticleInteraction: FunctionComponent<ArticleInteractionProps> = ({
  commentCount,
  comments,
  deleteCommentHandler,
  submitReplyHandler,
  changePageNumber,
  getCommentInformation,
  pageNumber,
  isLoading,
}: ArticleInteractionProps) => {
  const loaderStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;
  `;
  useEffect(() => {
    const getCommentInfo = async () => {
      await getCommentInformation(pageNumber);
    };

    getCommentInfo();
  }, [pageNumber]);

  return (
    <div className="article-interaction">
      <div className="comment-count">
        <CommentIcon />
        <p>
          <span>{commentCount} comments</span>
        </p>
      </div>
      {isLoading ? (
        <SyncLoaderSpinner isLoading={isLoading} override={loaderStyle} />
      ) : (
        <div className="comments">
          {pageNumber > 1 && (
            <div className="view-recent-comments">
              <p onClick={() => changePageNumber(-1)}>View recent comments</p>
            </div>
          )}

          {comments?.length > 0 ? (
            comments.map((comment: any) => (
              <CommentCard
                key={comment.id}
                commentDetails={comment}
                submitReplyHandler={submitReplyHandler}
                deleteCommentHandler={deleteCommentHandler}
              />
            ))
          ) : (
            <p className="instruction"> No comments found</p>
          )}

          {pageNumber * 5 < commentCount && (
            <div className="view-all-comments">
              <p onClick={() => changePageNumber(1)}>View more comments</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ArticleInteraction;
