import React, { ReactNode } from "react";
import "./Button.scss";

interface ButtonType {
  type: "button" | "submit";
  size: string;
  colorTheme: "has-primary-theme" | "has-secondary-theme";
  children: ReactNode;
  [propName: string]: any;
}

function Button({ type, size, colorTheme, children, ...rest }: ButtonType) {
  return (
    <button className={`btn  ${size} ${colorTheme}`} type={type} {...rest}>
      {children}
    </button>
  );
}

export default Button;
