import React, { FunctionComponent } from "react";
import Button from "../Button";
import defaultAvatar from "../../assets/images/default-avatar.png";
import "./CommunityMemberCard.scss";
import { ProfileInfoProps } from "../../types";
import { useHistory } from "react-router-dom";
import {
  MESSAGEBASE,
  VIEWPROFILE,
  VIEWPROFILEBASE,
} from "../../navigation/CONSTANT";

const CommunityMemberCard = ({
  profileInfo,
}: {
  profileInfo: ProfileInfoProps;
}) => {
  const history = useHistory();
  const {
    id,
    takedaId,
    title,
    firstName,
    lastName,
    region,
    organisation,
    speciality,
    seniority,
    professionalNumber,
    image,
    email,
    mobileNumber,
  } = profileInfo;
  const memberInfo: any = {
    role: seniority,
    speciality,
    region,
    organisation,
  };

  const viewProfile = () => {
    history.push(VIEWPROFILEBASE + "/" + id);
  };

  const messageUser = () => {
    history.push(MESSAGEBASE + "/" + id);
  };

  const fullName = [title, firstName, lastName].join(" ");
  return (
    <div className="community-member-card">
      <div className="upper-strip">
        <div className="member-basic-info">
          <div className="profile-img-container">
            <img src={image || defaultAvatar} alt={fullName} />
          </div>
          <div className="profile-basic-info">
            <h3>{fullName}</h3>
            {/* <div className="profile-email-number">
              <span>{email}</span>
              <span>|</span>
              <span>{mobileNumber || 1234567890}</span>
            </div> */}
          </div>
        </div>
        <div className="profile-act-btns">
          <div className="view-profile-btn">
            <Button
              type="button"
              size="size-lg"
              colorTheme="has-secondary-theme"
              onClick={viewProfile}
            >
              View Profile
            </Button>
          </div>
          <div className="contact-btn">
            <Button
              type="button"
              size="size-lg"
              colorTheme="has-secondary-theme"
              onClick={messageUser}
            >
              Contact
            </Button>
          </div>
        </div>
      </div>
      <div className="lower-strip">
        {Object.keys(memberInfo).map((m, idx) => {
          if (!memberInfo[m]) return;
          return (
            <div key={m} className="member-tag">
              <p>
                {m.charAt(0).toUpperCase() + m.slice(1)}: {memberInfo[m]}
              </p>
              {idx < 3 ? <div className="space-breaker">|</div> : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CommunityMemberCard;
