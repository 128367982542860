import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ConferenceBoard from "../../../components/ConferenceBoard";
import ConferenceHeader from "../../../components/ConferenceHeader";
import Pagination from "../../../components/Pagination";
import { css } from "@emotion/react";
import { useConferenceContext } from "../../../context/Conferences";
import { useAuth } from "../../../navigation/Auth/ProvideAuth";
import conferenceService from "../../../services/conferences";
import { sortByValues } from "../../../types";
import "./ConfHighlightMenu.scss";
import SyncLoaderSpinner from "../../../components/SyncLoader";

const ConfHighlightMenu = () => {
  const { auth } = useAuth();
  const { conferences, setConferences } = useConferenceContext();
  const [confFilter, setConfFilter] = useState<sortByValues>("added");
  const [activePage, setActivePage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const loaderStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  `;

  const setPageNumber = (pageNumber: number) => {
    setActivePage(pageNumber);
  };

  useEffect(() => {
    const getAllConferences = async () => {
      setLoading(true);
      const conferencesResp = await conferenceService.getAllConferences(
        activePage,
        auth?.access_token,
        confFilter
      );

      setConferences({
        type: "SET_CONFERENCES",
        payload: conferencesResp,
      });
      setLoading(false);
    };
    try {
      getAllConferences();
    } catch (error: any) {
      setLoading(false);
    }
  }, [confFilter, activePage]);
  return (
    <div className="conf-menu">
      <ConferenceHeader
        filter={confFilter}
        changeFilter={(value: sortByValues) => setConfFilter(value)}
      />
      {loading ? (
        <SyncLoaderSpinner isLoading={loading} override={loaderStyle} />
      ) : (
        <>
          <ConferenceBoard conferences={conferences.data} />
          <Pagination
            paginationDetails={conferences?.meta?.pagination}
            setPageNumber={setPageNumber}
          />
        </>
      )}
    </div>
  );
};

export default ConfHighlightMenu;
