import React, { FunctionComponent } from "react";
const TermsAndCondHeader = () => {
  return (
    <section className="terms-cond-header">
      <h1>Terms of use</h1>
    </section>
  );
};

export default TermsAndCondHeader;
