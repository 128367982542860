import { Form, Formik } from "formik";
import React, { FunctionComponent, useEffect, useState } from "react";
import Button from "../../../../../components/Button";
import FormikControl from "../../../../../components/Form/FormikControl";
import TextError from "../../../../../components/Form/TextError";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import { bookmarkedCheckbox } from "./bookmarkedCheckbox";
import "./FilterForm.scss";
import { filterInitialValues } from "./filterInitialValues";
import { filterValidationSchema } from "./filterValidationScheme";
import { OptionProp, SearchProps } from "../../../../../types";
import educationService from "../../../../../services/cmemodules";
import { useAuth } from "../../../../../navigation/Auth/ProvideAuth";
import { prepareTopics } from "../helper";
import { useProvideAuth } from "../../../../../navigation/Auth/useProvideAuth";
interface FilterFormProps {
  handleSearch: (searchDetails: SearchProps) => void;
}

const FilterForm: FunctionComponent<FilterFormProps> = ({
  handleSearch,
}: FilterFormProps) => {
  const { auth } = useAuth();
  const [isLoading, setLoading] = useState<boolean>(false);
  const { signout } = useProvideAuth();

  const [error, setError] = useState<string | undefined>();
  const [topicOptions, setTopicOptions] = useState<Array<OptionProp>>([
    { value: "", key: "Select..." },
  ]);

  const bookmarked = bookmarkedCheckbox;

  const initialValues = filterInitialValues;
  const validationSchema = filterValidationSchema;

  useEffect(() => {
    const getTopic = async () => {
      try {
        const topicsResp = await educationService.getEducationModuleTopics(
          auth?.access_token
        );
        setTopicOptions(prepareTopics(topicsResp));
      } catch (error: any) {
        if (error?.response?.status === 401) {
          //place your reentry code
          await signout();
        }
      }
    };

    getTopic();
  }, []);

  const onSubmit = async (values: any, actions: any) => {
    setLoading(true);
    await handleSearch({
      name: values?.name,
      topic: values?.topic,
      isBookmarked: values?.bookmarked?.length > 0,
    });
    setLoading(false);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, isValid, setFieldValue }) => (
        <section className="filter-mod-page-form">
          <div className="filter-mod-form-container">
            <h3 className="form-title">Filter</h3>
            <Form className="filter-mod-form">
              <FormikControl
                control="input"
                type="text"
                label="Name"
                name="name"
              />
              <FormikControl
                control="select"
                label="Topic"
                name="topic"
                options={topicOptions}
              />
              <FormikControl
                control="checkbox"
                label=""
                name="bookmarked"
                options={bookmarked}
              />

              <Button
                type="submit"
                size="size-xxlg"
                disabled={!isValid}
                colorTheme="has-primary-theme"
              >
                Search
              </Button>
              {error && <TextError>{error}</TextError>}
            </Form>
            <LoadingSpinner isLoading={isLoading} />
          </div>
        </section>
      )}
    </Formik>
  );
};

export default FilterForm;
