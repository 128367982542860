import React, { FunctionComponent } from "react";
import { ReactComponent as ConfPlay } from "../../assets/images/confplay.svg";
import { ReactComponent as Confdoc } from "../../assets/images/confdoc.svg";
import Rating from "../Rating";
import { TherapyCardType } from "../../types";
import "./TherapyCard.scss";
import { useHistory } from "react-router";

const TherapyCard: FunctionComponent<TherapyCardType> = ({
  id,
  title,
  feature_image,
  rating,
  embed_video_link,
  pdf_link,
  slug,
}: TherapyCardType) => {
  const history = useHistory();
  const viewPostLink = `/community/education/${slug}`;
  const handleClick = () => {
    history.push(viewPostLink + "/" + id);
  };
  return (
    <div className="therapy-card" onClick={handleClick}>
      <div className="therapy-card-info">
        <div className="therapy-card-thumbnail">
          <img src={feature_image} alt={title} />
        </div>
        <h3 className="therapy-card-title">{title}</h3>
        {/* <p className="therapy-card-content">{description}</p> */}
      </div>

      <div className="therapy-card-action">
        <div className="therapy-card-files">
          {pdf_link && (
            <div className="therapy-card-doc">
              <Confdoc />
            </div>
          )}
          {embed_video_link && (
            <div className="therapy-card-video">
              <ConfPlay />
            </div>
          )}
        </div>
        <Rating rating={rating ? rating : 0} />
      </div>
    </div>
  );
};

export default TherapyCard;
