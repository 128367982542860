import React, { FunctionComponent, useState } from "react";
import FilterForm from "./FilterForm";
import FilterResult from "./FilterResult";
import "./FilterModulesBody.scss";
import { SearchProps } from "../../../../types";
import { searchEducationModules, extractEducationModule } from "./helper";

const FilterModulesBody: FunctionComponent = () => {
  const [filterResult, setFilterResult] = useState<any>([]);
  const [isBookmarked, setIsBookmarked] = useState<boolean>(false);
  const handleSearch = async (searchDetails: SearchProps) => {
    const { name, topic, isBookmarked } = searchDetails;
    const result = await searchEducationModules(name, topic);
    setFilterResult(extractEducationModule(result));
    setIsBookmarked(isBookmarked);
  };
  return (
    <section className="filter-mod-page-body">
      <FilterForm handleSearch={handleSearch} />
      <FilterResult
        filterResult={filterResult}
        showOnlyBookmarked={isBookmarked}
      />
    </section>
  );
};

export default FilterModulesBody;
