export const registrationInitialValues = {
  image: null,
  title: "",
  firstName: "",
  lastName: "",
  seniority: "",
  speciality: "",
  region: "",
  organisation: "",
  professionalNumber: "",
  mobileNumber: "",
};
