import "./ReportingMenu.scss";
import React, { useEffect, useState } from "react";
import reportStatService from "../../../services/report";
import { useProvideAuth } from "../../../navigation/Auth/useProvideAuth";
import { useAuth } from "../../../navigation/Auth/ProvideAuth";
const initialReport = [
  { label: "Registered User", value: "-" },
  { label: "Verified User (Takeda)", value: "-" },
  { label: "Verified User (Non Takeda)", value: "-" },
  { label: "Unique visits", value: "-" },
  { label: "Duration of visits", value: "-" },
  { label: "Active Users", value: "-" },
  {
    label: "Currently Logged Users",
    value: "-",
  },
  { label: "Currently Logged Users", value: "-" },
  { label: "No. of resources accessed", value: "-" },
  { label: "Messages", value: "-" },
  { label: "Comments", value: "-" },
  { label: "Article Shares", value: "-" },
  { label: "Invites to Non members to Join to Community", value: "-" },
];

interface ReportMetric {
  label: string;
  value: number | string;
}
const ReportingMenu = () => {
  const [reports, setReports] = useState<Array<ReportMetric>>(initialReport);
  const { auth } = useAuth();
  const { signout } = useProvideAuth();

  useEffect(() => {
    const getReport = async () => {
      try {
        let prepareReport: Array<ReportMetric> = [];
        const registeredUserResp =
          await reportStatService.getRegisteredUserCount(
            auth?.access_token,
            false
          );
        const adminReportParameters =
          await reportStatService.getAdminReportParameters(auth?.access_token);
        const gaResp = await reportStatService.getGoogleAnalyticsReport(
          auth?.access_token
        );
        const currentLoggedResp =
          await reportStatService.getCurrentlyLoggedUser(auth?.access_token);

        prepareReport = [
          ...prepareReport,
          { label: "Registered users", value: registeredUserResp?.count },
          {
            label: "Verified users (Takeda)",
            value: adminReportParameters?.usersWithTakedaEmail,
          },
          {
            label: "Verified users (non Takeda)",
            value: adminReportParameters?.usersWithNonTakedaEmail,
          },
          { label: "Unique visits", value: gaResp?.right?.sessions },
          {
            label: "Duration of visits",
            value:
              (Number(gaResp?.right?.averageSessionDuration) / 60).toFixed(2) +
              " min",
          },
          // { label: "Active Users", value: gaResp?.right?.activeUsers },
          {
            label: "Currently logged users",
            value: currentLoggedResp ? currentLoggedResp.length : 0,
          },
          {
            label: "No. of resources accessed",
            value: adminReportParameters?.resourcesAccessedCount,
          },
          {
            label: "Messages",
            value: adminReportParameters?.totalMessagesCount,
          },
          {
            label: "Comments",
            value: adminReportParameters?.totalCommentsCount,
          },
          {
            label: "Shares",
            value: adminReportParameters?.totalArticleSharesCount,
          },
          {
            label: "Invites to non members to join to community",
            value: adminReportParameters?.usersInvitesCount,
          },
        ];

        setReports([...prepareReport]);
      } catch (error: any) {
        if (error?.response?.status === 401) {
          //place your reentry code
          await signout();
        }
      }
    };

    getReport();
  }, []);

  return (
    <section className="reporting-menu">
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Metric</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report, idx) => (
              <tr key={"report" + idx}>
                <td>{report.label}</td>
                <td>{report.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default ReportingMenu;
