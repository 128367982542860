import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../../components/Button";
import { signOutUser } from "../../../../helper";
import { useAuth } from "../../../../navigation/Auth/ProvideAuth";
import { useProvideAuth } from "../../../../navigation/Auth/useProvideAuth";
import {
  CHANGEPASSWORD,
  LOGOUT,
  UPDATEPROFILE,
} from "../../../../navigation/CONSTANT";

const AccountAction = () => {
  const { auth } = useAuth();
  const { signout } = useProvideAuth();
  const handleClick = async () => {
    await signOutUser(auth?.access_token, auth?.id_token);
    await signout();
  };
  const history = useHistory();
  return (
    <div className="account-actions">
      <hr />
      <div className="action-btn-container">
        <Button
          type="button"
          size="size-lg"
          colorTheme="has-secondary-theme"
          onClick={() => history.push(UPDATEPROFILE)}
        >
          Edit Profile
        </Button>
        <Button
          type="button"
          size="size-lg"
          colorTheme="has-secondary-theme"
          onClick={() => history.push(CHANGEPASSWORD)}
        >
          Change Password
        </Button>
        <Button
          type="button"
          size="size-lg"
          colorTheme="has-secondary-theme"
          onClick={handleClick}
        >
          Log out
        </Button>
      </div>
    </div>
  );
};

export default AccountAction;
