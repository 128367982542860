import React from "react";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter as Router } from "react-router-dom";
import { ActPreferenceProvider } from "./context/ActivityPreference";
import { ConferenceProvider } from "./context/Conferences";
import { LoggedUserProvider } from "./context/LoggedUser";
import { NewsProvider } from "./context/News";
import { ProvideAuth } from "./navigation/Auth/ProvideAuth";
import RouterConfig from "./navigation/RouterConfig";

const App = () => {
  return (
    <CookiesProvider>
      <ProvideAuth>
        <NewsProvider>
          <ConferenceProvider>
            <ActPreferenceProvider>
              <LoggedUserProvider>
                <Router>
                  <RouterConfig />
                </Router>
              </LoggedUserProvider>
            </ActPreferenceProvider>
          </ConferenceProvider>
        </NewsProvider>
      </ProvideAuth>
    </CookiesProvider>
  );
};

export default App;
