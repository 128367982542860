import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import {
  CLINICALNEWS,
  JOURNALWATCH,
  OTHERRESOURCES,
} from "../../navigation/CONSTANT";
import Button from "../Button";

import "./ServiceCard.scss";
import { ServiceCardProps } from "../../types";

const ServiceCard: FunctionComponent<ServiceCardProps> = ({
  title,
  cardLink,
  cardImage,
  subTitle,
  categories,
}: ServiceCardProps) => {
  return (
    <div className="service-card">
      <h3 className="card-title">{title}</h3>
      <div className="card-body">
        <div className="card-img-container">
          <img src={cardImage} alt="information image" />
        </div>

        <h4 className="card-body-title">{subTitle}</h4>
        <div className="card-body-content">
          {categories.map((category, index) => (
            <div key={"service" + index} className="btn-group">
              <Button
                type="button"
                size="size-lg"
                colorTheme="has-secondary-theme"
              >
                {category.categoryTitle}
              </Button>
              <Link to={category.categoryLink}>
                <Button
                  type="button"
                  size="size-lg"
                  colorTheme="has-secondary-theme"
                >
                  View all
                </Button>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="card-footer">
        <Link to={cardLink}>
          <Button type="button" size="size-lg" colorTheme="has-secondary-theme">
            View All
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default ServiceCard;
