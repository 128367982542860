import React from "react";
import "./Modal.scss";
const Modal = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="modal">
      <div className="inner-modal">{children}</div>
    </div>
  );
};

export default Modal;
