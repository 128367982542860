import { Form, Formik } from "formik";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../navigation/Auth/ProvideAuth";
import { useProvideAuth } from "../../navigation/Auth/useProvideAuth";
import messageService from "../../services/messages";
import Button from "../Button";
import CancelPopUpSVG from "../CancelPopUpSVG";
import FormikControl from "../Form/FormikControl";
import TextError from "../Form/TextError";
import LoadingSpinner from "../LoadingSpinner";
import { blockedCheckbox } from "./blockedCheckbox";
import { reportMessageInitialValues } from "./reportMessageInitialValues";
import "./ReportMessagePopUp.scss";
import { reportMessageValidationSchema } from "./reportMessageValidationScheme";

const ReportMessagePopUp = ({
  memberEmail,
  cancelPopUp,
  submitHandler,
}: {
  memberEmail: string;
  cancelPopUp: () => void;
  submitHandler: (block: boolean) => void;
}) => {
  const { auth } = useAuth();
  const { signout } = useProvideAuth();
  const [isLoading, setLoading] = useState<boolean>(false);
  const { recipientId } = useParams<{ recipientId?: string }>();
  const [error, setError] = useState<string | undefined>();
  const initialValues = { ...reportMessageInitialValues, memberEmail };
  const validationSchema = reportMessageValidationSchema;
  const blocked = blockedCheckbox;
  const onSubmit = async (values: any, actions: any) => {
    setLoading(true);
    const reportMessageInformation = {
      reporteeId: Number(recipientId),
      reason: values.reportMessageText,
      actionOnReport: values.blockUser.length > 0 ? "block" : "none",
    };

    try {
      const reportMessageResp = await messageService.reportMessage(
        reportMessageInformation,
        auth?.access_token
      );
      actions.resetForm();
      setLoading(false);
      submitHandler(values.blockUser.length > 0);
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.status === 401) {
        //place your reentry code
        await signout();
      }
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, isValid, setFieldValue }) => (
        <section className="report-msg-popup">
          <CancelPopUpSVG cancelPopUp={cancelPopUp} />
          <div className="report-msg-form-container">
            <Form className="report-msg-form">
              <FormikControl
                control="input"
                type="email"
                label="Choosen Member"
                name="memberEmail"
                disabled
              />

              <FormikControl
                control="textarea"
                label="Why would you like to report the messages from this user?"
                name="reportMessageText"
              />
              <FormikControl
                control="checkbox"
                label=""
                name="blockUser"
                options={blocked}
              />

              <Button
                type="submit"
                size="size-xxlg"
                disabled={!isValid}
                colorTheme="has-primary-theme"
              >
                Submit Report
              </Button>
              <LoadingSpinner isLoading={isLoading} />
              {error && <TextError>{error}</TextError>}
            </Form>
          </div>
        </section>
      )}
    </Formik>
  );
};

export default ReportMessagePopUp;
