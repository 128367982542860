import React, { useState } from "react";

const Checkbox = ({
  value,
  checked,
  label,
  checkHandler,
}: {
  value: string;
  checked: boolean;
  label: string;
  checkHandler: () => void;
}) => {
  return (
    <div className="form-control">
      <div className="checkbox-option">
        <input
          type="checkbox"
          id={value}
          value={value}
          checked={checked}
          onChange={checkHandler}
        />
        <label htmlFor={value}>{label}</label>
      </div>
    </div>
  );
};

export default Checkbox;
