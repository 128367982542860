import React, { useState } from "react";
import SearchHeader from "./SearchHeader";
import SearchBody from "./SearchBody";
import "./SearchContent.scss";
import { searchSite } from "./helper";
import { useAuth } from "../../../navigation/Auth/ProvideAuth";
const SearchContent = () => {
  const { auth } = useAuth();
  const [searchResult, setSearchResult] = useState({});
  const [loading, setLoading] = useState<boolean>(false);

  const handleSearch = async (query: string) => {
    setLoading(true);
    try {
      const searchResp = await searchSite(query, auth?.access_token);
      setLoading(false);
      setSearchResult(searchResp);
    } catch (error: any) {
      setLoading(false);
    }
  };

  return (
    <main className="search-page">
      <SearchHeader handleSiteSearch={handleSearch} />
      <SearchBody searchResult={searchResult} loading={loading} />
    </main>
  );
};

export default SearchContent;
