import React, { FunctionComponent } from "react";
import RegSuccessFooter from "./RegSuccessFooter";
import "./RegistrationSuccessContent.scss";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import { LOGIN } from "../../../navigation/CONSTANT";
interface RegistrationSuccessContentProps {
  takedaVerifiedHCP: boolean;
}
const RegistrationSuccessContent: FunctionComponent<RegistrationSuccessContentProps> =
  ({ takedaVerifiedHCP }: RegistrationSuccessContentProps) => {
    return (
      <main className="reg-success-page">
        {takedaVerifiedHCP ? (
          <section className="reg-success-content">
            <h1>Thank you!</h1>
            <div className="reg-info">
              <p>You are now registered. Please login to continue</p>
              <Link to={LOGIN}>
                <Button
                  type="button"
                  size="size-lg"
                  colorTheme="has-primary-theme"
                >
                  Login
                </Button>
              </Link>
            </div>
          </section>
        ) : (
          <section className="reg-success-content">
            <h1>Thank you!</h1>
            <div className="reg-info">
              <p>The registration approval process can take up 3-5 days.</p>
              <p>
                We will get in touch via email to let you know if your account
                has been approved.
              </p>
            </div>
          </section>
        )}

        <RegSuccessFooter />
      </main>
    );
  };

export default RegistrationSuccessContent;
