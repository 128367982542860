import React, { FunctionComponent, useState } from "react";
import DefaultAvatar from "../../assets/images/default-avatar.png";
import { useLoggedUserContext } from "../../context/LoggedUser";

import Button from "../Button";
import ReplyCard from "../ReplyCard";
import "./CommentCard.scss";
import ArticleReplyComment from "./ReplyForm";

interface Reply {
  id?: number;
  userId: number;
  image: string | null;
  firstName: string;
  lastName: string;
  commentText: string;
}

interface Comment {
  id: number;
  userId: number;
  image: string | null;
  firstName: string;
  lastName: string;
  commentText: string;
  replies?: Array<Reply>;
}

interface CommentCardProps {
  commentDetails: Comment;
  submitReplyHandler: (commentText: string, commentId: number) => void;
  updateCommentHandler?: (commentText: string, commentId: number) => void;
  deleteCommentHandler: (commentId: number, replyId?: number) => void;
}

const CommentCard: FunctionComponent<CommentCardProps> = ({
  commentDetails,
  submitReplyHandler,
  deleteCommentHandler,
}: CommentCardProps) => {
  const [replyActive, setReplyActive] = useState<boolean>(false);
  const {
    loggedUserInfo: { id: currentUserID, isAdmin },
  } = useLoggedUserContext();
  const { id, userId, image, firstName, lastName, commentText, replies } =
    commentDetails;

  const handleReplySubmit = async (replyText: string) => {
    await submitReplyHandler(replyText, id);
  };

  const handleCommentDeletion = async (replyId?: number) => {
    await deleteCommentHandler(id, replyId);
  };

  return (
    <div className="comment-card-container">
      <div className="comment-card">
        <div className="comment-main">
          <div className="author-img-cont">
            <img src={image ? image : DefaultAvatar} alt={firstName} />
          </div>

          <div className="comment-info">
            <h3>{firstName + " " + lastName}</h3>
            <p>{commentText}</p>
          </div>
        </div>
        <div className="comment-actions">
          {(currentUserID === userId || isAdmin) && (
            <div
              className="comment-action"
              onClick={() => handleCommentDeletion()}
            >
              Delete
            </div>
          )}

          <div
            className="comment-action"
            onClick={() => setReplyActive(!replyActive)}
          >
            {replies && replies.length > 0
              ? `View ${replies.length} replies`
              : "Reply"}
          </div>
        </div>
      </div>
      <div className="reply-container">
        {replyActive
          ? replies?.map((reply, idx) => (
              <ReplyCard
                key={"reply" + idx}
                replyDetails={reply}
                deleteReply={() => handleCommentDeletion(reply.id)}
              />
            ))
          : null}
      </div>

      {replyActive && (
        <ArticleReplyComment submitReplyHandler={handleReplySubmit} />
      )}
    </div>
  );
};

export default CommentCard;
