import React, { useState } from "react";
import { Formik, Form } from "formik";
import "./ChangePwdForm.scss";
import { ChangePwdInitialValues } from "./ChangePwdInitialValues";
import { ChangePwdValidationSchema } from "./ChangePwdValidationScheme";
import FormikControl from "../../../../components/Form/FormikControl";
import Button from "../../../../components/Button";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import TextError from "../../../../components/Form/TextError";
import ChangePasswordService from "../../../../services/changepassword";
import { useAuth } from "../../../../navigation/Auth/ProvideAuth";
import { changePwdErrorInformation } from "./errorTypes";
import { useHistory } from "react-router";
import { CHANGEPASSWORDSUCCESS } from "../../../../navigation/CONSTANT";
import { useProvideAuth } from "../../../../navigation/Auth/useProvideAuth";

const ChangePwdForm = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const { auth } = useAuth();
  const { signout } = useProvideAuth();

  const history = useHistory();
  const initialValues = ChangePwdInitialValues;
  const validationSchema = ChangePwdValidationSchema;

  const onSubmit = async (values: any, actions: any) => {
    setLoading(true);
    setError("");
    const changePwdCredentials = {
      oldPassword: values.currentpwd,
      newPassword: values.newpwd,
    };
    try {
      const response = await ChangePasswordService.changePassword(
        changePwdCredentials,
        auth?.access_token
      );
      setLoading(false);
      history.push(CHANGEPASSWORDSUCCESS);
    } catch (error: any) {
      setLoading(false);
      setError(
        changePwdErrorInformation[error?.response?.status] ||
          error?.response?.data?.errorCauses[0]?.errorSummary
      );
      if (error?.response?.status === 401) {
        //place your reentry code
        await signout();
      }
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, isValid, setFieldValue }) => (
        <section className="change-pwd-page-form">
          <div className="change-pwd-form-container">
            <Form className="change-pwd-form" onChange={() => setError("")}>
              <FormikControl
                control="password"
                type="password"
                label="Current Password"
                name="currentpwd"
              />
              <FormikControl
                control="password"
                type="password"
                label="New Password"
                name="newpwd"
              />
              <FormikControl
                control="password"
                type="password"
                label="Repeat Password"
                name="repeatpwd"
              />

              <Button
                type="submit"
                size="size-xxlg"
                disabled={!isValid}
                colorTheme="has-primary-theme"
              >
                Change Password
              </Button>
              <LoadingSpinner isLoading={isLoading} />
              {error && <TextError>{error}</TextError>}
            </Form>
          </div>
        </section>
      )}
    </Formik>
  );
};

export default ChangePwdForm;
