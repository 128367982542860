import React from "react";
import "./VerifyEmailContent.scss";
import { ReactComponent as ResetPasswordSVG } from "../../../assets/images/resetpassword.svg";

const VerifyEmailContent = () => {
  return (
    <main className="verify-email-page">
      <section className="verify-email-page-content">
        <ResetPasswordSVG />
        <h3>Please verify your email</h3>
        <p>
          We’ve sent you a verification email to your registered email address.
        </p>
        <p>Please verify your email before continuing.</p>
      </section>
    </main>
  );
};

export default VerifyEmailContent;
