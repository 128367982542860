import "./ContactUsForm.scss";
import { Formik, Form } from "formik";
import FormikControl from "../../../../components/Form/FormikControl";
import Button from "../../../../components/Button";
import { Link, useHistory } from "react-router-dom";
import TextError from "../../../../components/Form/TextError";
import React, { useRef, useState } from "react";
import {
  MESSAGESUCCESS,
  REGISTER,
  RESETPASSWORDREQUEST,
} from "../../../../navigation/CONSTANT";
import { contactUsValidationScheme } from "./contactUsValidationScheme";
import { contactUsInitialValues } from "./contactUsInitialValues";
import { loginErrorInformation } from "./errorTypes";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { featureTypes } from "./featureTypes";
import { useAuth } from "../../../../navigation/Auth/ProvideAuth";
import feedbackService from "../../../../services/feedback";

const ContactUsForm = () => {
  const { auth } = useAuth();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const history = useHistory();

  const initialValues = contactUsInitialValues;
  const validationSchema = contactUsValidationScheme;
  const featureOptions = featureTypes;

  const onSubmit = async (values: any, actions: any) => {
    setLoading(true);
    const contactPayload =
      values.topic && values.topic !== "Choose topic"
        ? {
            topic: values.topic,
            messageText: values.message,
          }
        : {
            messageText: values.message,
          };
    try {
      const feedbackResp = await feedbackService.userContact(
        auth?.access_token,
        contactPayload
      );
      setLoading(false);
      history.push(MESSAGESUCCESS);
    } catch (error: any) {
      setError(
        typeof error?.response?.data?.message === "string"
          ? error?.response?.data?.message
          : "Error occured"
      );
      setLoading(false);
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, isValid, setFieldValue }) => (
        <section className="contactus-page-form">
          <div className="contactus-form-container">
            <Form className="contactus-form" onChange={() => setError("")}>
              <FormikControl
                control="select"
                label="Topic (optional)"
                name="topic"
                options={featureOptions}
              />

              <FormikControl
                control="textarea"
                label="Message"
                name="message"
              />

              <Button
                type="submit"
                size="size-xxlg"
                disabled={!isValid}
                colorTheme="has-primary-theme"
              >
                Submit
              </Button>
              <LoadingSpinner isLoading={isLoading} />
              {error && <TextError>{error}</TextError>}
            </Form>
          </div>
        </section>
      )}
    </Formik>
  );
};

export default ContactUsForm;
