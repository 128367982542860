import React from "react";
import "./ResetPwdReqContent.scss";
import ResetPwdReqForm from "./ResetPwdReqForm";
import ResetPwdReqHeader from "./ResetPwdReqHeader";

const ResetPwdReqContent = () => {
  return (
    <main className="reset-pwd-req-page">
      <ResetPwdReqHeader />
      <ResetPwdReqForm />
    </main>
  );
};

export default ResetPwdReqContent;
