import React, { useState } from "react";
import { useParams } from "react-router";
import SkeletonConfHeading from "../../../components/Skeleton/SkeletonConfHeading";
import { useConferenceContext } from "../../../context/Conferences";
import { getConfByConfId } from "../helper";
import "./ConfHeading.scss";

const ConfHeading = () => {
  const { conferenceId } = useParams<{ conferenceId?: string }>();
  const { conferences, setConferences } = useConferenceContext();

  const selectedConference = getConfByConfId(conferences.data, conferenceId);

  const { title, feature_image, embedLink, pdfLink } = selectedConference
    ? selectedConference
    : {
        title: "",
        feature_image: "",
        embedLink: "",
        pdfLink: "",
      };
  return (
    <section className="conf-heading">
      {title.length == 0 && <SkeletonConfHeading />}
      {title.length > 0 && (
        <div className="title-container">
          <h1>{title}</h1>
        </div>
      )}
    </section>
  );
};

export default ConfHeading;
