import React, { FunctionComponent, useEffect, useState } from "react";
import CMEDisplayBoard from "../../../../../components/CMEDisplayBoard";
import { useAuth } from "../../../../../navigation/Auth/ProvideAuth";
import educationService from "../../../../../services/cmemodules";
import { CMEDisplayCardProps } from "../../../../../types";
import { selectOnlyBookmarked, sortResultsByCriteria } from "../helper";

import "./FilterResult.scss";
interface FilterResultProps {
  filterResult: Array<CMEDisplayCardProps>;
  showOnlyBookmarked: boolean;
}

const FilterResult: FunctionComponent<FilterResultProps> = ({
  filterResult,
  showOnlyBookmarked,
}: FilterResultProps) => {
  const { auth } = useAuth();
  const [sortBy, setSortBy] = useState<string>("A");
  const [educationModules, setEducationModules] = useState<{
    data: Array<CMEDisplayCardProps>;
    totalCount: number;
  }>({ data: [], totalCount: 0 });
  const selectSortingCriteria = (event: any) => {
    setSortBy(event.target.value);
  };
  const sortedResult = sortResultsByCriteria(filterResult, sortBy);
  const sortedBookMarkedResult = sortResultsByCriteria(
    selectOnlyBookmarked(filterResult, educationModules?.data),
    sortBy
  );

  useEffect(() => {
    const getAllSavedModules = async () => {
      const educationModulesResp =
        await educationService.getSavedEducationModule(1, auth?.access_token);
      setEducationModules({ ...educationModules, ...educationModulesResp });
    };
    getAllSavedModules();
  }, []);

  return (
    <div className="filter-mod-result">
      <div className="title">
        <h3>Results</h3>
      </div>

      <div className="form-control">
        <label htmlFor="sort">Sort by</label>
        <select name="sort" id="sort-modules" onChange={selectSortingCriteria}>
          <option value="A">Age (Newest to Oldest)</option>
          <option value="B">Age (Oldest to Newest)</option>
          <option value="C">Title (A-Z)</option>
          <option value="D">Title (Z-A)</option>
        </select>
      </div>
      <div className="bookmarked-results">
        <h3>Bookmarked Modules</h3>
        <CMEDisplayBoard modules={sortedBookMarkedResult} />
      </div>
      {!showOnlyBookmarked && (
        <div className="other-results">
          <h3>Other Results</h3>
          <CMEDisplayBoard modules={sortedResult} />
        </div>
      )}
    </div>
  );
};

export default FilterResult;
