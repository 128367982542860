import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../navigation/Auth/ProvideAuth";
import "./ModuleHeading.scss";
import { CMEModuleSingle } from "../../../types";
import educationService from "../../../services/cmemodules";
import SkeletonModuleHeading from "../../../components/Skeleton/SkeletonModuleHeading";

const ModuleHeading = () => {
  const { moduleId } = useParams<{ moduleId?: string }>();
  const [loading, setLoading] = useState<boolean>(false);

  const { auth } = useAuth();
  const [module, setModule] = useState<CMEModuleSingle>();
  useEffect(() => {
    const getEducationModule = async () => {
      // get conference details
      setLoading(true);
      const moduleResp = await educationService.getEducationModule(
        moduleId,
        auth?.access_token
      );
      setModule(moduleResp);
      setLoading(false);
    };

    getEducationModule();
  }, [setModule]);
  return (
    <section className="module-heading">
      {loading && <SkeletonModuleHeading />}
      {!loading && (
        <div className="title-container">
          <h1>{module?.title}</h1>
        </div>
      )}
    </section>
  );
};

export default ModuleHeading;
