import React, { FunctionComponent } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import NotFoundContent from "./NotFoundContent";

const NotFound: FunctionComponent = () => {
  return (
    <>
      <Header />
      <NotFoundContent />
      <Footer />
    </>
  );
};

export default NotFound;
