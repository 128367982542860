import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;
const actPreferencesURL = "/activity/preferences";
const optInURL = "/activity/opt-in";
const optOutURL = "/activity/opt-out";

interface OptRequestBody {
  activityTypeId: number;
}

const getActPreferences = async (accessToken: string) => {
  const config = {
    headers: { "takeda-access-token": accessToken },
  };
  const response = await axios.get(baseUrl + actPreferencesURL, config);
  return response.data;
};

const activityOptIn = async (
  newObject: OptRequestBody,
  accessToken: string
) => {
  const config = {
    headers: { "takeda-access-token": accessToken },
  };

  const response = await axios.post(baseUrl + optInURL, newObject, config);

  return response.data;
};

const activityOptOut = async (
  newObject: OptRequestBody,
  accessToken: string
) => {
  const config = {
    headers: { "takeda-access-token": accessToken },
  };

  const response = await axios.post(baseUrl + optOutURL, newObject, config);

  return response.data;
};

const actPreferenceService = {
  getActPreferences,
  activityOptIn,
  activityOptOut,
};
export default actPreferenceService;
