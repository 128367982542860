import React from "react";
import { MessageCardProps } from "../../types";
import MessageCard from "../MessageCard";
import { css } from "@emotion/react";
import SyncLoaderSpinner from "../SyncLoader";
import "./ReplyBoard.scss";
import ReplyBoardForm from "./ReplyBoardForm";

const ReplyBoard = ({
  messages,
  handlePageChange,
  currentPage,
  totalCount,
  isLoading,
  senderBlock,
  receiverBlock,
}: {
  messages: Array<MessageCardProps>;
  handlePageChange: (change: number) => void;
  currentPage: number;
  totalCount: number;
  isLoading: boolean;
  senderBlock: boolean;
  receiverBlock: boolean;
}) => {
  const messagingDisabled = senderBlock || receiverBlock;
  const prevPresent = currentPage > 1;
  const nextPresent = currentPage * 3 < totalCount;
  const loaderStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
  `;
  return (
    <div className="my-reply-board">
      {nextPresent && (
        <p className="load-msg" onClick={() => handlePageChange(1)}>
          Load previous messages
        </p>
      )}
      {isLoading ? (
        <SyncLoaderSpinner isLoading={isLoading} override={loaderStyle} />
      ) : (
        messages.map((message, idx) => (
          <MessageCard key={"msg" + idx} {...message} cardType="REPLY" />
        ))
      )}
      {prevPresent && (
        <p className="load-msg" onClick={() => handlePageChange(-1)}>
          Load recent messages
        </p>
      )}
      {!messagingDisabled ? (
        <ReplyBoardForm />
      ) : (
        <p className="block-error">
          You&apos;re not allowed to message this recipient
        </p>
      )}
    </div>
  );
};

export default ReplyBoard;
