import React, { FunctionComponent } from "react";
import "./ReplyCard.scss";
import DefaultAvatar from "../../assets/images/default-avatar.png";
import { useLoggedUserContext } from "../../context/LoggedUser";

interface Reply {
  id?: number;
  userId: number;
  image: string | null;
  firstName: string;
  lastName: string;
  commentText: string;
}

interface ReplyCardProps {
  replyDetails: Reply;
  editReply?: () => void;
  deleteReply?: () => void;
}

const ReplyCard: FunctionComponent<ReplyCardProps> = ({
  replyDetails,
  deleteReply,
}: ReplyCardProps) => {
  const { id, userId, image, firstName, lastName, commentText } = replyDetails;
  const {
    loggedUserInfo: { id: currentUserID, isAdmin },
  } = useLoggedUserContext();
  return (
    <div className="reply-card">
      <div className="reply-main">
        <div className="author-img-cont">
          <img src={image ? image : DefaultAvatar} alt={firstName} />
        </div>

        <div className="reply-info">
          <h3>{firstName + " " + lastName}</h3>
          <p>{commentText}</p>
        </div>
      </div>
      <div className="reply-act">
        {(userId === currentUserID || isAdmin) && (
          <p onClick={deleteReply}>Delete</p>
        )}
      </div>
    </div>
  );
};

export default ReplyCard;
