import React from "react";
import "./CommunityContent.scss";
import CommunityBody from "./CommunityBody";
interface CommunityContentProps {
  HeaderComponent: React.FunctionComponent;
  BodyComponent: React.FunctionComponent;
}

const CommunityContent = ({
  HeaderComponent,
  BodyComponent,
}: CommunityContentProps) => {
  return (
    <main className="community-content">
      <HeaderComponent />
      <CommunityBody BodyComponent={() => <BodyComponent />} />
    </main>
  );
};

export default CommunityContent;
