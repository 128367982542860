import "./RegistrationForm.scss";
import { Formik, Form, ErrorMessage } from "formik";
import React, { useRef, useState } from "react";
import FormikControl from "../../../../components/Form/FormikControl";
import ImagePreviewComponent from "../../../../components/Form/ImagePreviewComponent";
import TextError from "../../../../components/Form/TextError";
import { complianceTypes } from "./complianceTypes";
import { countryTypes } from "./countryTypes";
import { seniorityTypes } from "./seniorityTypes";
import { specialityTypes } from "./specialityTypes";
import { titleTypes } from "./titleTypes";
import Button from "../../../../components/Button";
import SupportMessage from "../../../../components/SupportMessage";
import axios from "axios";
import { useHistory } from "react-router";
import ReCaptcha from "react-google-recaptcha";
import { takedaVerifiedCompliance } from "./takedaVerifiedCompliance";
import { registrationInitialValues } from "./initialValues";
import { registrationValidationSchema } from "./validationSchema";
import { registerErrorInformation } from "./errorTypes";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import {
  REGISTERSUCCESSUNVERIFIED,
  REGISTERSUCCESSVERIFIED,
  VERIFYEMAIL,
} from "../../../../navigation/CONSTANT";
import Checkbox from "./Checkbox";
import Modal from "../../../../components/Modal";
import InnerModalTC from "../../../../components/InnerModalTC";
import InnerModalPrivacy from "../../../../components/InnerModalPrivacy";

const RegistrationForm = () => {
  const baseURL = process.env.REACT_APP_BASE_URL + "/user/signup";
  const history = useHistory();
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [privacyRead, setPrivacyRead] = useState<boolean>(false);
  const [tcRead, setTCRead] = useState<boolean>(false);
  const [tcModalOpen, setTCModalOpen] = useState<boolean>(false);
  const [privModalOpen, setPrivModalOpen] = useState<boolean>(false);

  const reCaptchaRef = useRef<ReCaptcha>(null);

  const seniorityOptions = seniorityTypes;
  const specialityOptions = specialityTypes;
  const regionOptions = countryTypes;
  const complianceOptions = complianceTypes;
  const titleOptions = titleTypes;
  const takedaVerifiedOptions = takedaVerifiedCompliance;

  // to hide choose file
  const uploadFileRef: any = useRef(null);
  const initialValues: any = registrationInitialValues;
  const validationSchema: any = registrationValidationSchema;

  const handleTCChange = () => {
    if (!tcRead) setTCModalOpen(true);
    setTCRead(!tcRead);
  };
  const handlePrivChange = () => {
    if (!privacyRead) setPrivModalOpen(true);
    setPrivacyRead(!privacyRead);
  };

  const rejectTC = () => {
    setTCModalOpen(false);
    setTCRead(false);
  };

  const rejectPrivPolicy = () => {
    setPrivModalOpen(false);
    setPrivacyRead(false);
  };

  const onSubmit = async (values: any, actions: any) => {
    setError("");
    if (!privacyRead || !tcRead) {
      setError("Please accept all conditions");
      return;
    }
    setLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.append("title", values.title);
    bodyFormData.append("firstName", values.firstName);
    bodyFormData.append("lastName", values.lastName);
    values.organisation
      ? bodyFormData.append("organisation", values.organisation)
      : "";
    values.seniority && values.seniority !== "Select Seniority"
      ? bodyFormData.append("seniority", values.seniority)
      : "";
    values.professionalNumber
      ? bodyFormData.append("professionalNumber", values.professionalNumber)
      : "";
    values.speciality && values.speciality !== "Select Speciality"
      ? bodyFormData.append("speciality", values.speciality)
      : "";
    values.region ? bodyFormData.append("region", values.region) : "";
    bodyFormData.append("email", values.email);
    values.mobileNumber
      ? bodyFormData.append("mobileNumber", values.mobileNumber)
      : "";
    values.password ? bodyFormData.append("password", values.password) : "";
    if (values.profileImage) {
      bodyFormData.append("image", values.profileImage);
    }

    //recaptcha setting

    let reCaptchaToken;
    if (reCaptchaRef.current) {
      reCaptchaToken = await reCaptchaRef.current.executeAsync();
      reCaptchaRef.current.reset();
    }

    axios({
      method: "post",
      url: baseURL,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        "g-recaptcha-response": reCaptchaToken,
      },
    })
      .then((response) => {
        //handle success
        // possible status values:"notATakedaVerifiedHcp", "takedaVerifiedHcp", "doesNotExistInTakedaCrm"
        // action  = noAction | verifiedInstantly | requestRaisedForTakedaVerification
        // | sentEmailVerificationLink
        setLoading(false);
        actions.resetForm();

        const isTakedaVerified = response.data.status === "takedaVerifiedHcp";
        if (isTakedaVerified) {
          // if the user is taked verified, instruct to directly login
          history.push(REGISTERSUCCESSVERIFIED);
        } else {
          const actionInstructed = response.data.action;
          if (actionInstructed === "sentEmailVerificationLink") {
            // If the user is random, instruct to verify the email
            history.push(VERIFYEMAIL);
          } else if (
            actionInstructed === "requestRaisedForTakedaVerification"
          ) {
            // If the user is not a takeda verified hcp, inform that the lead has been generated
            history.push(REGISTERSUCCESSUNVERIFIED);
          } else {
            // In case of no action and verified instantly
            history.push(REGISTERSUCCESSVERIFIED);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        //handle error
        setError(
          registerErrorInformation[error?.response?.data?.errors] ||
            registerErrorInformation[error?.response?.data?.errors?.errorMsg] ||
            "Error in registering the user.Please try again later."
        );
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, isValid, setFieldValue }) => (
        <section className="reg-form">
          <div className="form-container">
            <Form className="register-form" onChange={() => setError("")}>
              <ReCaptcha
                ref={reCaptchaRef}
                sitekey={
                  process.env.REACT_APP_Recaptcha_Site_key
                    ? process.env.REACT_APP_Recaptcha_Site_key
                    : ""
                }
                size="invisible"
              />
              <ImagePreviewComponent file={values.profileImage} />
              <div className="form-control">
                <input
                  hidden
                  ref={uploadFileRef}
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={(event: any) => {
                    setFieldValue("profileImage", event.target.files[0]);
                  }}
                />
                <button
                  type="button"
                  onClick={() => {
                    uploadFileRef.current.click();
                  }}
                  className="upload-button"
                >
                  <p>Upload a photo</p>
                  <p>&#40;Optional&#41;</p>
                </button>
                <ErrorMessage component={TextError} name="profileImage" />
              </div>
              <FormikControl
                control="select"
                label="Title"
                name="title"
                options={titleOptions}
              />
              <FormikControl
                control="input"
                type="text"
                label="First Name"
                name="firstName"
              />
              <FormikControl
                control="input"
                type="text"
                label="Last Name"
                name="lastName"
              />
              <FormikControl
                control="select"
                label="Region"
                name="region"
                options={regionOptions}
              />

              <FormikControl
                control="input"
                type="text"
                label="Organisation"
                name="organisation"
              />
              <FormikControl
                control="select"
                label="Speciality"
                name="speciality"
                options={specialityOptions}
              />
              <FormikControl
                control="select"
                label="Seniority"
                name="seniority"
                options={seniorityOptions}
              />

              <FormikControl
                control="input"
                type="text"
                label="Professional Number"
                name="professionalNumber"
              />
              <FormikControl
                control="input"
                type="email"
                label="Email Address"
                name="email"
              />

              <FormikControl
                control="checkbox"
                label=""
                name="takedaVerifiedCompliance"
                options={takedaVerifiedOptions}
              />
              <FormikControl
                control="input"
                type="tel"
                label="Mobile Number"
                name="mobileNumber"
              />
              {values?.takedaVerifiedCompliance.length < 1 ? (
                <FormikControl
                  control="password"
                  type="password"
                  label="Choose Password"
                  name="password"
                />
              ) : null}
              {values?.takedaVerifiedCompliance.length < 1 ? (
                <FormikControl
                  control="password"
                  type="password"
                  label="Confirm Password"
                  name="confirmPassword"
                />
              ) : null}

              <div className="compliance-container">
                {/* <h3>Compliance</h3> */}
                <p>
                  Please read through our terms and conditions and privacy
                  policy
                </p>
              </div>
              {/* <FormikControl
                control="checkbox"
                label=""
                name="compliance"
                options={complianceOptions}
              /> */}
              {/* Check box logic below here */}
              <Checkbox
                value="termsandcondition"
                label="I agree with the Terms and Conditions"
                checked={tcRead}
                checkHandler={handleTCChange}
              />
              <Checkbox
                value="privacypolicy"
                label="I agree with the Privacy Policy"
                checked={privacyRead}
                checkHandler={handlePrivChange}
              />
              {/* Check box logic above here */}

              <Button
                type="submit"
                size="size-xxlg"
                disabled={!isValid}
                colorTheme="has-primary-theme"
              >
                Create account
              </Button>
              <SupportMessage
                msg="Facing an issue? Contact Support at "
                email="medunifi@interactivepharma.com"
              />
              <LoadingSpinner isLoading={isLoading} />
              {error && <TextError>{error}</TextError>}
            </Form>
          </div>
          {tcModalOpen && (
            <Modal>
              <InnerModalTC
                cancelPopUp={() => setTCModalOpen(false)}
                reject={rejectTC}
              />
            </Modal>
          )}
          {privModalOpen && (
            <Modal>
              <InnerModalPrivacy
                cancelPopUp={() => setPrivModalOpen(false)}
                reject={rejectPrivPolicy}
              />
            </Modal>
          )}
        </section>
      )}
    </Formik>
  );
};

export default RegistrationForm;
