import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const changePwdURL = "/user/changePassword";

interface ChangePasswordCredential {
  oldPassword: string;
  newPassword: string;
}

const changePassword = async (
  credential: ChangePasswordCredential,
  accessToken: string
) => {
  const config = {
    headers: { "takeda-access-token": accessToken },
  };
  const response = await axios.post(baseUrl + changePwdURL, credential, config);
  return response.data;
};

const ChangePasswordService = {
  changePassword,
};

export default ChangePasswordService;
