import React from "react";
import "./MessageSuccessContent.scss";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";

const MessageSuccessContent = () => {
  return (
    <main className="msg-succ-page">
      <section className="msg-succ-content">
        <h3>Thank you!</h3>
        <p>your message has been sent</p>
        <Link to="/">
          <Button type="button" size="size-lg" colorTheme="has-secondary-theme">
            HOME
          </Button>
        </Link>
      </section>
    </main>
  );
};

export default MessageSuccessContent;
