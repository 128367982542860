import React from "react";
import "./TherapyAreaHeading.scss";
const TherapyAreaHeading = () => {
  return (
    <section className="education-heading">
      <div className="title-container">
        <h1>Education</h1>
      </div>
    </section>
  );
};

export default TherapyAreaHeading;
