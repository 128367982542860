import React, { FunctionComponent } from "react";
import "./PaginationLink.scss";
interface PaginationLinkProps {
  pageNumber: number;
  pageTitle: string;
  activePage?: number;
  setPageNumber: (pageNumber: number) => void;
}

const PaginationLink: FunctionComponent<PaginationLinkProps> = ({
  pageNumber,
  pageTitle,
  activePage,
  setPageNumber,
}: PaginationLinkProps) => {
  const handleClick = () => {
    setPageNumber(pageNumber);
  };
  return (
    <div
      className={`pagination-link ${activePage === pageNumber ? "active" : ""}`}
      onClick={handleClick}
    >
      {pageTitle}
    </div>
  );
};

export default PaginationLink;
