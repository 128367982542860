import React, { useRef, useState } from "react";
import { Formik, Form } from "formik";
import "./ResetPwdForm.scss";
import { resetPwdInitialValues } from "./resetPwdInitialValues";
import { resetPwdValidationScheme } from "./resetPwdValidationScheme";
import FormikControl from "../../../../components/Form/FormikControl";
import Button from "../../../../components/Button";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import TextError from "../../../../components/Form/TextError";
import ReCaptcha from "react-google-recaptcha";
import { useHistory, useParams } from "react-router";
import { RESETPASSWORDSUCCESS } from "../../../../navigation/CONSTANT";
import ResetPasswordService from "../../../../services/resetpassword";
import { resetPwdErrorInformation } from "./errorTypes";
const ResetPwdForm = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const history = useHistory();
  const initialValues = resetPwdInitialValues;
  const validationSchema = resetPwdValidationScheme;
  const reCaptchaRef = useRef<ReCaptcha>(null);

  const { recoveryToken } = {
    recoveryToken: "",
    ...useParams(),
  };

  const onSubmit = async (values: any, actions: any) => {
    setLoading(true);
    setError("");
    let reCaptchaToken;
    if (reCaptchaRef.current) {
      reCaptchaToken = await reCaptchaRef.current.executeAsync();
      reCaptchaRef.current.reset();
    }
    try {
      const creds = {
        recoveryToken: recoveryToken,
        newPassword: values.newpwd,
      };
      const response = await ResetPasswordService.resetPassword(
        creds,
        reCaptchaToken
      );
      setLoading(false);
      history.push(RESETPASSWORDSUCCESS);
    } catch (error: any) {
      setLoading(false);
      setError(
        resetPwdErrorInformation[error?.response?.data] || error?.response?.data
      );
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, isValid, setFieldValue }) => (
        <section className="reset-pwd-page-form">
          <div className="reset-pwd-form-container">
            <Form className="reset-pwd-form" onChange={() => setError("")}>
              <ReCaptcha
                ref={reCaptchaRef}
                sitekey={
                  process.env.REACT_APP_Recaptcha_Site_key
                    ? process.env.REACT_APP_Recaptcha_Site_key
                    : ""
                }
                size="invisible"
              />
              <FormikControl
                control="password"
                type="password"
                label="Create New Password"
                name="newpwd"
              />
              <FormikControl
                control="password"
                type="password"
                label="Confirm Password"
                name="cofirmpwd"
              />
              <Button
                type="submit"
                size="size-xxlg"
                disabled={!isValid}
                colorTheme="has-primary-theme"
              >
                Confirm
              </Button>
              <LoadingSpinner isLoading={isLoading} />
              {error && <TextError>{error}</TextError>}
            </Form>
          </div>
        </section>
      )}
    </Formik>
  );
};

export default ResetPwdForm;
