import React, { FunctionComponent } from "react";
import Button from "../Button";

import "./InviteConfirmPopUp.scss";
interface InviteConfirmPopUpProps {
  confirmBtnHandler: (email: string) => void;
  backBtnHandler: () => void;
}

const InviteConfirmPopUp: FunctionComponent<InviteConfirmPopUpProps> = ({
  confirmBtnHandler,
  backBtnHandler,
}: InviteConfirmPopUpProps) => {
  return (
    <div className="inviteconfirmpopup">
      <h1>Invite Confirmation</h1>
      <h3>
        Please confirm that the person being invited is an HCP who works in the
        disease areas being covered in this website; Hematology, Immunology,
        Gastroenterology, Hereditary Angioedema (HAE), Rare Genetic Diseases and
        Oncology
      </h3>
      <div className="action-btns">
        <Button
          type="button"
          size="size-lg"
          colorTheme="has-primary-theme"
          onClick={backBtnHandler}
        >
          Back
        </Button>
        <Button
          type="button"
          size="size-lg"
          colorTheme="has-primary-theme"
          onClick={confirmBtnHandler}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default InviteConfirmPopUp;
