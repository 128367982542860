import React, { useEffect, useRef, useState } from "react";
import { Redirect, useLocation } from "react-router";
import { useAuth } from "../../navigation/Auth/ProvideAuth";
import { COMMUNITY } from "../../navigation/CONSTANT";
import loginService from "../../services/login";
import ReCaptcha from "react-google-recaptcha";
import queryString from "query-string";

import "./Callback.scss";

const LoginCallback = () => {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const { hash } = useLocation();
  const { setAuth } = useAuth();
  const { code, state } = queryString.parse(hash);
  const reCaptchaRef = useRef<ReCaptcha>(null);

  useEffect(() => {
    const authorizeUser = async () => {
      let reCaptchaToken;
      if (reCaptchaRef.current) {
        reCaptchaToken = await reCaptchaRef.current.executeAsync();
        reCaptchaRef.current.reset();
      }
      if (typeof code === "string") {
        try {
          const payload = await loginService.authorizeUser(
            code,
            reCaptchaToken
          );
          setAuth(payload);
          localStorage.setItem(
            "loggedCommunityAppUser",
            JSON.stringify(payload)
          );
          setLoggedIn(true);
        } catch (error: any) {
          setError(
            error?.response?.data?.errors?.error_description ||
              "Error in getting the tokens"
          );
        }
      } else {
        setError("Error in getting the tokens");
      }
    };
    authorizeUser();
  }, [code]);

  return loggedIn ? (
    <Redirect to={COMMUNITY} />
  ) : error ? (
    <div className="callback">
      <h1>{error}</h1>
    </div>
  ) : (
    <div className="callback">
      <ReCaptcha
        ref={reCaptchaRef}
        sitekey={
          process.env.REACT_APP_Recaptcha_Site_key
            ? process.env.REACT_APP_Recaptcha_Site_key
            : ""
        }
        size="invisible"
      />
      <h1>Please wait while you get logged in</h1>
    </div>
  );
};

export default LoginCallback;
