import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ResetPwdReqContent from "./ResetPwdReqContent";

const ResetPasswordRequest = () => {
  return (
    <>
      <Header />
      <ResetPwdReqContent />
      <Footer />
    </>
  );
};

export default ResetPasswordRequest;
