interface SlugMapperProps {
  [slug: string]: string;
}
export const slugMapper: SlugMapperProps = {
  rarehaematology: "Rare Haematology",
  raregeneticdisease: "Rare Genetic Diseases",
  oncology: "Oncology",
  immunology: "Immunology/HAE",
  gastroenterology: "Gastroenterology",
};
