import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { ReactComponent as SearchSvg } from "../../../../assets/images/search.svg";
import { capitalize, slugToIndexMapper, slugToTitleMapper } from "../helper";
const SearchConfHeader = ({
  handleSiteSearch,
}: {
  handleSiteSearch: (query: string, indexName: string) => void;
}) => {
  const [query, setQuery] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { searchCategory } = useParams<{ searchCategory?: string }>();

  const capitalizeSearchCategory = `Search ${
    slugToTitleMapper[searchCategory || ""]
  }`;
  const handleSearch = async (event: any) => {
    event.preventDefault();
    if (!query) return;
    setSearchQuery(query);
    handleSiteSearch(query, slugToIndexMapper[searchCategory || ""]);
  };
  return (
    <section className="search-conf-page-header">
      <div className="header-content">
        <h1>{capitalize(searchQuery) || capitalizeSearchCategory}</h1>
        <form action="" className="site-search-form" onSubmit={handleSearch}>
          <div className="form-control">
            <input
              type="search"
              name="searchNews"
              id="searchnews"
              onChange={({ target }) => setQuery(target.value)}
              placeholder={capitalizeSearchCategory}
              style={{ fontFamily: "Arial, FontAwesome" }}
            />
          </div>
          <SearchSvg onClick={handleSearch} />
        </form>
      </div>
    </section>
  );
};

export default SearchConfHeader;
