import "./Rating.scss";
import React, { FunctionComponent } from "react";
import { ReactComponent as Star } from "../../assets/images/star.svg";
interface RatingProps {
  rating: number;
  handleClick?: (ratingValue: number) => any;
}

const Rating: FunctionComponent<RatingProps> = ({
  rating,
  handleClick: submitStarRating,
}: RatingProps) => {
  const handleClick = (ratingValue: number) => {
    submitStarRating ? submitStarRating(ratingValue) : null;
  };
  const ratingNum = Math.round(rating);
  return (
    <div className="rating">
      {Array(ratingNum)
        .fill(0)
        .map((val, index) => (
          <Star
            key={`star${index}`}
            className="colored"
            onClick={() => handleClick(index + 1)}
          />
        ))}

      {Array(5 - ratingNum)
        .fill(0)
        .map((val, index) => (
          <Star
            key={`star${index}`}
            onClick={() => handleClick(ratingNum + index + 1)}
          />
        ))}
    </div>
  );
};

export default Rating;
