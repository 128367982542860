export const featureTypes = [
  { value: "", key: "Choose feature" },
  { value: "Clinical News", key: "Clinical News" },
  { value: "Education Modules", key: "Education Modules" },
  { value: "Conference Highlights", key: "Conference Highlights" },
  { value: "Search", key: "Search" },
  { value: "My Account", key: "My Account" },
  { value: "Activity Preference", key: "Activity Preference" },
  { value: "My Messages", key: "My Messages" },
];
