import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ChangePasswordSuccessContent from "./ChangePasswordSuccessContent";
const ChangePasswordSuccess = () => {
  return (
    <>
      <Header />
      <ChangePasswordSuccessContent />
      <Footer />
    </>
  );
};

export default ChangePasswordSuccess;
