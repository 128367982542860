import React, { FunctionComponent } from "react";
import FilterModulesBody from "./FilterModulesBody";
import FilterModulesHeader from "./FilterModulesHeader";
import "./FilterModulesContent.scss";

const FilterModulesContent: FunctionComponent = () => {
  return (
    <main className="filter-mod-page">
      <FilterModulesHeader />
      <FilterModulesBody />
    </main>
  );
};

export default FilterModulesContent;
