import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ResetPasswordReqSuccessContent from "./ResetPasswordReqSuccessContent";
const ResetPasswordReqSuccess = () => {
  return (
    <>
      <Header />
      <ResetPasswordReqSuccessContent />
      <Footer />
    </>
  );
};

export default ResetPasswordReqSuccess;
