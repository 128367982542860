import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../../../components/Button";
import PopUp from "../../../../components/PopUp";
import ReportMessagePopUp from "../../../../components/ReportMessagePopUp";
import ThankYou from "../../../../components/ThankYouPopUp";
import { useAuth } from "../../../../navigation/Auth/ProvideAuth";
import { useProvideAuth } from "../../../../navigation/Auth/useProvideAuth";
import { MYMESSAGE } from "../../../../navigation/CONSTANT";
import communityMemService from "../../../../services/communityMembers";

const MyMessageClickThroughHeader: FunctionComponent = () => {
  const { auth } = useAuth();
  const { signout } = useProvideAuth();
  const [showReportForm, setShowReportForm] = useState<boolean>(false);
  const [thankYouPopUp, setThankYouPopUp] = useState<string>("");
  const { recipientId } = useParams<{ recipientId?: string }>();

  const history = useHistory();
  const [recipientDetails, setRecipientDetails] = useState<{ email: string }>({
    email: "",
  });

  const goBackToAllMessages = () => {
    history.push(MYMESSAGE);
  };

  const reportPopSubmitHandler = (block: boolean) => {
    setShowReportForm(false);
    if (block) setThankYouPopUp("YOU HAVE SUCCESSFULLY BLOCKED THE USER");
    else
      setThankYouPopUp(
        "Thank you for your message. Appropriate action will be taken"
      );
  };

  useEffect(() => {
    const getRecipientDetails = async () => {
      try {
        const userProfileResp =
          await communityMemService.getCommunityMemberById(
            auth?.access_token,
            Number(recipientId)
          );
        setRecipientDetails(userProfileResp);
      } catch (error: any) {
        if (error?.response?.status === 401) {
          //place your reentry code
          await signout();
        }
      }
    };
    getRecipientDetails();
  }, []);
  return (
    <section className="my-message-clth-page-header">
      <div className="header-content">
        <h1>My Messages</h1>
        <div className="action-btns">
          <Button
            type="button"
            size="size-lg"
            onClick={() => setShowReportForm(!showReportForm)}
            colorTheme="has-secondary-theme"
          >
            Report Messages
          </Button>
          <Button
            type="button"
            size="size-lg"
            onClick={goBackToAllMessages}
            colorTheme="has-secondary-theme"
          >
            Back
          </Button>
        </div>
      </div>
      {showReportForm ? (
        <PopUp>
          <ReportMessagePopUp
            memberEmail={recipientDetails.email}
            cancelPopUp={() => setShowReportForm(false)}
            submitHandler={reportPopSubmitHandler}
          />
        </PopUp>
      ) : (
        ""
      )}
      {thankYouPopUp && (
        <PopUp>
          <ThankYou
            msg={thankYouPopUp}
            submitHandler={() => setThankYouPopUp("")}
          />
        </PopUp>
      )}
    </section>
  );
};

export default MyMessageClickThroughHeader;
