import React, { FunctionComponent } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import LandingContent from "./LandingContent";

const Landing: FunctionComponent = () => {
  return (
    <>
      <Header />
      <LandingContent />
      <Footer />
    </>
  );
};

export default Landing;
