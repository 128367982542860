import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const getUserReportURL = "/report/users";
const getAdminReportParametersURL = "/report/getadminreportparameters";
const getGAReportURL = "/report";
const getActiveUserURL = "/report/user/active";

interface GAReportPayload {
  metrics: Array<string>;
  startDate: string;
  endDate: string;
}
const getRegisteredUserCount = async (
  accessToken: string,
  completed: boolean
) => {
  const response = await axios.get(baseUrl + getUserReportURL, {
    params: {
      completed,
    },
    headers: { "takeda-access-token": accessToken },
  });
  return response.data;
};
const getAdminReportParameters = async (accessToken: string) => {
  const response = await axios.get(baseUrl + getAdminReportParametersURL, {
    headers: { "takeda-access-token": accessToken },
  });
  return response.data;
};
const getCurrentlyLoggedUser = async (accessToken: string) => {
  const response = await axios.get(baseUrl + getActiveUserURL, {
    headers: { "takeda-access-token": accessToken },
  });
  return response.data;
};
const getGoogleAnalyticsReport = async (
  accessToken: string,
  gaPayload: GAReportPayload = {
    metrics: ["activeUsers", "sessions", "averageSessionDuration"],
    startDate: "2020-03-31",
    endDate: "today",
  }
) => {
  const config = {
    headers: { "takeda-access-token": accessToken },
  };
  const response = await axios.post(
    baseUrl + getGAReportURL,
    gaPayload,
    config
  );
  return response.data;
};

const reportStatService = {
  getRegisteredUserCount,
  getAdminReportParameters,
  getGoogleAnalyticsReport,
  getCurrentlyLoggedUser,
};

export default reportStatService;
