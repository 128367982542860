import React, { useRef, useState } from "react";
import { Formik, Form } from "formik";
import "./ResetPwdReqForm.scss";
import { resetPwdReqInitialValues } from "./resetPwdReqInitialValues";
import { resetPwdReqValidationSchema } from "./resetPwdReqValidationScheme";
import FormikControl from "../../../../components/Form/FormikControl";
import Button from "../../../../components/Button";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import TextError from "../../../../components/Form/TextError";
import ResetPasswordService from "../../../../services/resetpassword";
import {
  LOGIN,
  RESETPASSWORDREQUESTSUCCESS,
} from "../../../../navigation/CONSTANT";
import ReCaptcha from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { resetPwdReqErrorInformation } from "./errorTypes";

const ResetPwdReqForm = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const history = useHistory();
  const initialValues = resetPwdReqInitialValues;
  const validationSchema = resetPwdReqValidationSchema;
  const reCaptchaRef = useRef<ReCaptcha>(null);

  const onSubmit = async (values: any, actions: any) => {
    setLoading(true);
    setError("");
    let reCaptchaToken;
    if (reCaptchaRef.current) {
      reCaptchaToken = await reCaptchaRef.current.executeAsync();
      reCaptchaRef.current.reset();
    }
    try {
      const response = await ResetPasswordService.requestPasswordReset(
        values,
        reCaptchaToken
      );
      setLoading(false);
      history.push(RESETPASSWORDREQUESTSUCCESS);
    } catch (error: any) {
      setLoading(false);
      setError(
        resetPwdReqErrorInformation[error?.response?.data] ||
          "Error in resetting the password. Please try again later"
      );
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, isValid, setFieldValue }) => (
        <section className="reset-pwd-req-form">
          <div className="reset-pwd-req-form-container">
            <Form className="reset-pwd-form" onChange={() => setError("")}>
              <ReCaptcha
                ref={reCaptchaRef}
                sitekey={
                  process.env.REACT_APP_Recaptcha_Site_key
                    ? process.env.REACT_APP_Recaptcha_Site_key
                    : ""
                }
                size="invisible"
              />
              <FormikControl
                control="input"
                type="email"
                label="Enter Email Address"
                name="email"
              />
              <Button
                type="submit"
                size="size-xxlg"
                disabled={!isValid}
                colorTheme="has-primary-theme"
              >
                Confirm
              </Button>
              <LoadingSpinner isLoading={isLoading} />
              {error && <TextError>{error}</TextError>}
              <Link to={LOGIN}>Back to Sign in</Link>
            </Form>
          </div>
        </section>
      )}
    </Formik>
  );
};

export default ResetPwdReqForm;
