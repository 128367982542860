import React, { FunctionComponent, useState } from "react";
import { useHistory } from "react-router-dom";

import { ReactComponent as SearchSvg } from "../../../../assets/images/search.svg";
import { capitalize } from "../helper";
const SearchHeader = ({
  handleSiteSearch,
}: {
  handleSiteSearch: (query: string) => void;
}) => {
  const [query, setQuery] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const history = useHistory();

  const handleSearch = async (event: any) => {
    event.preventDefault();
    if (!query) return;
    setSearchQuery(query);
    handleSiteSearch(query);
  };
  return (
    <section className="search-page-header">
      <div className="header-content">
        <h1>{capitalize(searchQuery) || "SEARCH"}</h1>
        <form action="" className="site-search-form" onSubmit={handleSearch}>
          <div className="form-control">
            <input
              type="search"
              name="searchNews"
              id="searchnews"
              onChange={({ target }) => setQuery(target.value)}
              placeholder="Site Search"
              style={{ fontFamily: "Arial, FontAwesome" }}
            />
          </div>
          <SearchSvg onClick={handleSearch} />
        </form>
      </div>
    </section>
  );
};

export default SearchHeader;
