import React from "react";
import "./ResetPwdContent.scss";
import ResetPwdForm from "./ResetPwdForm";
import ResetPwdHeader from "./ResetPwdHeader";

const ResetPwdContent = () => {
  return (
    <main className="reset-pwd-page">
      <ResetPwdHeader />
      <ResetPwdForm />
    </main>
  );
};

export default ResetPwdContent;
