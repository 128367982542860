import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import MessageSuccessContent from "./MessageSuccessContent";
const MessageSuccess = () => {
  return (
    <>
      <Header />
      <MessageSuccessContent />
      <Footer />
    </>
  );
};

export default MessageSuccess;
