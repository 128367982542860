import React, { FunctionComponent } from "react";
import Button from "../Button";
import ClareRoberts from "../../assets/images/clareroberts.png";
import "./CMEDisplayCard.scss";
import { CMEDisplayCardProps } from "../../types";
import { extractFormat } from "./helper";
import { useHistory } from "react-router-dom";
import { CMEMODULES } from "../../navigation/CONSTANT";

const CMEDisplayCard = ({
  moduleInfo,
}: {
  moduleInfo: CMEDisplayCardProps;
}) => {
  const history = useHistory();
  const { id, title, dateLaunched, topic, pdfLinks, videoLinks } = moduleInfo;

  const moduleDetails: any = {
    topic,
    format: extractFormat(videoLinks, pdfLinks),
  };

  const goToModulePage = () => {
    history.push(CMEMODULES + "/" + id);
  };

  return (
    <div className="cme-display-card">
      <div className="upper-strip">
        <div className="card-info">
          <h4>{title}</h4>
          <div className="validity">
            <span>Date Launched:</span>
            {dateLaunched}
          </div>
        </div>
        <div className="module-act-btns">
          <div className="view-more-btn">
            <Button
              type="button"
              size="size-lg"
              colorTheme="has-secondary-theme"
              onClick={goToModulePage}
            >
              View
            </Button>
          </div>
        </div>
      </div>
      <div className="lower-strip">
        {Object.keys(moduleDetails).map((m, idx) => {
          if (!moduleDetails[m]) return;
          return (
            <div key={m} className="member-tag">
              <p>
                {m.charAt(0).toUpperCase() + m.slice(1)}: {moduleDetails[m]}
              </p>
              {idx < 1 ? <div className="space-breaker">|</div> : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CMEDisplayCard;
