import React, { FunctionComponent } from "react";
import Button from "../Button";
import "./TherapyDisplayCard.scss";
import { TherapyDisplayCardProps } from "../../types";
import { extractFormat } from "./helper";
import { useHistory } from "react-router-dom";

const TherapyDisplayCard = ({
  therapyInfo,
}: {
  therapyInfo: TherapyDisplayCardProps;
}) => {
  const history = useHistory();
  const { id, title, description, embed_video_link, pdf_link, therapyArea } =
    therapyInfo;

  const moduleDetails: any = {
    format: extractFormat(embed_video_link, pdf_link),
  };

  const viewLink = `/community/education/${therapyArea}`;
  const goToModulePage = () => {
    history.push(viewLink + "/" + id);
  };

  return (
    <div className="conf-display-card">
      <div className="upper-strip">
        <div className="card-info">
          <h4>{title}</h4>
          <div className="description">
            <span>Description:</span>
            {description}
          </div>
        </div>
        <div className="module-act-btns">
          <div className="view-more-btn">
            <Button
              type="button"
              size="size-lg"
              colorTheme="has-secondary-theme"
              onClick={goToModulePage}
            >
              View
            </Button>
          </div>
        </div>
      </div>
      <div className="lower-strip">
        {Object.keys(moduleDetails).map((m, idx) => {
          if (!moduleDetails[m]) return;
          return (
            <div key={m} className="member-tag">
              <p>
                {m.charAt(0).toUpperCase() + m.slice(1)}: {moduleDetails[m]}
              </p>
              {idx < moduleDetails.length - 1 ? (
                <div className="space-breaker">|</div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TherapyDisplayCard;
