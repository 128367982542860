import {
  CLINICALNEWS,
  CMEMODULES,
  CONFERENCEHIGHLIGHT,
  EDUCATION,
  GASTROENTEROLOGY,
  IMMUNOLOGY,
  NEWS,
  ONCOLOGY,
  RAREGENETICDISEASE,
  RAREHAEMATOLOGY,
} from "../../../navigation/CONSTANT";
import InformationImage from "../../../assets/images/information.png";
import DoctorWalkingImage from "../../../assets/images/doctors-walking.png";

export const servicecards = [
  {
    title: "Information",
    cardImage: InformationImage,
    subTitle: "News & Journal Watch",
    cardLink: NEWS,
    categories: [
      {
        categoryTitle: "Clinical News",
        categoryLink: CLINICALNEWS,
      },
    ],
  },
  {
    title: "Education",
    cardImage: DoctorWalkingImage,
    cardLink: EDUCATION,
    subTitle: "Therapy Areas",
    categories: [
      {
        categoryTitle: "Rare Haematology",
        categoryLink: RAREHAEMATOLOGY,
      },
      {
        categoryTitle: "Rare Genetic Diseases",
        categoryLink: RAREGENETICDISEASE,
      },
      {
        categoryTitle: "Oncology",
        categoryLink: ONCOLOGY,
      },
      {
        categoryTitle: "Immunology/HAE",
        categoryLink: IMMUNOLOGY,
      },
      {
        categoryTitle: "Gastroenterology",
        categoryLink: GASTROENTEROLOGY,
      },
    ],
  },
];
