import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Button from "../../../components/Button";
import SkeletonArticleHeading from "../../../components/Skeleton/SkeletonArticleHeading";
import { useNewsContext } from "../../../context/News";
import { useAuth } from "../../../navigation/Auth/ProvideAuth";
import { useProvideAuth } from "../../../navigation/Auth/useProvideAuth";
import newsService from "../../../services/news";
import { getArticleByArticleId, getNewsRoute, getNewsType } from "../helper";
import "./ArticleHeading.scss";

const ArticleHeading = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { auth } = useAuth();
  const { signout } = useProvideAuth();
  const { articleType, articleId } =
    useParams<{ articleType?: string; articleId?: string }>();
  const { news, setNews } = useNewsContext();

  const selectedNews = getArticleByArticleId(
    news,
    getNewsType(articleType),
    articleId
  );

  useEffect(() => {
    const getAllNewsInformation = async () => {
      setLoading(true);
      try {
        const typeNewsResp = await newsService.getNews(
          articleId,
          auth?.access_token
        );
        setNews({
          type: `GET_NEWS_ARTICLE`,
          payload: {
            articleDetails: typeNewsResp.data,
            articleType: getNewsType(articleType),
          },
        });
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        if (error?.response?.status === 401) {
          //place your reentry code
          await signout();
        }
      }
    };
    getAllNewsInformation();
  }, []);

  const saveArticleHandler = async () => {
    if (isSaved) {
      await newsService.unsaveArticle(articleId, auth?.access_token);
      setNews({
        type: `SAVE_NEWS_ARTICLE`,
        payload: {
          articleId,
          articleType: getNewsType(articleType),
        },
      });
    } else {
      await newsService.saveArticle(articleId, auth?.access_token);
      setNews({
        type: `SAVE_NEWS_ARTICLE`,
        payload: {
          articleId,
          articleType: getNewsType(articleType),
        },
      });
    }
  };

  const { title, feature_image, excerpt, isSaved } = selectedNews
    ? selectedNews
    : {
        title: "",
        feature_image: "",
        excerpt: "",
        isSaved: false,
      };
  return (
    <section className="article-heading">
      {loading && <SkeletonArticleHeading />}
      {!loading && (
        <div className="title-container">
          <h1>{title}</h1>
          <Button
            type="button"
            size="size-lg"
            colorTheme={isSaved ? "has-primary-theme" : "has-secondary-theme"}
            onClick={saveArticleHandler}
          >
            {isSaved ? "Saved" : "Save Article"}
          </Button>
        </div>
      )}
    </section>
  );
};

export default ArticleHeading;
