import React, { FunctionComponent } from "react";
import RareDiseaseThumbnail from "../../assets/images/confraredisease.png";
import { ReactComponent as ConfPlay } from "../../assets/images/confplay.svg";
import { ReactComponent as CMEDoc } from "../../assets/images/CMEdoc.svg";
import Rating from "../Rating";
import { CMECardType } from "../../types";
import "./CMECard.scss";
import { useHistory } from "react-router";
import { CMEMODULES, CONFERENCEHIGHLIGHT } from "../../navigation/CONSTANT";

const CMECard: FunctionComponent<CMECardType> = ({
  id,
  title,
  feature_image,
  description,
  pdfLink,
  videoLinks,
}: CMECardType) => {
  const history = useHistory();
  const handleClick = () => {
    history.push(CMEMODULES + "/" + id);
  };
  return (
    <div className="cme-card" onClick={handleClick}>
      <div className="cme-card-info">
        <div className="cme-card-thumbnail">
          <img src={feature_image} alt={title} />
        </div>
        <h3 className="cme-card-title">{title}</h3>
        <p className="cme-card-content">{description}</p>
      </div>

      <div className="cme-card-action">
        <div className="cme-card-files">
          {pdfLink?.length > 0 ? (
            <div className="cme-card-doc">
              <CMEDoc />
            </div>
          ) : null}
          {videoLinks?.length > 0 ? (
            <div className="cme-card-video">
              <ConfPlay />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CMECard;
