import React from "react";
import "./ChangePasswordSuccessContent.scss";
import { ReactComponent as ResetPasswordSVG } from "../../../assets/images/resetpassword.svg";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";

const ChangePasswordSuccessContent = () => {
  return (
    <main className="chg-pwd-succ-page">
      <section className="chg-pwd-succ-content">
        <ResetPasswordSVG />
        <h3>Change Password</h3>
        <p>
          Your password has been changed. You can log in with the new pasword.
        </p>
        <Link to="/login">
          <Button type="button" size="size-lg" colorTheme="has-primary-theme">
            Login
          </Button>
        </Link>
      </section>
    </main>
  );
};

export default ChangePasswordSuccessContent;
