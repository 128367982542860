import React from "react";
import CommunityMemberCard from "../CommunityMemberCard";
import "./CommunityMemberBoard.scss";
import { ProfileInfoProps } from "../../types";
const CommunityMemberBoard = ({
  profiles,
}: {
  profiles: Array<ProfileInfoProps>;
}) => {
  return (
    <div className="community-member-board">
      {profiles.map((profile) => (
        <CommunityMemberCard key={profile.id} profileInfo={profile} />
      ))}
    </div>
  );
};

export default CommunityMemberBoard;
