import React from "react";
import Shimmer from "./Shimmer";
import SkeletonElement from "./SkeletonElement";

const SkeletonConfBody = () => {
  return (
    <div className="skeleton-wrapper conf-main">
      <div className="conf-img">
        <SkeletonElement type="banner" />
      </div>
      <div className="conf-info">
        {/* <SkeletonElement type="button" /> */}
        {/* <SkeletonElement type="rating" /> */}
      </div>

      <div className="conf-content">
        <SkeletonElement type="video" />
      </div>
      <Shimmer />
    </div>
  );
};

export default SkeletonConfBody;
