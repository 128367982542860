import algoliasearch from "algoliasearch";
import { ConfDisplayCardProps } from "../../../types";
interface SlugToTitleProps {
  [slug: string]: string;
}

export const extractConf = (result: any, therapyArea: string | undefined) => {
  return result.hits.map(
    ({
      objectID,
      title,
      feature_image,
      embed_video_link,
      description,
      pdf_link,
    }: any) => {
      return {
        id: objectID,
        title,
        feature_image,
        embed_video_link,
        description,
        pdf_link,
        therapyArea,
      };
    }
  );
};

export const searchConferences = async (query: string, indexName: string) => {
  if (!query || !indexName) {
    return { hits: [] };
  }

  const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID as string,
    process.env.REACT_APP_ALGOLIA_APP_KEY as string
  );

  const index = await searchClient.initIndex(indexName);
  const result = await index.search(query);

  return result;
};

const capitalizeWord = (word: string): string => {
  return word.slice(0, 1).toUpperCase() + word.slice(1);
};

export const capitalize = (query: string): string => {
  return query
    .split(" ")
    .map((w) => capitalizeWord(w))
    .join(" ");
};

export const slugToTitleMapper: SlugToTitleProps = {
  rarehaematology: "Rare Haematology",
  raregeneticdisease: "Rare Genetic Diseases",
  oncology: "Oncology",
  immunology: "Immunology",
  gastroenterology: "Gastroenterology",
};
export const slugToIndexMapper: SlugToTitleProps = {
  rarehaematology: "rare_haematology_posts",
  raregeneticdisease: "rare_genetic_diseases_posts",
  oncology: "oncology_posts",
  immunology: "immunology_posts",
  gastroenterology: "gastroenterology_posts",
};
