export const extractArticles = (result: any) => {
  return result.hits.map(
    ({ objectID, title, plaintext, feature_image, rating }: any) => {
      return {
        id: objectID,
        title,
        excerpt: plaintext.slice(0, 650),
        feature_image,
        rating,
        isSearchResult: true,
      };
    }
  );
};
