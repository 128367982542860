import "./UpdateProfileForm.scss";
import { Formik, Form, ErrorMessage } from "formik";
import React, { useEffect, useRef, useState } from "react";
import FormikControl from "../../../../components/Form/FormikControl";
import ImagePreviewComponent from "../../../../components/Form/ImagePreviewComponent";
import TextError from "../../../../components/Form/TextError";
import { countryTypes } from "./countryTypes";
import { seniorityTypes } from "./seniorityTypes";
import { specialityTypes } from "./specialityTypes";
import { titleTypes } from "./titleTypes";
import Button from "../../../../components/Button";
import { useHistory } from "react-router";
import { registrationInitialValues } from "./initialValues";
import { registrationValidationSchema } from "./validationSchema";
import { updateProfileErrorInformation } from "./errorTypes";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import registerService from "../../../../services/register";
import { useAuth } from "../../../../navigation/Auth/ProvideAuth";
import TextSuccess from "../../../../components/Form/TextSuccess";
import { useLoggedUserContext } from "../../../../context/LoggedUser";
import { useProvideAuth } from "../../../../navigation/Auth/useProvideAuth";

const UpdateProfileForm = () => {
  const { auth } = useAuth();
  const { loggedUserInfo, setLoggedUserInfo } = useLoggedUserContext();
  const history = useHistory();
  const { signout } = useProvideAuth();

  const [error, setError] = useState<string | undefined>();
  const [notification, setNotification] = useState<string | undefined>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const seniorityOptions = seniorityTypes;
  const specialityOptions = specialityTypes;
  const regionOptions = countryTypes;
  const titleOptions = titleTypes;

  // to hide choose file
  const uploadFileRef: any = useRef(null);
  const initialValues: any = {
    ...registrationInitialValues,
    ...loggedUserInfo,
  };
  const validationSchema: any = registrationValidationSchema;

  const onSubmit = async (values: any, actions: any) => {
    setLoading(true);
    setNotification("");
    setError("");
    // identify the image status -> initial values
    const isImageUnchanged = values.image === initialValues.image;
    const isImageDeleted = !isImageUnchanged && !values.image;
    const imageStatus = isImageUnchanged
      ? "IMAGE_UNCHANGED"
      : isImageDeleted
      ? "IMAGE_DELETED"
      : "IMAGE_UPDATED";

    try {
      const response = await registerService.updateUser(
        { imageStatus, ...values },
        auth?.access_token
      );
      imageStatus === "IMAGE_DELETED"
        ? setLoggedUserInfo({
            type: "UPDATE_USER_DETAILS",
            payload: {
              image: null,
              ...response?.data,
            },
          })
        : setLoggedUserInfo({
            type: "UPDATE_USER_DETAILS",
            payload: {
              ...response?.data,
            },
          });

      setLoading(false);
      setNotification("Profile successfully updated");
    } catch (error: any) {
      setLoading(false);
      setError(
        updateProfileErrorInformation[error?.response?.data?.message] ||
          "Error in updating Profile"
      );
      if (error?.response?.status === 401) {
        //place your reentry code
        await signout();
      }
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, isValid, setFieldValue }) => (
        <section className="update-profile-form">
          <div className="form-container">
            <Form
              className="update-form"
              onChange={() => {
                setNotification("");
                setError("");
              }}
            >
              <ImagePreviewComponent file={values.image} />
              <div className="form-control">
                <input
                  hidden
                  ref={uploadFileRef}
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={(event: any) => {
                    setFieldValue("image", event.target.files[0]);
                  }}
                />
                <button
                  type="button"
                  onClick={() => {
                    uploadFileRef.current.click();
                  }}
                  className="upload-button"
                >
                  <p>Upload a photo</p>
                </button>
                {values.image && (
                  <button
                    type="button"
                    onClick={() => {
                      setFieldValue("image", null);
                    }}
                    className="remove-button"
                  >
                    <p>Remove photo</p>
                  </button>
                )}
                <ErrorMessage component={TextError} name="image" />
              </div>
              <FormikControl
                control="select"
                label="Title"
                name="title"
                options={titleOptions}
              />
              <FormikControl
                control="input"
                type="text"
                label="First Name"
                name="firstName"
              />
              <FormikControl
                control="input"
                type="text"
                label="Last Name"
                name="lastName"
              />
              <FormikControl
                control="select"
                label="Region"
                name="region"
                options={regionOptions}
              />

              <FormikControl
                control="input"
                type="text"
                label="Organisation (optional)"
                name="organisation"
              />
              <FormikControl
                control="select"
                label="Speciality (optional)"
                name="speciality"
                options={specialityOptions}
              />
              <FormikControl
                control="select"
                label="Seniority (optional)"
                name="seniority"
                options={seniorityOptions}
              />

              <FormikControl
                control="input"
                type="text"
                label="Professional Number (optional)"
                name="professionalNumber"
              />

              <FormikControl
                control="input"
                type="tel"
                label="Mobile Number (optional)"
                name="mobileNumber"
              />

              <Button
                type="submit"
                size="size-xxlg"
                disabled={!isValid}
                colorTheme="has-primary-theme"
              >
                Save changes
              </Button>
              <LoadingSpinner isLoading={isLoading} />
              {error && <TextError>{error}</TextError>}
              {notification && <TextSuccess>{notification}</TextSuccess>}
            </Form>
          </div>
        </section>
      )}
    </Formik>
  );
};

export default UpdateProfileForm;
