import React, { FunctionComponent } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import FeedbackContent from "./FeedbackContent";

const Feedback: FunctionComponent = () => {
  return (
    <>
      <Header />
      <FeedbackContent />
      <Footer />
    </>
  );
};

export default Feedback;
