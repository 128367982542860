import React, { FunctionComponent } from "react";
import "./MessageCounter.scss";
interface MessageCounterProps {
  msgCount: number;
}

const MessageCounter: FunctionComponent<MessageCounterProps> = ({
  msgCount,
}: MessageCounterProps) => {
  if (!msgCount) return null;
  return (
    <div className="msg-counter-container">
      <div className="msg-count">{msgCount}</div>
    </div>
  );
};

export default MessageCounter;
