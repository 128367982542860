import React, { FunctionComponent } from "react";
import RareDiseaseThumbnail from "../../assets/images/confraredisease.png";
import { ReactComponent as ConfPlay } from "../../assets/images/confplay.svg";
import { ReactComponent as Confdoc } from "../../assets/images/confdoc.svg";
import Rating from "../Rating";
import { ConferenceCardType } from "../../types";
import "./ConferenceCard.scss";
import { useHistory } from "react-router";
import { CONFERENCEHIGHLIGHT } from "../../navigation/CONSTANT";

const ConferenceCard: FunctionComponent<ConferenceCardType> = ({
  id,
  title,
  feature_image,
  description,
  rating,
}: ConferenceCardType) => {
  const history = useHistory();
  const handleClick = () => {
    history.push(CONFERENCEHIGHLIGHT + "/" + id);
  };
  return (
    <div className="conference-card" onClick={handleClick}>
      <div className="conf-card-info">
        <div className="conf-card-thumbnail">
          <img src={feature_image} alt={title} />
        </div>
        <h3 className="conf-card-title">{title}</h3>
        <p className="conf-card-content">{description}</p>
      </div>

      <div className="conf-card-action">
        <div className="conf-card-files">
          <div className="conf-card-doc">
            <Confdoc />
          </div>
          <div className="conf-card-video">
            <ConfPlay />
          </div>
        </div>
        <Rating rating={rating ? rating : 0} />
      </div>
    </div>
  );
};

export default ConferenceCard;
