import React from "react";
import "./CookiePolicyBody.scss";

const CookiePolicyBody = () => {
  return (
    <div className="cookie-policy-body">
      <div className="content">
        <h3>1. Cookies that we use</h3>
        <p>
          1.1 Blocking all cookies will have a negative impact upon the
          usability of many websites.
        </p>
        <p>
          1.2 If you block cookies, you will not be a ble to use all the
          features on our website.
        </p>
        <p>1.3 We use cookies for the following ourposes:</p>
        <p>
          (a) authentication- we use cookies to identify you when you visit our
          website, if you are logged in, as you navigate our website, and to
          protect our website a nd services generally.
        </p>
        <p>
          (b) personalization - we use cookies to stare information aDout your
          preferences and to personalise our website for you.
        </p>
        <p>
          (c) advertising - we use cookies to help us to display advertisements
          that will be relevant to you.
        </p>
        <p>
          (d) analysis - we use cookies to help us to analyse the use and
          performance of our website and services.
        </p>

        <h3>2. How we use cookies</h3>

        <p>
          The ICMEA HCP COMMUNITY PLATFORM may place and access certain
          first-party Cookies on your computer or device. First-party Cookies
          are those placed directly by us and are used onIy by us. We use
          Cookies to facilitate and improve your experience of the ICMEA HCP
          COMMUNITY PLATFORM and to provide and improve our services. We have
          carefully chosen these Cookies and have ta ken steps to ensure that
          your privacy and personal data is protected and respected at all
          times.
        </p>

        <p>
          By using the ICMEA HCP COMMUNITY PLATFORM, you may also receive
          certain third-party Cookies on your computer or device. third-party
          Cookies are those placed by websites, services, and/or parties other
          than us. Third-party Cookies are used on the ICMEA HCP COMMUNITY
          PLATFORM for insert description of use(s) of third-party cookies. For
          more details, please refer to the table below. These Cookies are not
          integral to the functioning of the ICMEA HCP COMMUNITY PLATFORM and
          your use and experience of the ICMEA HCP COMMUNITY PLATFORM will not
          be impaired by refusing consent to them.
        </p>

        <p>
          All Cookies used by and on the ICMEA HCP COMMUNITY PLATFORM are used
          in accordance with current Cookie Law.
        </p>

        <p>
          Before Cookies are placed on your computer or device, you will be
          shown a prompt, e.g. pop-up requesting your consent to set those
          Cookies. By giving your consent to the placing of Cookies you are
          enabling us to provide the Dest possible experience and service to
          you. You may, if you wish, deny consent to the placing of Cookies;
          however certain features of the ICMEA HCP COMMUNITY PLATFORM may not
          function fully or as intended. You will be given the opportunity to
          allow only first-party Cookies and block third-party Cookies.
        </p>

        <p>
          In addition to the controls that we provide, you can choose to enable
          or disable Cookies in your internet browser. Most internet browsers
          also enable you to choose whether you wish to disable all Cookies or
          only third-party Cookies. By default, most internet browsers accept
          Cookies, but this can be changed. For further details, please consult
          the help menu in your internet browser or the documentation that came
          with your device.
        </p>

        <p>
          You can choose to delete Cookies an your computer or device at any
          time, however you may lose any information that enables you to access
          Our Site more quickly and efficiently including, but not limited to,
          login and personalization settings.
        </p>

        <p>
          It is recommended that you keep your internet browser and operating
          system up-to-date and that you consuIt the help and guidance provided
          by the developer of your internet browser and manufacturer of your
          computer or device if you are unsure about adjusting your privacy
          settings.
        </p>
      </div>
    </div>
  );
};

export default CookiePolicyBody;
