import React, { FunctionComponent } from "react";
import { ProfileInfoProps } from "../../../types";
import DefaultAvatar from "../../../assets/images/default-avatar.png";
import { useHistory } from "react-router-dom";
import { VIEWPROFILEBASE } from "../../../navigation/CONSTANT";
interface MemberCardProfileProps {
  profileInfo: ProfileInfoProps;
}

const MemberCardProfile: FunctionComponent<MemberCardProfileProps> = ({
  profileInfo: { image, firstName, lastName, title, id },
}: MemberCardProfileProps) => {
  const history = useHistory();
  return (
    <div
      className="mem-card-profile"
      onClick={() => history.push(VIEWPROFILEBASE + "/" + id)}
    >
      <img
        src={image || DefaultAvatar}
        alt={[title, firstName, lastName].join(" ")}
      />
    </div>
  );
};

export default MemberCardProfile;
