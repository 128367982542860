import React, { useEffect, useState } from "react";
import "./InformationHeading.scss";
import { SEARCHARTICLE, SEARCHARTICLEBASE } from "../../../navigation/CONSTANT";
import { useHistory } from "react-router";

const InformationHeading = () => {
  const [query, setQuery] = useState<string>("");
  const history = useHistory();

  const handleSearch = async (event: any) => {
    event.preventDefault();
    if (!query) return;
    history.push(SEARCHARTICLEBASE + query);
  };

  return (
    <section className="information-heading">
      <div className="title-container">
        <h1>News</h1>
        <form action="" className="article-search-form" onSubmit={handleSearch}>
          <input
            type="search"
            name="searchNews"
            id="searchnews"
            onChange={({ target }) => setQuery(target.value)}
            placeholder="&#xF002; Article Search"
            style={{ fontFamily: "Arial, FontAwesome" }}
          />
        </form>
      </div>
    </section>
  );
};

export default InformationHeading;
