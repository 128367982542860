import React, { FunctionComponent } from "react";
import "./TherapyBoard.scss";
import { TherapyCardType } from "../../types";
import TherapyCard from "../TherapyCard";
import { slugToTitleMapper } from "../../pages/SearchConf/SearchConfContent/helper";
interface TherapyBoardProps {
  therapyposts: Array<TherapyCardType>;
  slug: string;
}

const TherapyBoard: FunctionComponent<TherapyBoardProps> = ({
  therapyposts,
  slug,
}: TherapyBoardProps) => {
  {
    return therapyposts?.length > 0 ? (
      <div className="therapy-board">
        {therapyposts.map((therapypost) => (
          <TherapyCard key={therapypost.id} {...therapypost} slug={slug} />
        ))}
      </div>
    ) : (
      <div className="no-post-msg">
        <p>No {slugToTitleMapper[slug]} post found</p>
      </div>
    );
  }
};

export default TherapyBoard;
