import {
  REGISTER,
  ABOUTUS,
  CONTACTUS,
  LOGIN,
  HOME,
  COMMUNITY,
  FEEDBACK,
  LOGOUT,
  SEARCH,
} from "../../navigation/CONSTANT";

interface NavigationItemType {
  type: string;
  navigateTo: string;
  label: string;
  handleClick?: () => Promise<void>;
  size?: string;
}

export const unauthenticatedNavItems: Array<NavigationItemType> = [
  // { type: "normal", navigateTo: REGISTER, label: "Register" },
  { type: "normal", navigateTo: ABOUTUS, label: "About us" },
  { type: "normal", navigateTo: CONTACTUS, label: "Contact us" },
  {
    type: "button",
    navigateTo: LOGIN,
    label: "Join / Log In",
    size: "size-sm",
  },
];

export const authenticatedNavItems: Array<NavigationItemType> = [
  { type: "normal", navigateTo: HOME, label: "Home" },
  { type: "normal", navigateTo: COMMUNITY, label: "Community" },
  { type: "normal", navigateTo: ABOUTUS, label: "About us" },
  { type: "normal", navigateTo: CONTACTUS, label: "Contact us" },
  { type: "icon", navigateTo: SEARCH, label: "Search" },
  {
    type: "button",
    navigateTo: FEEDBACK,
    label: "Your Feedback",
    size: "size-sml",
  },
  {
    type: "dropdown",
    navigateTo: LOGOUT,
    label: "Log Out",
    size: "size-sm",
  },
];
