import React, { useEffect } from "react";
import { useAuth } from "../../navigation/Auth/ProvideAuth";
import {
  unauthenticatedNavItems,
  authenticatedNavItems,
} from "./navigationItemsList";
import NavigationItem from "./NavigationItem";
import { useProvideAuth } from "../../navigation/Auth/useProvideAuth";
import { useLocation } from "react-router";
import loginService from "../../services/login";
import DropDownItem from "./DropdownItem";
import { useLoggedUserContext } from "../../context/LoggedUser";
import registerService from "../../services/register";
import messageService from "../../services/messages";
import { signOutUser } from "../../helper";

interface NavigationItemType {
  type: string;
  navigateTo: string;
  label: string;
  handleClick?: () => Promise<void>;
  size?: string;
}

const NavigationMenu = () => {
  const { auth } = useAuth();
  const { loggedUserInfo, setLoggedUserInfo } = useLoggedUserContext();
  const { signout } = useProvideAuth();
  const location = useLocation();

  const handleClick = async () => {
    await signOutUser(auth?.access_token, auth?.id_token);
    await signout();
  };
  const isAuthenticated = auth?.id_token ? true : false;
  const navItems: Array<NavigationItemType> = isAuthenticated
    ? authenticatedNavItems
    : unauthenticatedNavItems;

  useEffect(() => {
    const getLoggedUserInfo = async () => {
      if (!isAuthenticated) return;
      try {
        const unseenMsgCountResp = await messageService.getUnseenMsgCount(
          auth?.access_token
        );
        const userDetails = await registerService.getUser(auth?.access_token);
        setLoggedUserInfo({
          type: "INIT_USER_DETAILS",
          payload: {
            ...userDetails,
            unseenMsgCount: unseenMsgCountResp?.count || 0,
          },
        });
      } catch (error: any) {
        if (error?.response?.status === 401) {
          //place your reentry code
          await signout();
        }
      }
    };
    getLoggedUserInfo();
  }, []);

  return (
    <ul className="nav-items">
      {navItems.map((navItem, index) => {
        if (navItem.type === "dropdown") {
          return (
            <DropDownItem
              key={`navItem${index}`}
              handleLogout={handleClick}
              loggedUserDetails={loggedUserInfo}
            />
          );
        }
        return (
          <NavigationItem
            key={`navItem${index}`}
            navigationProps={navItem}
            active={navItem.navigateTo === location.pathname}
          />
        );
      })}
    </ul>
  );
};

export default NavigationMenu;
