import React, { useEffect, useState } from "react";
import ViewProfileHeader from "./ViewProfileHeader";
import ViewProfileBody from "./ViewProfileBody";
import "./ViewProfileContent.scss";
import { useParams } from "react-router-dom";
import { ProfileInfoProps } from "../../../types";
import communityMemService from "../../../services/communityMembers";
import { useAuth } from "../../../navigation/Auth/ProvideAuth";

const initialProfileValue = {
  email: "",
  firstName: "",
  id: null,
  image: null,
  lastName: "",
  organisation: "",
  professionalNumber: "",
  region: "",
  seniority: "",
  speciality: "",
  takedaId: "",
  title: "",
};

const ViewProfileContent = () => {
  const { auth } = useAuth();
  const { profileId } = {
    profileId: null,
    ...useParams(),
  };

  const [viewProfileDetails, setViewProfileDetails] =
    useState<ProfileInfoProps>(initialProfileValue);

  useEffect(() => {
    const getProfileByProfileId = async () => {
      const userProfileResp = await communityMemService.getCommunityMemberById(
        auth?.access_token,
        profileId
      );
      setViewProfileDetails(userProfileResp);
    };

    getProfileByProfileId();
  }, [profileId]);
  return (
    <main className="view-profile-page">
      <ViewProfileHeader profileDetails={viewProfileDetails} />
      <ViewProfileBody profileDetails={viewProfileDetails} />
    </main>
  );
};

export default ViewProfileContent;
