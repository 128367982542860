import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const sendMessageURL = "/user/message";
const getConvURL = "/user/conversation";
const reportMessageURL = "/user/message/report";
const unseenMessageCountURL = "/user/unseen-messages/count";
const shareContentURL = "/user/share";

interface sendMessagePayload {
  messageText: string;
  receiverId: number | null;
}

interface reportMessagePayload {
  reporteeId: number;
  reason: string;
  actionOnReport: string;
}
interface shareContentPayload {
  resourceLink: string;
  receiverId: number;
  senderMessage: string;
}

const getMessages = async (accessToken: string, page: number) => {
  const response = await axios.get(baseUrl + sendMessageURL, {
    params: {
      limit: 3,
      page,
    },
    headers: { "takeda-access-token": accessToken },
  });
  return response.data;
};

const sendMessage = async (
  messageDetails: sendMessagePayload,
  accessToken: string
) => {
  if (!messageDetails.receiverId) return;
  const config = {
    headers: { "takeda-access-token": accessToken },
  };
  const response = await axios.post(
    baseUrl + sendMessageURL,
    messageDetails,
    config
  );
  return response.data;
};

const getConversation = async (
  accessToken: string,
  page: number,
  recipientId: number | null
) => {
  if (!recipientId) return;
  const response = await axios.get(baseUrl + getConvURL, {
    params: {
      limit: 3,
      page,
      recipientId,
    },
    headers: { "takeda-access-token": accessToken },
  });
  return response.data;
};

const reportMessage = async (
  reportMessageDetails: reportMessagePayload,
  accessToken: string
) => {
  const config = {
    headers: { "takeda-access-token": accessToken },
  };
  const response = await axios.post(
    baseUrl + reportMessageURL,
    reportMessageDetails,
    config
  );
  return response.data;
};

const getUnseenMsgCount = async (accessToken: string) => {
  const response = await axios.get(baseUrl + unseenMessageCountURL, {
    headers: { "takeda-access-token": accessToken },
  });
  return response.data;
};

const shareContent = async (
  shareContentDetails: shareContentPayload,
  accessToken: string
) => {
  const config = {
    headers: { "takeda-access-token": accessToken },
  };
  const response = await axios.post(
    baseUrl + shareContentURL,
    shareContentDetails,
    config
  );
  return response.data;
};

const messageService = {
  sendMessage,
  getMessages,
  getConversation,
  reportMessage,
  getUnseenMsgCount,
  shareContent,
};

export default messageService;
