import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useProvideAuth } from "../../../navigation/Auth/useProvideAuth";
import { prepareErrorMsg } from "../../ArticleAddComment/helper";
import Button from "../../Button";
import FormikControl from "../../Form/FormikControl";
import TextError from "../../Form/TextError";
import LoadingSpinner from "../../LoadingSpinner";
import { replyCommentErrorInformation } from "./errorTypes";

import { ReplyCommentInitialVal } from "./ReplyCommentInitialValues";
import { ReplyCommentValidationSchema } from "./ReplyCommentValidationScheme";

interface ArticleReplyComment {
  submitReplyHandler: (replyText: string) => void;
}

const ArticleReplyComment = ({ submitReplyHandler }: ArticleReplyComment) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const { signout } = useProvideAuth();

  const initialValues = ReplyCommentInitialVal;
  const validationSchema = ReplyCommentValidationSchema;

  const onSubmit = async (values: any, actions: any) => {
    setLoading(true);
    setError("");
    const { replyText } = values;
    try {
      await submitReplyHandler(replyText);
      actions.resetForm();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.data?.status === "inappropriateContent") {
        setError(prepareErrorMsg(error?.response?.data?.data));
      } else {
        setError(
          replyCommentErrorInformation[error?.response?.data] ||
            "Error in adding reply"
        );
      }
      if (error?.response?.status === 401) {
        //place your reentry code
        await signout();
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, isValid, setFieldValue }) => (
        <section className="article-reply-comment">
          <div className="reply-form-container">
            <Form
              className="reply-form"
              onChange={() => {
                setError("");
              }}
            >
              <FormikControl
                control="textarea"
                label="New Comment"
                name="replyText"
                required
              />
              <Button
                type="submit"
                size="size-lg"
                disabled={!isValid}
                colorTheme="has-primary-theme"
              >
                Post
              </Button>
            </Form>
            {error && <TextError>{error}</TextError>}
            <LoadingSpinner isLoading={isLoading} />
          </div>
        </section>
      )}
    </Formik>
  );
};

export default ArticleReplyComment;
