import React, { FunctionComponent } from "react";
import { useParams } from "react-router";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import RegistrationSuccessContent from "./RegistrationSuccessContent";

const RegistrationSuccess: FunctionComponent = () => {
  const { verificationStatus } = {
    verificationStatus: "notverified",
    ...useParams(),
  };
  const isVerified = verificationStatus === "verified";
  return (
    <>
      <Header />
      <RegistrationSuccessContent takedaVerifiedHCP={isVerified} />
      <Footer />
    </>
  );
};

export default RegistrationSuccess;
