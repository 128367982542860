import React, { FunctionComponent, useEffect, useState } from "react";
import CommunityMemberBoard from "../../../../components/CommunityMemberBoard";
import PaginationByCount from "../../../../components/PaginationByCount";
import SyncLoaderSpinner from "../../../../components/SyncLoader";
import { useAuth } from "../../../../navigation/Auth/ProvideAuth";
import { useProvideAuth } from "../../../../navigation/Auth/useProvideAuth";
import communityMemService from "../../../../services/communityMembers";
import { ProfileInfoProps } from "../../../../types";
import "./CommunityMembersBody.scss";

const CommunityMembersBody: FunctionComponent = () => {
  const { auth } = useAuth();
  const { signout } = useProvideAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [commMemProfiles, setCommMemProfiles] = useState<{
    users: Array<ProfileInfoProps>;
    totalCount: number;
  }>({ users: [], totalCount: 0 });

  const changePage = (changeVal: number) => {
    setPageNumber(pageNumber + changeVal);
  };

  useEffect(() => {
    const getCommMemProfilesResp = async () => {
      setLoading(true);
      try {
        const commMemProfileResp =
          await communityMemService.getAllCommunityMember(
            auth?.access_token,
            pageNumber
          );
        setLoading(false);
        setCommMemProfiles(commMemProfileResp);
      } catch (error: any) {
        if (error?.response?.status === 401) {
          //place your reentry code
          await signout();
        }
      }
    };
    getCommMemProfilesResp();
  }, [pageNumber]);
  return (
    <section className="comm-mem-page-body">
      {loading ? (
        <SyncLoaderSpinner isLoading={loading} override="" />
      ) : (
        <>
          {commMemProfiles?.users?.length > 0 ? (
            <>
              <CommunityMemberBoard profiles={commMemProfiles?.users} />
              <PaginationByCount
                currentPage={pageNumber}
                totalCount={commMemProfiles?.totalCount}
                limit={6}
                changePage={changePage}
              />
            </>
          ) : (
            <div className="no-user-found">
              <p>No other members found</p>
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default CommunityMembersBody;
