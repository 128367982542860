import React, { useEffect, useState } from "react";
import PaginationByCount from "../../../../components/PaginationByCount";
import { css } from "@emotion/react";
import SavedModuleDisplayBoard from "../../../../components/SavedModuleDisplayBoard";
import { useAuth } from "../../../../navigation/Auth/ProvideAuth";
import educationService from "../../../../services/cmemodules";
import { CMEDisplayCardProps } from "../../../../types";
import SyncLoaderSpinner from "../../../../components/SyncLoader";
import { useProvideAuth } from "../../../../navigation/Auth/useProvideAuth";

const SavedModules = () => {
  const { auth } = useAuth();
  const { signout } = useProvideAuth();
  const [educationModules, setEducationModules] = useState<{
    data: Array<CMEDisplayCardProps>;
    totalCount: number;
  }>({ data: [], totalCount: 0 });
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const loaderStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
  `;
  const changePage = (changeVal: number) => {
    setPageNumber(pageNumber + changeVal);
  };
  useEffect(() => {
    setLoading(true);
    const getAllSavedModules = async () => {
      try {
        const educationModulesResp =
          await educationService.getSavedEducationModule(
            pageNumber,
            auth?.access_token
          );
        setEducationModules({ ...educationModulesResp });
        setLoading(false);
      } catch (error: any) {
        if (error?.response?.status === 401) {
          //place your reentry code
          await signout();
        }
      }
    };
    getAllSavedModules();
  }, [pageNumber]);
  return (
    <div className="saved-modules">
      <h3>SAVED MODULES</h3>
      <hr />
      {loading ? (
        <SyncLoaderSpinner isLoading={loading} override={loaderStyle} />
      ) : (
        <>
          <SavedModuleDisplayBoard modules={educationModules?.data} />
          <PaginationByCount
            currentPage={pageNumber}
            totalCount={educationModules?.totalCount}
            limit={2}
            changePage={changePage}
          />
        </>
      )}
    </div>
  );
};

export default SavedModules;
