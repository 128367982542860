import { Form, Formik } from "formik";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useAuth } from "../../navigation/Auth/ProvideAuth";
import communityMemService from "../../services/communityMembers";
import Button from "../Button";
import CancelPopUpSVG from "../CancelPopUpSVG";
import FormikControl from "../Form/FormikControl";
import TextError from "../Form/TextError";
import LoadingSpinner from "../LoadingSpinner";
import DefaultAvatar from "../../assets/images/default-avatar.png";
import "./SharePopUp.scss";
import { sharePopUpInitialValues } from "./sharePopUpInitialValues";
import { sharePopUpValidationSchema } from "./sharePopUpValidationScheme";
import messageService from "../../services/messages";
import { useHistory } from "react-router-dom";
import { useProvideAuth } from "../../navigation/Auth/useProvideAuth";

interface SharePopUpProps {
  submitHandler: () => void;
  cancelPopUp: () => void;
  title: string;
}

const SharePopUp: FunctionComponent<SharePopUpProps> = ({
  submitHandler,
  cancelPopUp,
  title,
}: SharePopUpProps) => {
  const { auth } = useAuth();
  const { signout } = useProvideAuth();
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const initialValues = { sharePopUpInitialValues, memberName: searchQuery };
  const validationSchema = sharePopUpValidationSchema;
  const onSubmit = async (values: any, actions: any) => {
    setLoading(true);
    const shareContentDetails = {
      resourceLink: window.location.href,
      receiverId: values.memberName.id,
      senderMessage: values.senderMessage || "",
    };

    try {
      const shareArticleResp = await messageService.shareContent(
        shareContentDetails,
        auth?.access_token
      );
      setLoading(false);
      submitHandler();
    } catch (error: any) {
      setError(error?.response?.data?.message || error?.response?.data?.errors);
      setLoading(false);
    }
  };

  const fetchOptions = async (searchQuery: string, callback: any) => {
    try {
      const searchUserResp = await communityMemService.searchUser(
        auth?.access_token,
        searchQuery,
        "typeAhead"
      );

      const searchResult = searchUserResp.reduce((acc: any, s: any) => {
        return [
          ...acc,
          {
            label: (
              <div className="async-select-opt-label">
                <img src={s.image || DefaultAvatar} />
                <p>{`${s.firstName} ${s.lastName}`}</p>
              </div>
            ),
            value: s,
          },
        ];
      }, []);
      callback(searchResult);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        //place your reentry code
        await signout();
      }
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, isValid, setFieldValue }) => (
        <section className="share-popup">
          <CancelPopUpSVG cancelPopUp={cancelPopUp} />
          <h2>Share this article</h2>
          <h3>{title}</h3>
          <div className="share-form-container">
            <Form className="share-form" onChange={() => setError("")}>
              <FormikControl
                control="async-select"
                label="Choose Member"
                name="memberName"
                placeholder="Search member"
                loadOptions={fetchOptions}
              />

              <FormikControl
                control="textarea"
                label="Add Message"
                name="senderMessage"
              />

              <Button
                type="submit"
                size="size-xxlg"
                disabled={!isValid}
                colorTheme="has-primary-theme"
              >
                Submit
              </Button>
              <LoadingSpinner isLoading={isLoading} />
              {error && <TextError>{error}</TextError>}
            </Form>
          </div>
        </section>
      )}
    </Formik>
  );
};

export default SharePopUp;
