import React, { FunctionComponent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../navigation/Auth/ProvideAuth";
import { COMMUNITYMEMBERS } from "../../navigation/CONSTANT";
import communityMemService from "../../services/communityMembers";
import { ProfileInfoProps } from "../../types";
import "./MemberCard.scss";
import MemberCardProfile from "./MemberCardProfile";
import { sampleProfile } from "./sampleProfile";
interface MemberCardProps {
  title: string;
}

const MemberCard: FunctionComponent<MemberCardProps> = () => {
  const { auth } = useAuth();
  const [commMemProfiles, setCommMemProfiles] = useState<{
    users: Array<ProfileInfoProps>;
    totalCount: number;
  }>({ users: sampleProfile, totalCount: 0 });

  useEffect(() => {
    const getCommMemProfilesResp = async () => {
      const commMemProfileResp =
        await communityMemService.getAllCommunityMember(auth?.access_token, 1);
      setCommMemProfiles(commMemProfileResp);
    };
    getCommMemProfilesResp();
  }, []);

  return (
    <div className="mem-card">
      <h3>Member list</h3>
      {commMemProfiles?.users?.length > 0 ? (
        <>
          <div className="sample-mem-profile">
            {commMemProfiles?.users.slice(0, 4).map((user) => (
              <MemberCardProfile key={user.id} profileInfo={user} />
            ))}
          </div>
          <Link to={COMMUNITYMEMBERS}>View all members</Link>
        </>
      ) : (
        <div className="no-users-found">No other members found</div>
      )}
    </div>
  );
};

export default MemberCard;
