import { TherapyDetailsProps } from "../../../types";

export const initialTherapyDetails = {
  id: "",
  title: "",
  embed_video_link: "",
  pdf_link: "",
  feature_image: "",
  description: "",
  rating: 0,
  ratingsCount: 0,
  isSaved: false,
  comments: [],
  commentCount: 0,
};

export enum therapyActionKind {
  GETTHERAPY = "GET_THERAPY",
  UPDATETHERAPYRATING = "UPDATE_THERAPY_RATING",
  GETTHERAPYCOMMENTS = "GET_THERAPY_COMMENTS",
  ADDTHERAPYCOMMENT = "ADD_THERAPY_COMMENT",
  ADDTHERAPYREPLY = "ADD_THERAPY_REPLY",
  DELETETHERAPYCOMMENT = "DELETE_THERAPY_COMMENT",
  DELETETHERAPYREPLY = "DELETE_THERAPY_REPLY",
}

export interface actionProps {
  type: therapyActionKind;
  payload: any;
}
export const therapyReducer = (
  state: TherapyDetailsProps,
  action: actionProps
) => {
  const { type, payload } = action;
  switch (type) {
    case therapyActionKind.GETTHERAPY:
      return { ...state, ...payload };
    case therapyActionKind.UPDATETHERAPYRATING:
      return {
        ...state,
        rating: Math.floor(
          (state.rating * state.ratingsCount + payload.rating) /
            (state.ratingsCount + 1)
        ),
        ratingsCount: state.ratingsCount + 1,
      };
    case therapyActionKind.GETTHERAPYCOMMENTS:
      return {
        ...state,
        comments: payload.commentInfo.comments,
        commentCount: payload.commentInfo.totalCommentsCount,
      };
    case therapyActionKind.ADDTHERAPYCOMMENT:
      return {
        ...state,
        comments: [...state.comments, payload.comment],
        commentCount: state.commentCount + 1,
      };
    case therapyActionKind.ADDTHERAPYREPLY:
      return {
        ...state,
        comments: state.comments.map((comment: any) => {
          if (comment.id !== payload.commentId) return comment;

          return {
            ...comment,
            replies: comment.replies
              ? [...comment.replies, payload.comment]
              : [payload.comment],
          };
        }),
      };
    case therapyActionKind.DELETETHERAPYCOMMENT:
      return {
        ...state,
        comments: state.comments.filter(
          (conference: any) => conference.id !== payload.commentId
        ),
        commentCount: state.commentCount - 1,
      };
    case therapyActionKind.DELETETHERAPYREPLY:
      return {
        ...state,
        comments: state.comments.map((comment: any) => {
          if (comment.id !== payload.commentId) return comment;

          return {
            ...comment,
            replies: comment.replies.filter(
              (reply: any) => reply.id !== payload.replyId
            ),
          };
        }),
      };
    default:
      return state;
  }
};

export const prepareVideoURL = (
  videoURL: string,
  digitalId: string,
  customerID: string,
  crmCountry: string
): string => {
  if (!videoURL) return "";
  return `${videoURL}&autoplay=false&bccustomguid=${customerID}&bcknownuser=1&bctrackingid=${crmCountry}:${digitalId}`;
};
