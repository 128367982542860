import React from "react";
import { useHistory } from "react-router-dom";
import { ACTIVITYPREFERENCE, INVITEFRIEND } from "../../navigation/CONSTANT";
import "./ProfileInfoCard.scss";
interface ProfileInfoCardType {
  name: string;
  image?: string;
}
const ProfileInfoCard = ({
  profileInformation,
}: {
  profileInformation: ProfileInfoCardType;
}) => {
  const { name, image } = profileInformation;

  const history = useHistory();

  return (
    <div className="profile-info-card">
      <div className="profile-img-container">
        <img src={image} alt="profile" />
      </div>
      <h4>{name}</h4>
      <p onClick={() => history.push(ACTIVITYPREFERENCE)}>Manage Preferences</p>
      <p onClick={() => history.push(INVITEFRIEND)}>Invite a Friend</p>
    </div>
  );
};

export default ProfileInfoCard;
