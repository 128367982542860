import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;
const registerUserURL = "/auth/signup";
const verifyUserURL = "/user/verifyUser";
const userURL = "/user";
type IMAGE_STATUS = "IMAGE_DELETED" | "IMAGE_UPDATED" | "IMAGE_UNCHANGED";
interface registerDetails {
  title: string;
  firstName: string;
  lastName: string;
  organisation?: string;
  seniority?: string;
  professionalNumber?: string;
  speciality?: string;
  region?: string;
  email: string;
  mobileNumber?: string;
  password?: string;
  image?: string;
}
interface updateProfileDetails {
  title: string;
  firstName: string;
  lastName: string;
  region: string;
  imageStatus: IMAGE_STATUS;
  organisation?: string;
  seniority?: string;
  professionalNumber?: string;
  speciality?: string;
  mobileNumber?: string;
  image?: string;
}

const register = async (registerData: registerDetails) => {
  const response = await axios.post(baseUrl + registerUserURL, registerData);
  return response.data;
};

const getUser = async (accessToken: string) => {
  const config = {
    headers: { "takeda-access-token": accessToken },
  };
  const response = await axios.get(baseUrl + userURL, config);
  return response.data;
};

const updateUser = async (
  updateData: updateProfileDetails,
  accessToken: string
) => {
  const bodyFormData = new FormData();
  bodyFormData.append("title", updateData.title);
  bodyFormData.append("firstName", updateData.firstName);
  bodyFormData.append("lastName", updateData.lastName);
  bodyFormData.append("region", updateData.region);
  bodyFormData.append("imageStatus", updateData.imageStatus);
  updateData.organisation
    ? bodyFormData.append("organisation", updateData.organisation)
    : "";
  updateData.seniority
    ? bodyFormData.append("seniority", updateData.seniority)
    : "";
  updateData.professionalNumber
    ? bodyFormData.append("professionalNumber", updateData.professionalNumber)
    : "";
  updateData.speciality
    ? bodyFormData.append("speciality", updateData.speciality)
    : "";
  updateData.mobileNumber
    ? bodyFormData.append("mobileNumber", updateData.mobileNumber)
    : "";
  updateData.image ? bodyFormData.append("image", updateData.image) : "";
  const config = {
    headers: {
      "takeda-access-token": accessToken,
      "Content-Type": "multipart/form-data",
    },
  };
  const response = await axios.put(baseUrl + userURL, bodyFormData, config);
  return response.data;
};

const verifyEmailAndGenerateLead = async (
  recoveryToken: string,
  reCaptchaToken: any
) => {
  const config = {
    headers: { "g-recaptcha-response": reCaptchaToken },
  };
  const response = await axios.post(
    baseUrl + verifyUserURL,
    { recoveryToken },
    config
  );
  return response.data;
};

const registerService = {
  register,
  verifyEmailAndGenerateLead,
  getUser,
  updateUser,
};

export default registerService;
