import React from "react";
import ArticleInfoCard from "../../../../components/ArticleInfoCard";
import CommunityMemberCard from "../../../../components/CommunityMemberCard";
import SyncLoaderSpinner from "../../../../components/SyncLoader";
import TherapyDisplayCard from "../../../../components/TherapyDisplayCard";
import { CLINICALNEWS } from "../../../../navigation/CONSTANT";
import {
  ArticleProps,
  ProfileInfoProps,
  TherapyDisplayCardProps,
} from "../../../../types";
import {
  extractArticles,
  extractConf,
  relevantResultFound,
  searchResultInitialVal,
  therapyAreas,
  therapyToSlugMapper,
} from "../helper";
import "./SearchBody.scss";

const SearchBody = ({
  searchResult,
  loading,
}: {
  searchResult: any;
  loading: boolean;
}) => {
  const prepareSearchResult = {
    ...searchResultInitialVal,
    ...searchResult,
  };

  const resultFound = relevantResultFound(prepareSearchResult);

  return loading ? (
    <SyncLoaderSpinner isLoading={loading} override="" />
  ) : (
    <div className="search-page-body">
      {resultFound ? (
        <div className="search-matches">
          {prepareSearchResult.articles.hits.length > 0 && (
            <div className="search-matched-articles">
              <h3>Articles</h3>
              {extractArticles(prepareSearchResult.articles).map(
                (hit: ArticleProps) => (
                  <ArticleInfoCard
                    key={hit.id}
                    articleInfo={hit}
                    baseURL={CLINICALNEWS}
                  />
                )
              )}
            </div>
          )}

          {therapyAreas.map((therapyArea) => {
            return (
              prepareSearchResult[therapyArea].hits.length > 0 && (
                <div className="search-matched-conf" key={therapyArea}>
                  <h3>{therapyArea}</h3>
                  {extractConf(
                    prepareSearchResult[therapyArea],
                    therapyToSlugMapper[therapyArea]
                  ).map((hit: TherapyDisplayCardProps) => (
                    <TherapyDisplayCard key={hit.id} therapyInfo={hit} />
                  ))}
                </div>
              )
            );
          })}

          {/* Display CME Modules */}
          {/* {prepareSearchResult.modules.hits.length > 0 && (
          <div className="search-matched-modules">
            <h3>Education Modules</h3>
            {extractModules(prepareSearchResult.modules).map(
              (hit: CMEDisplayCardProps) => (
                <CMEDisplayCard key={hit.id} moduleInfo={hit} />
              )
            )}
          </div>
        )} */}

          {/* Display Profiles */}
          {prepareSearchResult.profiles.hits.length > 0 && (
            <div className="search-matched-profiles">
              <h3>Profiles</h3>
              {prepareSearchResult.profiles.hits.map(
                (hit: ProfileInfoProps) => (
                  <CommunityMemberCard key={hit.id} profileInfo={hit} />
                )
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="no-result-found">
          <p> No relevant result found</p>
        </div>
      )}
    </div>
  );
};

export default SearchBody;
