import React, { useEffect, useState } from "react";
import Board from "../../../../components/Board";
import { CLINICALNEWS } from "../../../../navigation/CONSTANT";
import RareDisease from "../../../../assets/images/raredisease.png";
import newsService from "../../../../services/news";
import { css } from "@emotion/react";
import { useAuth } from "../../../../navigation/Auth/ProvideAuth";
import SavedArticleDisplayBoard from "../../../../components/SavedArticleDisplayBoard";
import { SavedArticleInfoCardProps } from "../../../../types";
import PaginationByCount from "../../../../components/PaginationByCount";
import SyncLoaderSpinner from "../../../../components/SyncLoader";

const SavedArticles = () => {
  const { auth } = useAuth();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const loaderStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
  `;

  const [savedArticles, setSavedArticles] = useState<{
    data: Array<SavedArticleInfoCardProps>;
    totalCount: number;
  }>({ data: [], totalCount: 0 });

  const changePage = (changeVal: number) => {
    setPageNumber(pageNumber + changeVal);
  };

  const removeArticle = (articleId: string) => {
    const newSavedArticles = {
      data: savedArticles.data.filter((article) => article.id !== articleId),
      totalCount: savedArticles.totalCount - 1,
    };
    setSavedArticles(newSavedArticles);
  };

  useEffect(() => {
    const getAllSavedArticles = async () => {
      setLoading(true);
      const savedArticlesResp = await newsService.getSavedArticles(
        pageNumber,
        auth?.access_token
      );
      setSavedArticles({ ...savedArticlesResp });
      setLoading(false);
    };
    try {
      getAllSavedArticles();
    } catch (error) {
      setLoading(false);
    }
  }, [pageNumber]);

  return (
    <div className="saved-articles">
      <h3>Saved Articles</h3>
      <hr />
      {loading ? (
        <SyncLoaderSpinner isLoading={loading} override={loaderStyle} />
      ) : (
        <>
          <div className="information-menu">
            <SavedArticleDisplayBoard
              articles={savedArticles?.data}
              removeArticle={removeArticle}
              navigateTo={CLINICALNEWS}
            />
          </div>
          <PaginationByCount
            currentPage={pageNumber}
            totalCount={savedArticles?.totalCount}
            limit={2}
            changePage={changePage}
          />
        </>
      )}
    </div>
  );
};

export default SavedArticles;
