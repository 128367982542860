import React, { FunctionComponent } from "react";
const MyMessageHeader: FunctionComponent = () => {
  return (
    <section className="my-message-page-header">
      <h1>My Messages</h1>
    </section>
  );
};

export default MyMessageHeader;
