import React from "react";
import "./ResetPwdReqHeader.scss";
const ResetPwdReqHeader = () => {
  return (
    <section className="reset-pwd-req-header">
      <h3>Reset Password</h3>
      <p>
        Enter your email to recover your password. You will receive an email
        with instructions.
      </p>
    </section>
  );
};

export default ResetPwdReqHeader;
