export const initialLoggedUserInfo = {
  email: "",
  firstName: "",
  id: undefined,
  image: "",
  lastName: "",
  mobileNumber: "",
  organisation: "",
  professionalNumber: "",
  region: "",
  seniority: "",
  speciality: "",
  takedaId: "",
  title: "",
  customer_id: "",
  crm_country: "",
  digital_id: "",
  isAdmin: false,
  unseenMsgCount: 0,
};

export const loggedUserReducer = (state: any, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case "INIT_USER_DETAILS":
      return { ...payload };
    case "UPDATE_USER_DETAILS":
      return { ...state, ...payload };
    case "UPDATE_MESSAGE_COUNT":
      return { ...state, unseenMsgCount: payload.unseenMsgCount };
    default:
      return state;
  }
};
