import React, { createContext, useContext, useReducer } from "react";
import { loggedUserReducer, initialLoggedUserInfo } from "./LoggedUserReducer";

interface AppContextInterface {
  loggedUserInfo: any;
  setLoggedUserInfo: React.Dispatch<any>;
}
const LoggedUserContext = createContext<AppContextInterface>(
  {} as AppContextInterface
);

export const LoggedUserProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [loggedUserInfo, setLoggedUserInfo] = useReducer(
    loggedUserReducer,
    initialLoggedUserInfo
  );

  return (
    <LoggedUserContext.Provider value={{ loggedUserInfo, setLoggedUserInfo }}>
      {children}
    </LoggedUserContext.Provider>
  );
};

export const useLoggedUserContext = () => useContext(LoggedUserContext);
