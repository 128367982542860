import "./EducationMenu.scss";
import React from "react";
import EducationModule from "./EducationModule";
import ConfHighlights from "./ConfHighlights";
import { therapyAreas } from "./therapyAreas";
import EdTherapyArea from "./EdTherapyArea";

const EducationMenu = () => {
  return (
    <div className="education-menu">
      {/* <EducationModule />
      <ConfHighlights /> */}
      {therapyAreas.map((area) => (
        <EdTherapyArea key={area} slug={area} />
      ))}
    </div>
  );
};

export default EducationMenu;
