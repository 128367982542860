import React from "react";
import "./CMEDisplayBoard.scss";
import { CMEDisplayCardProps } from "../../types";
import CMEDisplayCard from "../CMEDisplayCard";

const CMEDisplayBoard = ({
  modules,
}: {
  modules: Array<CMEDisplayCardProps>;
}) => {
  return (
    <div className="cme-display-board">
      {modules.length === 0 ? (
        <p className="instruction">No modules found</p>
      ) : (
        <>
          {modules.map((module) => (
            <CMEDisplayCard key={module.id} moduleInfo={module} />
          ))}
        </>
      )}
    </div>
  );
};

export default CMEDisplayBoard;
