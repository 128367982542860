import algoliasearch from "algoliasearch";
import communityMemService from "../../../services/communityMembers";

interface AlgoliaSampleSearchResult {
  hits: Array<any>;
  hitsPerPage?: number;
  nbHits?: number;
  nbPages?: number;
  page?: number;
}

interface SiteSearchResult {
  articles: AlgoliaSampleSearchResult;
  conferences: AlgoliaSampleSearchResult;
  modules: AlgoliaSampleSearchResult;
  profiles: AlgoliaSampleSearchResult;
}

interface SlugToTitleProps {
  [slug: string]: string;
}

export const extractArticles = (result: any) => {
  return result.hits.map(
    ({ objectID, title, plaintext, feature_image, rating }: any) => {
      return {
        id: objectID,
        title,
        excerpt: plaintext.slice(0, 570),
        feature_image,
        rating,
        isSearchResult: true,
      };
    }
  );
};

export const extractConf = (result: any, therapyArea: string) => {
  return result.hits.map(
    ({
      objectID,
      title,
      feature_image,
      embed_video_link,
      description,
      pdf_link,
    }: any) => {
      return {
        id: objectID,
        title,
        feature_image,
        description,
        pdf_link,
        embed_video_link,
        therapyArea,
      };
    }
  );
};

export const extractModules = (result: any) => {
  return result.hits.map(
    ({
      objectID,
      title,
      moduleIntro,
      topic,
      dateLaunched,
      videoLinks,
      pdfLinks,
    }: any) => {
      return {
        id: objectID,
        title,
        moduleIntro,
        topic,
        dateLaunched,
        videoLinks,
        pdfLinks,
      };
    }
  );
};
export const resultNameList = [
  "articles",
  "haematology",
  "rare_genetic_diseases",
  "oncology",
  "immunology",
  "gastroenterology",
];
export const therapyAreas = [
  "haematology",
  "rare_genetic_diseases",
  "oncology",
  "immunology",
  "gastroenterology",
];
export const allResultList = [
  "articles",
  "haematology",
  "rare_genetic_diseases",
  "oncology",
  "immunology",
  "gastroenterology",
  "profiles",
];

export const therapyToSlugMapper: SlugToTitleProps = {
  haematology: "rarehaematology",
  rare_genetic_diseases: "raregeneticdisease",
  oncology: "oncology",
  immunology: "immunology",
  gastroenterology: "gastroenterology",
};
export const searchResultInitialVal = allResultList.reduce(
  (acc, rescategory) => {
    return { ...acc, [rescategory]: { hits: [] } };
  },
  {}
);
const initialSearchContentVal = resultNameList.reduce((acc, resultName) => {
  return {
    ...acc,
    [resultName]: { hits: [], hitsPerPage: 3, nbHits: 0, nbPages: 0, page: 0 },
  };
}, {});

const initialSearchProfileVal = {
  profiles: {
    hits: [],
    hitsPerPage: 3,
    nbHits: 0,
    nbPages: 0,
    page: 0,
  },
};

const initialResultVal = {
  ...initialSearchContentVal,
  ...initialSearchProfileVal,
};

export const searchContent = async (query: string) => {
  if (!query) {
    return initialSearchContentVal;
  }
  const indexNameList = [
    "hcpcomm_information_posts",
    "rare_haematology_posts",
    "rare_genetic_diseases_posts",
    "oncology_posts",
    "immunology_posts",
    "gastroenterology_posts",
  ];

  const queries = indexNameList.map((indexName) => {
    return {
      indexName,
      query,
      params: {
        hitsPerPage: 3,
      },
    };
  });

  const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID as string,
    process.env.REACT_APP_ALGOLIA_APP_KEY as string
  );

  const { results } = await searchClient.multipleQueries(queries);
  return resultNameList.reduce((acc, resultName, idx) => {
    return {
      ...acc,
      [resultName]: { ...results[idx] },
    };
  }, {});
};

export const searchProfile = async (query: string, token: string) => {
  if (!query) {
    return initialSearchProfileVal;
  }
  const profileMatch = await communityMemService.searchUser(
    token,
    query,
    "profile"
  );
  return { profiles: { hits: [...profileMatch] } };
};

export const searchSite = async (query: string, token: string) => {
  const matchedContent = await searchContent(query);
  const matchedProfile = await searchProfile(query, token);

  return { ...matchedContent, ...matchedProfile };
};

const capitalizeWord = (word: string): string => {
  return word.slice(0, 1).toUpperCase() + word.slice(1);
};

export const capitalize = (query: string): string => {
  return query
    .split(" ")
    .map((w) => capitalizeWord(w))
    .join(" ");
};

export const relevantResultFound = (prepareSearchResult: any): boolean => {
  const therapyAreaPresent = therapyAreas
    .map((therapyArea) => {
      return prepareSearchResult[therapyArea].hits.length > 0;
    })
    .includes(true);
  return (
    prepareSearchResult.articles.hits.length > 0 ||
    prepareSearchResult.profiles.hits.length > 0 ||
    therapyAreaPresent
  );
};
