import React, { FunctionComponent } from "react";
import PaginationLink from "../PaginationLink";
import "./Pagination.scss";
interface PaginationProps {
  paginationDetails: {
    limit: number;
    next: number | null;
    page: number;
    pages: number;
    prev: number | null;
    total: number;
  };
  setPageNumber: (pageNumber: number) => void;
}

const Pagination: FunctionComponent<PaginationProps> = ({
  paginationDetails,
  setPageNumber,
}: PaginationProps) => {
  const {
    page: currentPage,
    pages: totalPage,
    next: nextPage,
    prev: prevPage,
    total,
  } = paginationDetails || {
    page: 1,
    pages: 1,
    next: null,
    prev: null,
    total: 0,
  };
  const totalDisplayPage = totalPage - currentPage + 1;
  const fillLength = Math.max(Math.min(totalDisplayPage, 4), 0);
  const paginationIndex = Array(fillLength)
    .fill(0, 0, fillLength)
    .map((v, i) => currentPage + i);

  return total > 0 ? (
    <div className="pagination">
      {prevPage && (
        <PaginationLink
          pageNumber={prevPage}
          pageTitle="Prev"
          setPageNumber={setPageNumber}
        />
      )}
      {paginationIndex.map((index) => (
        <PaginationLink
          key={index}
          pageNumber={index}
          pageTitle={index + ""}
          activePage={currentPage}
          setPageNumber={setPageNumber}
        />
      ))}
      {nextPage && (
        <PaginationLink
          pageNumber={nextPage}
          pageTitle="Next"
          setPageNumber={setPageNumber}
        />
      )}
    </div>
  ) : (
    <div className="pagination"></div>
  );
};

export default Pagination;
