import React from "react";
import PrivacyPolicyHeader from "./PrivacyPolicyHeader";
import PrivacyPolicyBody from "./PrivacyPolicyBody";
import "./PrivacyPolicyContent.scss";

const PrivacyPolicyContent = () => {
  return (
    <main className="priv-pol-page">
      <PrivacyPolicyHeader />
      <PrivacyPolicyBody />
    </main>
  );
};

export default PrivacyPolicyContent;
