import React from "react";
import { useParams } from "react-router-dom";
import SyncLoaderSpinner from "../../../../components/SyncLoader";
import TherapyDisplayCard from "../../../../components/TherapyDisplayCard";
import { TherapyDisplayCardProps } from "../../../../types";
import { extractConf, slugToTitleMapper } from "../helper";
import "./SearchConfBody.scss";

const SearchConfBody = ({
  searchResult,
  loading,
}: {
  searchResult: any;
  loading: boolean;
}) => {
  const preparedSearchResults = { hits: [], ...searchResult };
  const { searchCategory } = useParams<{ searchCategory?: string }>();
  const capitalizeSearchCategory = `${slugToTitleMapper[searchCategory || ""]}`;

  return loading ? (
    <SyncLoaderSpinner isLoading={loading} override="" />
  ) : (
    <div className="search-conf-page-body">
      <div className="search-matches">
        <div className="search-matched-conf">
          <h3>{capitalizeSearchCategory}</h3>
          {preparedSearchResults.hits.length === 0 ? (
            <p className="instruction">
              No match found for {capitalizeSearchCategory}
            </p>
          ) : (
            <>
              {extractConf(preparedSearchResults, searchCategory).map(
                (hit: TherapyDisplayCardProps) => (
                  <TherapyDisplayCard key={hit.id} therapyInfo={hit} />
                )
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchConfBody;
