import React, { useEffect, useState } from "react";
import CMEBoard from "../../../components/CMEBoard";
import CMEHeader from "../../../components/CMEHeader";
import Pagination from "../../../components/Pagination";
import { css } from "@emotion/react";
import { useAuth } from "../../../navigation/Auth/ProvideAuth";
import educationService from "../../../services/cmemodules";
import { sortByValues } from "../../../types";
import { cmemodules } from "./cmemodules";

import "./CMEModulesMenu.scss";
import SyncLoaderSpinner from "../../../components/SyncLoader";

const CMEModulesMenu = () => {
  const { auth } = useAuth();
  const [CMEModules, setCMEModules] = useState<any>({ data: [], meta: {} });
  const [filter, setFilter] = useState<sortByValues | "recommended">("added");
  const [activePage, setActivePage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const loaderStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  `;
  const setPageNumber = (pageNumber: number) => {
    setActivePage(pageNumber);
  };
  useEffect(() => {
    const getAllEducationModules = async () => {
      setLoading(true);
      const sortBy = filter === "added" ? "added" : "views";
      const cmeModulesResp = await educationService.getAllEducationModules(
        activePage,
        auth?.access_token,
        sortBy
      );
      setCMEModules(cmeModulesResp);
      setLoading(false);
    };
    try {
      getAllEducationModules();
    } catch (error) {
      setLoading(false);
    }
  }, [activePage, filter]);
  return (
    <div className="cme-menu">
      <CMEHeader
        filter={filter}
        changeFilter={(value: sortByValues | "recommended") => setFilter(value)}
      />
      {loading ? (
        <SyncLoaderSpinner isLoading={loading} override={loaderStyle} />
      ) : (
        <>
          <CMEBoard cmemodules={CMEModules.data} />
          <Pagination
            paginationDetails={CMEModules?.meta?.pagination}
            setPageNumber={setPageNumber}
          />
        </>
      )}
    </div>
  );
};

export default CMEModulesMenu;
