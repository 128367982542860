import React from "react";
import Card from "../../../../../components/Card";
import ProfileInfoCard from "../../../../../components/ProfileInfoCard";
import defaultAvatar from "../../../../../assets/images/default-avatar.png";
import "./CommunityInfo.scss";
import {
  ANALYTICSDASH,
  CLINICALNEWS,
  CMEMODULES,
  CONFERENCEHIGHLIGHT,
  EDUCATION,
  GASTROENTEROLOGY,
  IMMUNOLOGY,
  JOURNALWATCH,
  NEWS,
  ONCOLOGY,
  OTHERRESOURCES,
  RAREGENETICDISEASE,
  RAREHAEMATOLOGY,
} from "../../../../../navigation/CONSTANT";
import { useLoggedUserContext } from "../../../../../context/LoggedUser";
import MemberCard from "../../../../../components/MemberCard";

const CommunityInfo = () => {
  const { loggedUserInfo } = useLoggedUserContext();
  const { title, firstName, lastName, image, isAdmin } = loggedUserInfo;
  const profileInformation = {
    name: [title, firstName, lastName].join(" "),
    image: image || defaultAvatar,
  };
  return (
    <div className="community-info">
      <ProfileInfoCard profileInformation={profileInformation} />
      <Card
        title={{ navigateTo: NEWS, name: "Information" }}
        actions={[
          { title: "Clinical News", navigateTo: CLINICALNEWS },
          // { title: "Journal Watch", navigateTo: JOURNALWATCH },
          // { title: "Other Resources", navigateTo: OTHERRESOURCES },
        ]}
      />
      <Card
        title={{ navigateTo: EDUCATION, name: "Education" }}
        actions={[
          // { title: "Education Modules", navigateTo: CMEMODULES },
          // {
          //   title: "Conference Highlights",
          //   navigateTo: CONFERENCEHIGHLIGHT,
          // },
          {
            title: "Rare Hematology",
            navigateTo: RAREHAEMATOLOGY,
          },
          {
            title: "Rare Genetic Diseases",
            navigateTo: RAREGENETICDISEASE,
          },
          {
            title: "Oncology",
            navigateTo: ONCOLOGY,
          },
          {
            title: "Immunology/HAE",
            navigateTo: IMMUNOLOGY,
          },
          {
            title: "Gastroenterology",
            navigateTo: GASTROENTEROLOGY,
          },
        ]}
      />
      {isAdmin && (
        <Card
          title={{ navigateTo: ANALYTICSDASH, name: "Report" }}
          actions={[{ title: "Reporting Overview", navigateTo: ANALYTICSDASH }]}
        />
      )}
      <MemberCard title="Memcard" />
    </div>
  );
};

export default CommunityInfo;
