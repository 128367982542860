import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FILTERMODULES } from "../../navigation/CONSTANT";
import { sortByValues } from "../../types";
import Button from "../Button";
import "./CMEHeader.scss";

const CMEHeader = ({
  filter,
  changeFilter,
}: {
  filter: string;
  changeFilter: (value: sortByValues | "recommended") => void;
}) => {
  const history = useHistory();
  return (
    <div className="cme-header">
      <h3>education modules</h3>
      <div className="filter-action">
        <div className="filter-button">
          <Button
            type="button"
            size="size-lg"
            colorTheme="has-primary-theme"
            onClick={() => history.push(FILTERMODULES)}
          >
            Filter
          </Button>
        </div>
        <div className="filter-bar">
          <div
            className={`filter-event ${
              filter === "added" ? "filter-event-active" : ""
            }`}
            onClick={() => changeFilter("added")}
          >
            Just added
          </div>
          <div
            className={`filter-event ${
              filter === "views" ? "filter-event-active" : ""
            }`}
            onClick={() => changeFilter("views")}
          >
            Most viewed
          </div>
          <div
            className={`filter-event ${
              filter === "recommended" ? "filter-event-active" : ""
            }`}
            onClick={() => changeFilter("recommended")}
          >
            Recommended
          </div>
        </div>
      </div>
    </div>
  );
};

export default CMEHeader;
