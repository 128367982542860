import React from "react";
import "./InviteFriendSuccessBody.scss";

const InviteFriendSuccessBody = () => {
  return (
    <div className="invite-friend-success-body">
      <h3>Thank you!</h3>
      <p>The registration approval process can take up 3-5 days.</p>
      <p>
        Your friend will receive an email directing them to create an account.
      </p>
    </div>
  );
};

export default InviteFriendSuccessBody;
