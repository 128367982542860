import React from "react";
import "./InviteFriendHeading.scss";

const InviteFriendHeading = () => {
  return (
    <section className="invite-friend-heading">
      <div className="title-container">
        <h1>Invite a friend</h1>
      </div>
    </section>
  );
};

export default InviteFriendHeading;
