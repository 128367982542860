import React, { useState } from "react";
import SearchConfHeader from "./SearchConfHeader";
import SearchConfBody from "./SearchConfBody";
import "./SearchConfContent.scss";
import { searchConferences } from "./helper";
const SearchConfContent = () => {
  const [searchResult, setSearchResult] = useState({});
  const [loading, setLoading] = useState<boolean>(false);

  const handleSearch = async (query: string, indexName: string) => {
    setLoading(true);
    try {
      const searchResp = await searchConferences(query, indexName);
      setLoading(false);
      setSearchResult(searchResp);
    } catch (error: any) {
      setLoading(false);
    }
  };

  return (
    <main className="search-conf-page">
      <SearchConfHeader handleSiteSearch={handleSearch} />
      <SearchConfBody searchResult={searchResult} loading={loading} />
    </main>
  );
};

export default SearchConfContent;
