import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";

const RegSuccessFooter: FunctionComponent = () => {
  return (
    <section className="reg-success-page-footer">
      <div className="footer-content">
        <p>Don’t have a login?</p>
        <Link to="/register">
          <Button type="button" size="size-lg" colorTheme="has-primary-theme">
            Register
          </Button>
        </Link>
      </div>
    </section>
  );
};

export default RegSuccessFooter;
